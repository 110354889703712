import React, { useEffect, useState } from 'react'
import JSSidebar from './JSSidebar'
import NavBar from '../element/NavBar'
import Footer from '../element/Footer'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import ApiKey from '../api/ApiKey'
import BaseApi from '../api/BaseApi'
// import Multiselect from "multiselect-react-dropdown";
import JoditEditor from 'jodit-react'
import Select from 'react-select'
import { useRef } from 'react'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // import styles
import CreateResumePreview from './CreateResumePreview'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'

const ResumePreview = () => {
  let primaryColor = Cookies.get('primaryColor')
  let secondaryColor = Cookies.get('secondaryColor')
  const mapKey = Cookies.get('mapKey')
  const [t, i18n] = useTranslation('global')
  const [editProfile, setEditProfile] = useState({
    experience_certificate: '',
    job_position: '',
    company_name: '',
    location: '',
    job_profile: '',
    startMonth: '',
    startYear: '',
    endMonth: '',
    endYear: '',
    ongoing: false
  })
  // const [internships, setInternships] = useState([])
  const [experience, setExperience] = useState([])

  const [validationErrors, setValidationErrors] = useState([])
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [yearsList, setYearsList] = useState([])
  const [errors, setErrors] = useState({})
  const [educationDetails, setEducationDetails] = useState([])
  const [loading, setLoading] = useState(false)
  const [autocompleteService, setAutocompleteService] = useState(null)
  const [suggestionsPreferred, setSuggestionsPreferred] = useState([])
  const [suggestionsNative, setSuggestionsNative] = useState([])
  const [suggestionTakenNative, setSuggestionTakenNative] = useState(false)

  const navigate = useNavigate()
  const tokenKey = Cookies.get('tokenClient') // Assuming you're using cookies for the token

  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false)

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true)
  }

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false)
  }

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false)

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true)
  }

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false)
  }

  const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false)

  const handleThirdButtonMouseEnter = () => {
    setHoverThirdButtonColor(true)
  }

  const handleThirdButtonMouseLeave = () => {
    setHoverThirdButtonColor(false)
  }

  const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false)

  const handleFourthButtonMouseEnter = () => {
    setHoverFourthButtonColor(true)
  }

  const handleFourthButtonMouseLeave = () => {
    setHoverFourthButtonColor(false)
  }

  const handleClick = () => {
    navigate("/candidates/myaccount"); // Navigate to My Account page
  };

  return (
    <>
      <NavBar />
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <>
          <div className='container editProfile'>
            <div className='row'>
              {/* <div className='col-lg-3'>
                <JSSidebar />
              </div> */}

              <div
                className='col-lg-12 mb-5'
                style={{
                  borderLeft: '2px solid #e6e8e7',
                  borderRight: '2px solid #e6e8e7'
                }}
              >
                <main class='content'>
                  <div className='CVPriveCongrat'>
                    <h2>Congratulation!!!</h2>
                    <p>Your resume is ready to conquer the world</p>
                  </div>

                  <div class='preview-section'>
                    {/* <h3>Simply click</h3> */}
                    {/* <Link to ='/candidates/resume' class='btn preview-btn' >Preview</Link> */}
                    <button className='btn preview-btn' onClick={handleOpen}>
                      Preview
                    </button>

                    {/* Modal component */}
                    <Modal open={open} onClose={handleClose}>
                      <Box
                        sx={{
                          maxHeight: '100%',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: '62%',
                          bgcolor: 'background.paper',
                          border: '2px solid #000',
                          boxShadow: 24,
                          overflowY: 'auto',
                          p: 4
                        }}
                      >
                        <CreateResumePreview />
                        {/* <h2>Create Resume Preview</h2>
          <p>Here you can preview your resume content...</p> */}
                        <button onClick={handleClose} className='btn close-btn'>
                          X
                        </button>
                      </Box>
                    </Modal>

                    {/* <Link to="/candidates/resume" className="hidden-link">
        Navigate to Resume
      </Link> */}

                    <div class='resume-preview'>
                   
                      <CreateResumePreview />
                    </div>


                    
                  </div>
                </main>

                <div className='JSEPFinalButton d-flex justify-content-between'>
                  <Link
                    to={`/candidates/languages`}
                    type='button'
                    // onClick={handleClick}
                    className='btn btn-primary button1'
                    style={{
                      backgroundColor: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor
                    }}
                    onMouseEnter={handleFirstButtonMouseEnter}
                    onMouseLeave={handleFirstButtonMouseLeave}
                  >
                    Back
                  </Link>
                  <button
                    // to={`/candidates/professions/experience`}
                    type='button'
                    onClick={handleClick}
                    className='btn btn-primary button1'
                    style={{
                      backgroundColor: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor
                    }}
                    onMouseEnter={handleFirstButtonMouseEnter}
                    onMouseLeave={handleFirstButtonMouseLeave}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  )
}

export default ResumePreview
