import React, { useEffect, useState } from 'react'
import JSSidebar from './JSSidebar'
import NavBar from '../element/NavBar'
import Footer from '../element/Footer'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import ApiKey from '../api/ApiKey'
import BaseApi from '../api/BaseApi'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

const JobForm = ({
  index,
  jobType,
  initialData,
  onValidate,
  onRemove,
  onDataChange,
  yearsList,
  experience_id,
  getData
}) => {
  const [formData, setFormData] = useState({
    job_position: '',
    company_name: '',
    location: '',
    job_profile: '',
    from_year: '',
    to_year: '',
    from_month: '',
    to_month: '',
    ongoing: 0,
    experience_certificate: '',
    selected_value: jobType
    
  })
  console.log(jobType)

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const tokenKey = Cookies.get('tokenClient')
  // const [yearsList, setYearsList] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [formErrors, setFormErrors] = useState({})
  const { t } = useTranslation('global')

  let primaryColor = Cookies.get('primaryColor')
  let secondaryColor = Cookies.get('secondaryColor')
  const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false)

  const handleFourthButtonMouseEnter = () => {
    setHoverFourthButtonColor(true)
  }
  const [ongoing, setOngoing] = useState()
  const currentMonth = new Date().getMonth() + 1 // JavaScript months are 0-indexed
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    if (initialData) {
      setFormData(prev => ({
        ...prev,
        ...initialData,
        experience_certificate: initialData.experience_certificate || []
      }))
    }
  }, [initialData])

  // Helper function for session expiration handling
  const handleSessionExpiration = message => {
    Cookies.remove('tokenClient')
    Cookies.remove('user_type')
    Cookies.remove('fname')
    navigate('/')
    Swal.fire({
      title: message,
      icon: 'warning',
      confirmButtonText: t('searchJobPage.close')
    })
  }

  // const handleChange = e => {
  //   const { name, value, type, checked } = e.target
  //   const updatedValue = type === 'checkbox' ? checked : value
  //   const updatedData = { ...formData, [name]: updatedValue }

  //   console.log(updatedData,'handle change function')

  //   setFormData(updatedData)
  //   onDataChange(index, updatedData)
  // }


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedValue = type === "checkbox" ? checked : value;
  
    let updatedData = { ...formData, [name]: updatedValue };
  
    if (name === "ongoing" && checked) {
      const currentMonth = new Date().getMonth() + 1; // 1-12
      const currentYear = new Date().getFullYear();
  
      // If 'Ongoing' is checked, set 'to_month' and 'to_year' to the current month and year
      updatedData = { ...updatedData, to_month: currentMonth, to_year: currentYear };
    }
  
    setFormData(updatedData);
    console.log(updatedData);
  
    // If 'Ongoing' is checked, remove validation errors for 'to_month' and 'to_year'
    setFormErrors((prevErrors) => {
      if (checked) {
        const { to_month, to_year, ...rest } = prevErrors;
        return rest;
      }
      return prevErrors;
    });
  
    onDataChange(index, updatedData);
  };

  const handleFileChange = (event, index) => {
    const files = Array.from(event.target.files) // Convert FileList to an array
    console.log(files, 'Files uploaded')

    // Ensure selectedFiles[index] is an array
    const currentFiles = Array.isArray(selectedFiles[index])
      ? selectedFiles[index]
      : []

    // Check for file limit
    if (currentFiles.length + files.length > 5) {
      Swal.fire({
        title: 'File Limit Exceeded',
        text: 'You can upload a maximum of 5 files.',
        icon: 'error'
      })
      return
    }

    // Check for duplicates
    const isDuplicate = files.some(newFile =>
      currentFiles.some(existingFile => existingFile.name === newFile.name)
    )

    if (isDuplicate) {
      Swal.fire({
        title: 'Duplicate Files',
        text: 'Some files are duplicates and were not added.',
        icon: 'error'
      })
      return
    }

    // Update selectedFiles state
    setSelectedFiles(prevFiles => {
      const updatedFiles = [...prevFiles] // Create a new array to avoid reassignment error
      updatedFiles[index] = [...(updatedFiles[index] || []), ...files] // Append new files to the existing ones
      console.log(updatedFiles, 'Updated selected files')
      return updatedFiles
    })

    // Update formData state
    setFormData(prevData => {
      const updatedData = { ...prevData }

      // Ensure the specific index in formData exists and is an object
      if (!updatedData[index]) {
        updatedData[index] = { experience_certificate: [] }
      }

      // Append the new files to the existing experience_certificate array (without removing old files)
      updatedData.experience_certificate = [
        ...(updatedData.experience_certificate || []),
        ...files
      ]

      console.log('FormData updated on handleFileChange :', updatedData) // Log updated formData

      // Call onDataChange to notify the parent component
      onDataChange(index, updatedData)

      return updatedData
    })
  }

  const handleRemoveFile = (index, fileIndex) => {
    // Show a SweetAlert confirmation dialog before removing the file
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once removed, this file cannot be recovered!',
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        // If the user confirms, proceed with removing the file
        // Update the selected files state
        setSelectedFiles(prevFiles => {
          // Create a new array by filtering out the file at fileIndex
          const updatedFiles = prevFiles.filter((_, idx) => idx !== fileIndex)
          return updatedFiles // Return the updated files array
        })

        // Update the education details state
        setFormData(prevData => {
          // Log the entire prevData structure for debugging
          console.log('prevData:', prevData)
          console.log('Type of prevData:', typeof prevData) // Log the type of prevData
          console.log(
            'Is prevData an object?',
            prevData && typeof prevData === 'object'
          ) // Check if it's an object

          // Ensure prevData is an object and has the experience_certificate property
          if (
            prevData &&
            typeof prevData === 'object' &&
            prevData.hasOwnProperty('experience_certificate')
          ) {
            // Log the structure of experience_certification
            console.log(
              'experience_certification:',
              prevData.experience_certificate
            )
            console.log(
              'Type of experience_certification:',
              typeof prevData.experience_certificate
            ) // Log its type

            // Create a shallow copy of prevData
            const updatedData = { ...prevData }

            // Ensure experience_certification is an array
            if (Array.isArray(updatedData.experience_certificate)) {
              // Filter out the file at the given fileIndex
              updatedData.experience_certificate =
                updatedData.experience_certificate.filter(
                  (_, idx) => idx !== fileIndex // Remove the file at the given index
                )
            } else {
              console.warn(
                'experience_certification is not an array:',
                updatedData.experience_certificate
              )
            }

            console.log('FormData updated on handle Remove File :', updatedData) // Log updated formData

            return updatedData // Return the updated data object
          } else {
            console.warn('Invalid structure for prevData:', prevData)
            return prevData // Return the original data if the structure is invalid
          }
        })

        // Optionally, show a success message
        Swal.fire('Success!', 'File has been removed.', 'success')
      } else {
        // If the user cancels, show a message
        Swal.fire('Cancelled', 'Your file is safe!', 'info')
      }
    })
  }

  const handleRemoveFileWithName = async (file, index) => {
    try {
      // Log to check what education_id is
      console.log('Education ID before parsing:', file)

      // Check if education_id is an array or object and extract the ID correctly
      // const educationId = Array.isArray(education_id)
      //   ? education_id[0]?.id
      //   : parseInt(education_id, 10)

      // console.log('Education ID after parsing:', educationId)

      // Check if educationId is valid
      // if (isNaN(educationId) || !Number.isInteger(educationId)) {
      //   console.error('Invalid education ID:', educationId)
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Invalid Education ID',
      //     text: 'The Education ID is invalid. Please try again.'
      //   })
      //   return
      // }

      // SweetAlert confirmation dialog
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `Are you sure you want to delete the file ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      })

      if (result.isConfirmed) {
        // Proceed with the API call to delete the file
        const response = await axios.post(
          BaseApi + '/candidates/deleteExperienceDocuments',
          {
            file_id: file, // Send as a single integer
            file_name: file // Send the file name to be deleted
          },
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey, // Replace with your actual API key
              token: tokenKey // Replace with the user's token
            }
          }
        )

        // Check the response from the API
        if (response.data.status === 200) {
          getData()
          setLoading(false)

          // Successfully deleted file, update state or remove file from selectedFiles
          setSelectedFiles(prevFiles => {
            const newFiles = [...prevFiles]
            newFiles.splice(index, 1) // Remove the file from the list
            return newFiles
          })

          // Show success message with SweetAlert
          Swal.fire({
            icon: 'success',
            title: 'File Deleted Successfully',
            text: `The file "${file}" has been deleted.`
          })
          console.log('File deleted successfully')
        } else {
          console.error('Failed to delete file')
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete the file. Please try again.'
          })
        }
      } else {
        // User canceled the deletion
        console.log('File deletion canceled')
      }
    } catch (error) {
      console.error('Error deleting file:', error)
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while deleting the file. Please try again.'
      })
    }
  }



  
  
  // useEffect(() => {
  //   const errors = {}
  
  //   Object.keys(formData).forEach(key => {
  //     const value = formData[key];
  
  //     // Validate 'job_position'
  //     if (key === 'job_position' && !value) {
  //       errors[key] = 'Job position is required';
  //     }
  
  //     // Validate 'company_name'
  //     if (key === 'company_name' && !value) {
  //       errors[key] = 'Company name is required';
  //     }
  
  //     // Validate 'location'
  //     if (key === 'location' && !value) {
  //       errors[key] = 'Location is required';
  //     }
  
  //     // Validate 'job_profile'
  //     if (key === 'job_profile' && !value) {
  //       errors[key] = 'Job profile is required';
  //     }
  
  //     // Validate 'from_month' and 'from_year' for empty values
  //     if (key === 'from_month' && !value) {
  //       errors[key] = 'Start Month is required';
  //     }
  
  //     if (key === 'from_year' && !value) {
  //       errors[key] = 'Start Year is required';
  //     }
  
  //     // Validate 'to_month' and 'to_year' for empty values
  //     if (key === 'to_month' && !value) {
  //       errors[key] = 'End Month is required';
  //     }
  
  //     if (key === 'to_year' && !value) {
  //       errors[key] = 'End Year is required';
  //     }
  
  //     // Validation for start date (from_month, from_year) not being ahead of current date
  //     // if (key === 'from_month' || key === 'from_year') {
  //     //   const startMonth = formData['from_month'];
  //     //   const startYear = formData['from_year'];
  
  //     //   const currentMonth = new Date().getMonth() + 1; // Current month (1-12)
  //     //   const currentYear = new Date().getFullYear(); // Current year
  
  //     //   // Check if start date (month and year) is in the future
  //     //   if (startYear > currentYear || (startYear === currentYear && startMonth > currentMonth)) {
  //     //     errors['from_year'] = 'Start month cannot be in the future';
  //     //     errors['from_month'] = 'Start month cannot be in the future';
  //     //   }
  //     // }
    
  //     // Validate 'to_year' and 'to_month' (ensure it is after start date)
  //     // if ((key === 'to_year' || key === 'to_month') && !value) {
  //     //   const endMonth = formData['to_month'];
  //     //   const endYear = formData['to_year'];
  
  //     //   const startMonth = formData['from_month'];
  //     //   const startYear = formData['from_year'];
  
  //     //   // Ensure end date (to_year, to_month) is not before the start date
  //     //   if (endYear < startYear || (endYear === startYear && endMonth < startMonth)) {
  //     //     errors['to_year'] = 'End date cannot be before start date';
  //     //     errors['to_month'] = 'End date cannot be before start date';
  //     //   }
  //     // }
  //   //   if (key === 'from_month' || key === 'from_year') {
  //   //     const startMonth = parseInt(formData['from_month'], 10) || 0; // Default to 0 if empty
  //   //     const startYear = parseInt(formData['from_year'], 10) || 0; // Default to 0 if empty
    
  //   //     const currentMonth = new Date().getMonth() + 1; // Current month (1-12)
  //   //     const currentYear = new Date().getFullYear(); // Current year

  //   //     if (startYear === currentYear && startMonth > currentMonth) {
  //   //       errors['from_month'] = 'Cannot select a future month of this year';
  //   //     } else {
  //   //       delete errors['from_year']; // Remove error if valid
  //   //     }
    
  //   //     // Prevent selecting a future month in the current year
  //   //     if (startYear > currentYear || (startYear === currentYear && startMonth > currentMonth)) {
  //   //         errors['from_year'] = 'Start month cannot be in the future';
  //   //         errors['from_month'] = 'Start month cannot be in the future';
  //   //     } else {
  //   //         delete errors['from_year']; // Remove error if corrected
  //   //         delete errors['from_month'];
  //   //     }
  //   // }
    
  //   //   if (key === 'to_year' || key === 'to_month') {
  //   //     const endMonth = parseInt(formData['to_month'], 10) || 0; // Default to 0 if empty
  //   //     const endYear = parseInt(formData['to_year'], 10) || 0; // Default to 0 if empty
      
  //   //     const startMonth = parseInt(formData['from_month'], 10) || 0;
  //   //     const startYear = parseInt(formData['from_year'], 10) || 0;
      
  //   //     const currentYear = new Date().getFullYear();
  //   //     const currentMonth = new Date().getMonth() + 1; // JavaScript months are 0-based
      
  //   //     // Prevent selecting a future month in the current year
  //   //     if (endYear === currentYear && endMonth > currentMonth) {
  //   //       errors['to_month'] = 'Cannot select a future month of this year';
  //   //     } else {
  //   //       delete errors['to_month']; // Remove error if valid
  //   //     }
      
  //   //     // Ensure end date is not before the start date
  //   //     if (endYear && startYear) {
  //   //       if (endYear < startYear || (endYear === startYear && endMonth < startMonth)) {
  //   //         errors['to_year'] = 'End date cannot be before start date';
  //   //         errors['to_month'] = 'End date cannot be before start date';
  //   //       } else {
  //   //         delete errors['to_year']; // Remove error if validation passes
  //   //         delete errors['to_month'];
  //   //       }
  //   //     }
  //   //   }
  //   if ((key === 'from_month' || key === 'from_year') && !value) {
  //     const startMonth = parseInt(formData['from_month'], 10) || 0;
  //     const startYear = parseInt(formData['from_year'], 10) || 0;
  
  //     const currentMonth = new Date().getMonth() + 1; // 1-12
  //     const currentYear = new Date().getFullYear();
  
  //     // Prevent selecting a future month of the current year
  //     if (startYear > currentYear || (startYear === currentYear && startMonth > currentMonth)) {
  //         errors['from_year'] = 'Start year cannot be in the future';
  //         errors['from_month'] = 'Start month cannot be in the future';
  //     } else {
  //         delete errors['from_year'];
  //         delete errors['from_month'];
  //     }
  // }
  
  // if ((key === 'to_year' || key === 'to_month') && !value) {
  //     const endMonth = parseInt(formData['to_month'], 10) || 0;
  //     const endYear = parseInt(formData['to_year'], 10) || 0;
  
  //     const startMonth = parseInt(formData['from_month'], 10) || 0;
  //     const startYear = parseInt(formData['from_year'], 10) || 0;
  
  //     const currentMonth = new Date().getMonth() + 1;
  //     const currentYear = new Date().getFullYear();
  
  //     // Prevent selecting a future month of the current year
  //     if (endYear > currentYear || (endYear === currentYear && endMonth > currentMonth)) {
  //         errors['to_year'] = 'End year cannot be in the future';
  //         errors['to_month'] = 'End month cannot be in the future';
  //     } else {
  //         delete errors['to_year'];
  //         delete errors['to_month'];
  //     }
  
  //     // Ensure end date is not before the start date
  //     if (endYear && startYear) {
  //         if (endYear < startYear || (endYear === startYear && endMonth < startMonth)) {
  //             errors['to_year'] = 'End date cannot be before start date';
  //             errors['to_month'] = 'End date cannot be before start date';
  //         }
  //     }
  // }
  
      
  
  //   });
  
  //   setFormErrors(errors);
  //   console.log(errors, 'vedanta');

  //   console.log(formData);

  
  //   onValidate(errors); // Call onValidate with the error object
    
  // }, [formData]);



    useEffect(() => {
      const errors = {};
    
      // Validate required fields
      Object.keys(formData).forEach(key => {
        const value = formData[key];
    
        // Validate 'job_position'
        if (key === 'job_position' && !value) {
          errors[key] = 'Job position is required';
        }
    
        // Validate 'company_name'
        if (key === 'company_name' && !value) {
          errors[key] = 'Company name is required';
        }
    
        // Validate 'location'
        if (key === 'location' && !value) {
          errors[key] = 'Location is required';
        }
    
        // Validate 'job_profile'
        if (key === 'job_profile' && !value) {
          errors[key] = 'Job profile is required';
        }
    
        // Validate 'from_month' and 'from_year' for empty values
        if ((key === 'from_month' || key === 'from_year') && !value) {
          if (key === 'from_month' && !formData['from_month']) {
            errors['from_month'] = 'Start month is required';
          }
          if (key === 'from_year' && !formData['from_year']) {
            errors['from_year'] = 'Start year is required';
          }
        }
    
        // Validate 'to_month' and 'to_year' for empty values
        if ((key === 'to_month' || key === 'to_year') && !value) {
          if (key === 'to_month' && !formData['to_month']) {
            errors['to_month'] = 'End month is required';
          }
          if (key === 'to_year' && !formData['to_year']) {
            errors['to_year'] = 'End year is required';
          }
        }
      });
    
      // Additional date validations for 'from' and 'to'
      // const startMonth = parseInt(formData['from_month'], 10) || 0;
      // const startYear = parseInt(formData['from_year'], 10) || 0;
      // const endMonth = parseInt(formData['to_month'], 10) || 0;
      // const endYear = parseInt(formData['to_year'], 10) || 0;
      // const currentMonth = new Date().getMonth() + 1; // 1-12
      // const currentYear = new Date().getFullYear();
    
      // // Prevent selecting a future month of the current year
      // if (startYear > currentYear || (startYear === currentYear && startMonth > currentMonth)) {
      //   errors['from_year'] = 'Start year cannot be in the future';
      //   errors['from_month'] = 'Start month cannot be in the future';
      // }
    
      // if (endYear > currentYear || (endYear === currentYear && endMonth > currentMonth)) {
      //   errors['to_year'] = 'End year cannot be in the future';
      //   errors['to_month'] = 'End month cannot be in the future';
      // }
    
      // // Ensure end date is not before start date
      // if (endYear && startYear) {
      //   if (endYear < startYear || (endYear === startYear && endMonth < startMonth)) {
      //     errors['to_year'] = 'End date cannot be before start date';
      //     errors['to_month'] = 'End date cannot be before start date';
      //   }
      // }


      const startMonth = parseInt(formData['from_month'], 10) || 0;
const startYear = parseInt(formData['from_year'], 10) || 0;
const endMonth = parseInt(formData['to_month'], 10) || 0;
const endYear = parseInt(formData['to_year'], 10) || 0;
const currentMonth = new Date().getMonth() + 1; // 1-12
const currentYear = new Date().getFullYear();

// Prevent selecting a future month of the current year
if (startYear > currentYear || (startYear === currentYear && startMonth > currentMonth)) {
  errors['from_year'] = 'Start year cannot be in the future';
  errors['from_month'] = 'Start month cannot be in the future';
}

if (endYear > currentYear || (endYear === currentYear && endMonth > currentMonth)) {
  errors['to_year'] = 'End year cannot be in the future';
  errors['to_month'] = 'End month cannot be in the future';
}

// Ensure start year is not greater than end year
if (startYear > endYear) {
  errors['from_year'] = 'Start year cannot be greater than end year';
  errors['to_year'] = 'End year cannot be before start year';
}

// Ensure end date is not before start date
if (endYear && startYear) {
  if (endYear < startYear || (endYear === startYear && endMonth < startMonth)) {
    errors['to_year'] = 'End year cannot be before start date';
    errors['to_month'] = 'End month cannot be before start date';
  }
}

    
      // Set form errors and call onValidate
      setFormErrors(errors);
      console.log(errors, 'vedanta');
      onValidate(errors); // Call onValidate with the error object
    
    }, [formData]);
  
  
  return (
    <>
      {formData && (
        <>
          <div className='job-form'>
            <h4 className='mt-5 mb-5'>
              {jobType.charAt(0).toUpperCase() + jobType.slice(1)} {index + 1}:
            </h4>
            {/* Job Position */}
            <div className='form-outline mb-5 DashBoardInputBx'>
              <label className='form-label'>
                Job Position <span className='RedStar'>*</span>
              </label>
              <input
                type='text'
                className={`form-control ${
                  formErrors.job_position && 'input-error'
                }`}
                name='job_position'
                placeholder='Job Position'
                value={formData.job_position}
                onChange={handleChange}
              />
              {formErrors.job_position && (
                <p className='text-danger'>{formErrors.job_position}</p>
              )}
            </div>

            <div className='form-outline mb-5 DashBoardInputBx'>
              <label className='form-label'>
                Employer (Company Name) <span className='RedStar'>*</span>
              </label>
              <input
                type='text'
                className={`form-control ${
                  formErrors.company_name && 'input-error'
                }`}
                name='company_name'
                placeholder='Company Name'
                value={formData.company_name}
                onChange={handleChange}
              />
              {formErrors.company_name && (
                <p className='text-danger'>{formErrors.company_name}</p>
              )}
            </div>

            <div className='form-outline mb-5 DashBoardInputBx'>
              <label className='form-label'>
                Location <span className='RedStar'>*</span>
              </label>
              <input
                type='text'
                className={`form-control ${
                  formErrors.location && 'input-error'
                }`}
                name='location'
                placeholder='Location'
                value={formData.location}
                onChange={handleChange}
              />
              {formErrors.location && (
                <p className='text-danger'>{formErrors.location}</p>
              )}
            </div>

            <div className='form-outline mb-5 DashBoardInputBx'>
              <h5>Duration</h5>
            </div>
            <div className='form-outline mb-5 DashBoardInputBx'>
              <div className='row'>
                <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
                  <label className='form-label'>
                    Start of Employment <span className='RedStar'>*</span>
                  </label>
                  <div className=' form-control d-md-flex gap-3'>
                    <div className='w-100 w-md-50'>
                      <select
                        name='from_month'
                        // id={`startMonth-${index}`}
                        className={`form-control form-select ${
                          formErrors.from_month && 'input-error'
                        }`}
                        placeholder='Month'
                        value={formData.from_month}
                        onChange={handleChange}
                      >
                        <option value=''>Select Month</option>
                        {/* Month options */}
                        {[
                          '01',
                          '02',
                          '03',
                          '04',
                          '05',
                          '06',
                          '07',
                          '08',
                          '09',
                          '10',
                          '11',
                          '12'
                        ].map((month, idx) => (
                          <option key={idx} value={month}>
                            {new Date(0, month - 1).toLocaleString('default', {
                              month: 'long'
                            })}
                          </option>
                        ))}
                      </select>
                      {formErrors.from_month && (
                <p className='text-danger'>{formErrors.from_month}</p>
              )}
                    </div>
                    <div className='w-100 w-md-50'>
                      <select
                        name='from_year'
                        // id={`startYear-${index}`}
                        className={`form-control form-select ${
                          formErrors.from_year && 'input-error'
                        }`}
                        placeholder='Year'
                        value={formData.from_year}
                        onChange={handleChange}
                      >
                        <option value=''>
                          {t('jobseekerExperience.selectYear')}
                        </option>

                        {yearsList.map((year, idx) => (
                          <option key={idx} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                      {formErrors.from_year && (
                <p className='text-danger'>{formErrors.from_year}</p>
              )}
                    </div>
                  </div>
                </div>

                <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
                  <div className='d-md-flex gap-3 form-control'>
                    

                    {formData.ongoing ? (
                      <>
                        <div className='w-100 w-md-50'>
                          <label className='form-label'>
                            End of Employment
                            <span className='RedStar'>*</span>
                          </label>
                          <select
                            name='to_month'
                            className={`form-control form-select ${
                              formData.to_month && 'input-error'
                            }`}
                            placeholder='Month'
                            value={currentMonth}
                            onChange={handleChange}
                            disabled={!!formData.ongoing} // Disable if ongoing is checked
                          >
                            <option value=''>Select Month</option>
                            {[...Array(12)].map((_, idx) => (
                              <option key={idx} value={idx + 1}>
                                {new Date(0, idx).toLocaleString('en-US', {
                                  month: 'long'
                                })}
                              </option>
                            ))}
                          </select>
                          {formErrors.to_month && (
                            <div className='text-danger small'>
                              {formErrors.to_month}
                            </div>
                          )}
                        </div>

                        <div className='w-100 w-md-50'>
                          <select
                            name='to_year'
                            className={`form-control form-select ${
                              formData.to_year && 'input-error'
                            }`}
                            placeholder='Year name'
                            value={currentYear}
                            onChange={handleChange}
                            disabled={!!formData.ongoing} // Disable if ongoing is checked
                          >
                            <option value=''>Select Year</option>
                            {yearsList.map((year, idx) => (
                              <option key={idx} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                          {formErrors.to_year && (
                            <div className='text-danger small'>
                              {formErrors.to_year}
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='w-100 w-md-50'>
                          <label className='form-label'>
                            End of Employment
                            <span className='RedStar'>*</span>
                          </label>
                          <select
                            name='to_month'
                            className={`form-control form-select ${
                              formErrors.to_month && 'input-error'
                            }`}
                            placeholder='Month'
                            value={formData.to_month}
                            onChange={handleChange}
                          >
                            <option value=''>Select Month</option>
                            {[
                              '01',
                              '02',
                              '03',
                              '04',
                              '05',
                              '06',
                              '07',
                              '08',
                              '09',
                              '10',
                              '11',
                              '12'
                            ].map((month, idx) => (
                              <option key={idx} value={month}>
                                {new Date(0, month - 1).toLocaleString(
                                  'default',
                                  {
                                    month: 'long'
                                  }
                                )}
                              </option>
                            ))}
                          </select>
                          {formErrors.to_month && (
                            <div className='text-danger small'>
                              {formErrors.to_month}
                            </div>
                          )}
                        </div>

                        <div className='w-100 w-md-50'>
                          <select
                            name='to_year'
                            className={`form-control form-select ${
                              formErrors.to_year && 'input-error'
                            }`}
                            placeholder='Year'
                            value={formData.to_year}
                            onChange={handleChange}
                          >
                            <option value=''>
                              {t('jobseekerExperience.selectYear')}
                            </option>
                            {yearsList.map((year, idx) => (
                              <option key={idx} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                          {formErrors.to_year && (
                            <div className='text-danger small'>
                              {formErrors.to_year}
                            </div>
                          )}
                        </div>
                      </>
                    )}

                  
                  </div>
                </div>
              </div>
            </div>

            <div className='form-outline mb-5 DashBoardInputBx ongoing-container'>
              <input
                type='checkbox'
                name='ongoing'
                checked={formData.ongoing == 1 ? true : false}
                onChange={handleChange}
                className='form-check-input'
              />

              <label htmlFor={`ongoing-${index}`} className='ongoing-label'>
                Ongoing<span className='RedStar'>*</span>
              </label>
            </div>

            <div className='form-outline mb-5 DashBoardInputBx SubjectAreaBx'>
              <label className='form-label'>
                Job Profile <span className='RedStar'>*</span>
              </label>
              <textarea
                type='text'
                className={`form-control ${
                  formErrors.job_profile && 'input-error'
                }`}
                name='job_profile'
                placeholder='What relevant tasks or projects have you worked on that are also important for the new position? (Please summarize these briefly and concisely in keywords)'
                value={formData.job_profile}
                onChange={handleChange}
              />
              {formErrors.job_profile && (
                <p className='text-danger'>{formErrors.job_profile}</p>
              )}
            </div>

            <div className='file-upload-container'>
              <div className='upload-box'>
                <input
                  type='file'
                  name='experience_certificate'
                  id={`file-input-${index}`}
                  accept='.pdf, .doc, .docx, .txt, .xls, .csv, .jpg, .jpeg'
                  multiple
                  onChange={e => handleFileChange(e, index)}
                  style={{ display: 'none' }}
                />
                <div
                  className='upload-method'
                  onClick={() =>
                    document.getElementById(`file-input-${index}`).click()
                  }
                >
                  <p>
                    <span role='img' aria-label='attachment'>
                      📎
                    </span>{' '}
                    <span className='upload-text'>Add files</span> or drag and
                    drop files here
                  </p>
                  <p className='upload-info'>
                    Supported formats: .pdf, .doc, .docx, .txt, .xls, .csv,
                    .jpg, .jpeg
                  </p>
                </div>

                {/* <ul>
                  {Array.isArray(formData.experience_certificate) &&
                  formData.experience_certificate.length > 0 ? (
                    formData.experience_certificate.map((file, fileIndex) => (
                      <li key={fileIndex}>
                        <span className='file-name'>
                        
                          {typeof file === 'object' && file.name
                            ? file.name.length > 30
                              ? `${file.name.slice(0, 30)}...`
                              : file.name
                            : file}
                        </span>
                        <button
                          type='button'
                          onClick={() =>
                            handleRemoveFileWithName(
                              file,

                              fileIndex
                            )
                          }
                          className='remove-btn'
                        >
                          &times;
                        </button>{' '}
                        : (
                        <>
                          <button
                            type='button'
                            onClick={() => handleRemoveFile(index, fileIndex)} 
                            className='remove-btn'
                          >
                            &times;
                          </button>
                        </>
                        )
                      </li>
                    ))
                  ) : (
                    <p>No experience certificates available</p>
                  )}
                </ul> */}

                {/* <ul>
                  {Array.isArray(formData.experience_certificate) &&
                  formData.experience_certificate.length > 0 ? (
                    formData.experience_certificate.map((file, fileIndex) => (
                      <li key={fileIndex}>
                        <span className='file-name'>
                      
                          {typeof file === 'object' && file.name
                            ? file.name.length > 30
                              ? `${file.name.slice(0, 30)}...`
                              : file.name
                            : file}
                        </span>
                       
                        {!file.id ? (
                          <button
                            type='button'
                            onClick={() =>
                              handleRemoveFileWithName(file, fileIndex)
                            } // Remove file from database
                            className='remove-btn'
                          >
                            &times;
                          </button>
                        ) : (
                          <button
                            type='button'
                            onClick={() => handleRemoveFile(file, fileIndex)} // Remove file from local state
                            className='remove-btn'
                          >
                            &times;
                          </button>
                        )}
                      </li>
                    ))
                  ) : (
                    <p>No experience certificates available</p>
                  )}
                </ul> */}
                <ul>
                  {Array.isArray(formData.experience_certificate) &&
                  formData.experience_certificate.length > 0 ? (
                    formData.experience_certificate.map((file, fileIndex) => (
                      <div className='file-preview'>
                        <strong>Selected Certificates:</strong>

                        <li key={fileIndex} className='file-item'>
                          <i className={`fas fa-file file-icon`}></i>
                          <span className='file-name'>
                            {/* Check if file is an object and has a 'name' property */}
                            {typeof file === 'object' && file.name
                              ? file.name.length > 30
                                ? `${file.name.slice(0, 30)}...`
                                : file.name
                              : file}
                          </span>
                          {/* Check if file is an object with an id to determine whether it's from the database */}
                          {!file.name ? (
                            <button
                              type='button'
                              onClick={() =>
                                handleRemoveFileWithName(file, fileIndex)
                              } // Remove file from database
                              className='remove-btn'
                            >
                              &times;
                            </button>
                          ) : (
                            <button
                              type='button'
                              onClick={() => handleRemoveFile(file, fileIndex)} // Remove file from local state
                              className='remove-btn'
                            >
                              &times;
                            </button>
                          )}
                        </li>
                      </div>
                    ))
                  ) : (
                    <p>No experience certificates available</p>
                  )}
                </ul>
              </div>
            </div>

            {/* <div className='removeButtonJobseeker mt-4'>
              <button
                type='button'
                onClick={() => onRemove(index)} // Pass the index to remove the specific form
                className='btn btn-primary button2'
                style={{
                  color: hoverFourthButtonColor ? primaryColor : secondaryColor,
                  backgroundColor: 'white',
                  border: hoverFourthButtonColor
                    ? `2px solid ${primaryColor}`
                    : `2px solid ${secondaryColor}`
                }}
              >
                Remove
              </button>
            </div> */}
            <div className='removeButtonJobseeker mt-4'>
  {index !== 0 && ( // Check if the index is not 0
    <button
      type='button'
      onClick={() => onRemove(index)} // Pass the index to remove the specific form
      className='btn btn-primary button2'
      style={{
        color: hoverFourthButtonColor ? primaryColor : secondaryColor,
        backgroundColor: 'white',
        border: hoverFourthButtonColor
          ? `2px solid ${primaryColor}`
          : `2px solid ${secondaryColor}`
      }}
    >
      Remove
    </button>
  )}
</div>
          </div>
        </>
      )}
    </>
  )
}

const JobTypeSelector = ({ jobType, onJobTypeChange, options }) => {
  return (
    <div className='form-group mb-3 mt-3'>
      <select
        value={jobType}
        onChange={onJobTypeChange}
        className='form-control form-select'
      >
        <option value=''>Select Experience Type</option>
        {/* {options.map((value, index) => (
          <option key={index} value={value}>
            {value}
          </option>
        ))} */}
        {Object.entries(options).map(([index, value]) => (
          <option key={index} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  )
}



const ExperienceDetails = () => {
  const primaryColor = Cookies.get('primaryColor')
  const secondaryColor = Cookies.get('secondaryColor')
  const tokenKey = Cookies.get('tokenClient')
  const { t } = useTranslation('global')
  const navigate = useNavigate()

  const [selectedFiles, setSelectedFiles] = useState([])
  const [validationErrors, setValidationErrors] = useState([])
  const [jobTypeSelections, setJobTypeSelections] = useState([{ jobType: '' }])
  const [formData, setFormData] = useState([{}])
  const [options, setOptions] = useState([])
  const [yearsList, setYearsList] = useState([])
  const [loading, setLoading] = useState(false)
  const [jobTypeSelectionsError, setJobTypeSelectionsError] = useState([
    { jobTypeError: '' }
  ])
  const [isFirstAdd, setIsFirstAdd] = useState(true)

  const getData = async () => {
    try {
      setLoading(true)
      const response = await axios.post(
        `${BaseApi}/candidates/newEditExperience`,
        null,
        {
          headers: {
            'Content-Type': 'application/json',
            key: ApiKey,
            token: tokenKey
          }
        }
      )

      if (response.data.status === 200) {
        const { expDetails, yearList, options } = response.data.response
        setOptions(options || [])
        setYearsList(yearList || [])

        if (expDetails.length > 0) {
          setFormData(expDetails)
          
          setJobTypeSelections(
            expDetails.map(detail => ({ jobType: detail.selected_value }))
          )
        } else {
          setFormData([{}])
          setJobTypeSelections([{ jobType: '' }])
        }

        setLoading(false)
      } else {
        Swal.fire({
          title: response.data.message,
          icon: 'error',
          confirmButtonText: t('searchJobPage.close')
        })
      }
    } catch (error) {
      setLoading(false)
      Swal.fire({
        title: 'An error occurred',
        text: 'There was an issue with the request. Please try again later.',
        icon: 'error',
        confirmButtonText: 'Close'
      })
    }
  }

  const handleClick = async () => {
    const dropdownErrors = jobTypeSelections.map(selection => {
      if (!selection.jobType) {
        return { jobTypeError: 'Please select job type' }
      }
      return { jobTypeError: '' }
    })

    setJobTypeSelectionsError(dropdownErrors)
    console.log('Dropdown Errors:', dropdownErrors)

    const hasDropdownErrors = dropdownErrors.some(
      error => error.jobTypeError !== ''
    )
    const hasFormErrors = validationErrors.some(
      errors => Object.keys(errors || {}).length > 0
    )

    if (hasDropdownErrors || hasFormErrors) {
      Swal.fire({
        title: 'Validation Error',
        text: 'Please fill all the details',
        icon: 'warning',
        confirmButtonText: 'Close'
      })
      return
    }

    try {
      const confirmationResult = await Swal.fire({
        title: 'Confirm Experience Details',
        text: 'Are you sure you want to save these details?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
  console.log(formData, 'formdata')

      if (confirmationResult.isConfirmed) {
        setLoading(true)
        const response = await axios.post(
          `${BaseApi}/candidates/newEditExperience`,
          { Experience: formData },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              key: ApiKey,
              token: tokenKey
            }
          }
        )

        setLoading(false)

        if (response.data.status === 200) {
          getData()
          Swal.fire({
            title: 'Success!',
            text: 'Experience details updated successfully.',
            icon: 'success',
            confirmButtonText: 'Close'
          })
          navigate('/candidates/educations')
        } else {
          Swal.fire({
            title: response.data.message,
            icon: 'error',
            confirmButtonText: 'Close'
          })
        }
      }
    } catch (error) {
      setLoading(false)
      Swal.fire({
        title: 'Failed to update Experience Details',
        text: 'There was an error updating your experience details. Please try again.',
        icon: 'error',
        confirmButtonText: 'Close'
      })
    }
  }

  const handleJobFormDataChange = (index, updatedData) => {
    setFormData(prevData => {
      const newData = [...prevData]
      newData[index] = updatedData
      return newData
    })
  }

  const handleAddDropdown = () => {
    setJobTypeSelections([...jobTypeSelections, { jobType: '' }])

    console.log(formData , 'handle Add Dropdown' );
    
    setFormData([...formData, {}])
  }

  const handleJobFormValidation = (index, errors) => {
    setValidationErrors(prevErrors => {
      const updatedErrors = [...prevErrors]
      // console.log(errors)
      updatedErrors[index] = errors
      return updatedErrors
    })
  }

  const handleJobTypeChange = (e, index) => {
    const selectedValue = e.target.value
    const newJobTypeSelections = [...jobTypeSelections]
    newJobTypeSelections[index].jobType = selectedValue
    setJobTypeSelections(newJobTypeSelections)

    setFormData(prevData => {
      const updatedData = [...prevData]
      updatedData[index] = {
        ...updatedData[index],
        selected_value: selectedValue
      }
      console.log(updatedData)
      return updatedData
    })
  }

  // const handleRemoveJobForm = index => {
  //   const newJobTypeSelections = [...jobTypeSelections]
  //   newJobTypeSelections.splice(index, 1)
  //   setJobTypeSelections(newJobTypeSelections)

  //   const newFormData = [...formData]
  //   newFormData.splice(index, 1)
  //   setFormData(newFormData)
  // }


  // const handleRemoveJobForm = (index) => {
  //   setJobTypeSelections((prev) =>
  //     prev.length > 1 ? prev.filter((_, i) => i !== index) : [{}]
  //   );
  
  //   setFormData((prev) =>
  //     prev.length > 1 ? prev.filter((_, i) => i !== index) : [{}]
  //   );
  // };

  const handleRemoveJobForm = (index) => {
    Swal.fire({
      title: "Remove Details?",
      text: "Do you want to remove this Details?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        setJobTypeSelections((prev) =>
          prev.length > 1 ? prev.filter((_, i) => i !== index) : [{}]
        );
  
        setFormData((prev) =>
          prev.length > 1 ? prev.filter((_, i) => i !== index) : [{}]
        );
  
        Swal.fire(
          'Removed!',
          'The section has been removed.',
          'success'
        );
      }
    });
  };

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
  
        <NavBar />
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <div className='container editProfile'>
          <div className='row'>
            <div className='col-lg-3'>
              <JSSidebar />
            </div>
            <div className='col-lg-9 mb-5'>
              <h3>Experience</h3>
              <p>
                Begin with your most recent or current position and work
                backward. <br />
                (If you have a lot of experience, just provide your most recent
                positions)
              </p>
              {jobTypeSelections.map((jobSelection, index) => (
                <div key={index}>
                  <JobTypeSelector
                    jobType={jobSelection.jobType}
                    onJobTypeChange={e => handleJobTypeChange(e, index)}
                    options={options}
                  />
                  {jobSelection.jobType && (
                    <JobForm
                      index={index}
                      jobType={jobSelection.jobType}
                      initialData={formData[index]}
                      onDataChange={handleJobFormDataChange}
                      onValidate={errors =>
                        handleJobFormValidation(index, errors)
                      }
                      onRemove={handleRemoveJobForm}
                      yearsList={yearsList}
                      getData={getData}
                    />
                  )}
                  
                </div>
                
              ))}
              {/* <div className='JSEPFinalButton d-flex justify-content-end my-3'>
                <button
                  // onClick={handleAddDropdown}
                  className='btn btn-primary button1'
                >
                  Remove
                </button>
              </div> */}
                 

                 

              <div className='JSEPFinalButton d-flex justify-content-end my-3'>
                <button
                  onClick={handleAddDropdown}
                  className='btn btn-primary button1'
                >
                  Add More
                </button>
              </div>
              <div className='JSEPFinalButton d-flex justify-content-between my-5'>
                <Link
                  to='/candidates/create-resume'
                  className='btn btn-primary button1'
                >
                  Back
                </Link>
                <button
                  onClick={handleClick}
                  className='btn btn-primary button1'
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


   
    
    </>
  )
}

export default ExperienceDetails
