import React, { useEffect, useState } from 'react'
import JSSidebar from './JSSidebar'
import NavBar from '../element/NavBar'
import Footer from '../element/Footer'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import ApiKey from '../api/ApiKey'
import BaseApi from '../api/BaseApi'

import Select from 'react-select'
import { useRef } from 'react'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // import styles

const NewContactInformation = () => {
  const [loading, setLoading] = useState(false)
  const [editProfile, setEditProfile] = useState([])
  const [isTooltipVisible, setTooltipVisible] = useState(false)

  const [countries, setCountries] = useState([])
  const [genders, setGenders] = useState([])
  const [maritalStatuses, setMaritalStatuses] = useState([])
  const [socialNetworks, setSocialNetworks] = useState([])
  const [drivingLicenses, setDrivingLicenses] = useState([])

  const editor = useRef(null)
  const navigate = useNavigate()

  const userId = Cookies.get('userId')
  console.log('User ID from cookies:', userId)

  const tokenKey = Cookies.get('tokenClient')
  let primaryColor = Cookies.get('primaryColor')
  let secondaryColor = Cookies.get('secondaryColor')
  const mapKey = Cookies.get('mapKey')
  const [t, i18n] = useTranslation('global')
  const [imagePreview, setImagePreview] = useState(null)
  const [error, setError] = useState('')

  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false)

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true)
  }

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false)
  }

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false)

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true)
  }

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false)
  }

  const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false)

  const handleThirdButtonMouseEnter = () => {
    setHoverThirdButtonColor(true)
  }

  const handleThirdButtonMouseLeave = () => {
    setHoverThirdButtonColor(false)
  }

  const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false)

  const handleFourthButtonMouseEnter = () => {
    setHoverFourthButtonColor(true)
  }

  const handleFourthButtonMouseLeave = () => {
    setHoverFourthButtonColor(false)
  }

  const [userData, setUserData] = useState({
    profile_image: null,
    about_me: '',
    first_name: '',
    last_name: '',
    email_address: '',
    address: '',
    postcode: '',
    mobile: '',
    phone: '',
    dateOfBirth: '',
    placeOfBirth: '',
    nationality: '',
    gender: '',
    maritalStatus: '',
    drivingLicense: '',
    socialLinks: '',
    website: '',
    others: ''
  })

  const [errors, setErrors] = useState({}) // Error handling state

  // // Handle input changes for all fields
  // // const handleChange = e => {
  // //   const { name, value } = e.target
  // //   setUserData(prevState => ({
  // //     ...prevState,
  // //     [name]: value // Dynamically update the state based on input name
  // //   }))
  // // }

  // const handleChange = (e) => {
  //   const file = e.target.files[0];

  //   if (!file) {
  //     setError('No file selected.');
  //     return;
  //   }

  //   // Validate file type
  //   if (!file.type.startsWith('image/')) {
  //     setError('Please upload a valid image file.');
  //     return;
  //   }

  //   // Validate file size (e.g., max 2MB)
  //   const maxSize = 2 * 1024 * 1024; // 2MB
  //   if (file.size > maxSize) {
  //     setUserData('File size exceeds 2MB.');
  //     return;
  //   }

  //   // Clear previous error and set the file
  //   setError('');
  //   setUserData({ ...userData, profile_image: file });

  //   // Generate a preview of the image
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     setImagePreview(reader.result);
  //   };
  //   reader.readAsDataURL(file);
  // };

  const handleChange = e => {
    const { name, value, files } = e.target

    // Handle text input changes
    if (name !== 'profile_image') {
      setUserData(prevState => ({
        ...prevState,
        [name]: value // Dynamically update the state based on input name
      }))
      return
    }

    // Handle file input changes (image upload)
    const file = files[0]

    if (!file) {
      setError('No file selected.')
      return
    }

    // Validate file type
    if (!file.type.startsWith('image/')) {
      setError('Please upload a valid image file.')
      return
    }

    // Validate file size (e.g., max 2MB)
    const maxSize = 2 * 1024 * 1024 // 2MB
    if (file.size > maxSize) {
      setError('File size exceeds 2MB.')
      return
    }

    // Clear previous error and set the file
    setError('')
    setUserData(prevState => ({ ...prevState, profile_image: file }))

    // Generate a preview of the image
    const reader = new FileReader()
    reader.onload = () => {
      setImagePreview(reader.result)
    }
    reader.readAsDataURL(file)
  }

  const handleUpload = async () => {
    if (!userData.profile_image) {
      setError('Please select an image to upload.')
      return
    }

    const formData = new FormData()
    formData.append('profile_image', userData.profile_image)

    try {
      const response = await fetch('/api/upload', {
        method: 'POST',
        body: formData
      })

      if (response.ok) {
        alert('Image uploaded successfully!')
      } else {
        setError('Failed to upload image.')
      }
    } catch (err) {
      setError('An error occurred during upload.')
    }
  }

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [address, setAddress] = useState('')
  const [postcode, setPostcode] = useState('')
  const [mobile, setMobile] = useState('')
  const [gender, setGender] = useState('')
  const [phone, setPhone] = useState('')
  const [placeOfBirth, setPlaceOfBirth] = useState('')
  const [placeOfResidence, setPlaceOfResidence] = useState('')
  const [profileImage, setProfileImage] = useState('')
  const [socialLinks, setSocialLinks] = useState([])
  const [skills, setSkills] = useState([])
  const [experience, setExperience] = useState({})
  const [locationList, setLocationList] = useState([])
  const [dateOfBirth, setDateOfBirth] = useState('')

  // const handleDateChange = e => {
  //   setDateOfBirth(e.target.value)
  // }
  // const handleGenderChange = e => {
  //   setGender(e.target.value) // Update gender state with selected value
  // }

  // const fetchData = async () => {
  //   console.log('Requesting data...')
  //   setLoading(true)

  //   try {
  //     const response = await axios.post(
  //       BaseApi + '/candidates/editProfile',
  //       null,
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           key: ApiKey,
  //           token: tokenKey
  //         }
  //       }
  //     )

  //     // Ensure the response status and data are valid
  //     if (response.status === 200 && response.data.status === 200) {
  //       const userData = response.data.response // This contains the user data

  //       // Update the userData state with the fetched data
  //       setUserData({
  //         profile_image: userData.profile_image || '',

  //         about_me: userData.about_me || '',
  //         first_name: userData.first_name || '',
  //         last_name: userData.last_name || '',
  //         email_address: userData.email_address || '',
  //         address: userData.address || '',
  //         postcode: userData.postcode || '',
  //         mobile: userData.mobile || '',
  //         phone: userData.phone || '',
  //         placeOfBirth: userData.placeOfBirth || '',
  //         placeOfResidence: userData.placeOfResidence || '',
  //         gender: userData.gender || '',
  //         maritalStatus: userData.maritalStatus || '',
  //         drivingLicense: userData.drivingLicense || '',
  //         dateOfBirth: userData.dateOfBirth || '',
  //         nationality: userData.nationality || '',
  //         profile_image: userData.profile_image || '',
  //         socialLinks: userData.socialLinks || [],
  //         skillList: userData.skillList || [],
  //         experienceArray: userData.experienceArray || [],
  //         locationlList: userData.locationlList || []
  //       })

  //       console.log('Fetched user data:', userData)

  //       // Additional logic to handle other fields like country, gender, maritalStatus
  //       // const { country, gender, maritalStatus, drivingLicense } = userData;

  //       // // Set country, gender, marital status, and driving license options as required
  //       // setCountries(
  //       //   country ? Object.entries(country).map(([key, value]) => ({ id: key, name: value })) : []
  //       // );
  //       // setGenders(
  //       //   gender ? Object.entries(gender).map(([key, value]) => ({ id: key, name: value })) : []
  //       // );
  //       // setMaritalStatuses(
  //       //   maritalStatus ? Object.entries(maritalStatus).map(([key, value]) => ({ id: key, name: value })) : []
  //       // );
  //       // setDrivingLicenses(
  //       //   drivingLicense ? Object.entries(drivingLicense).map(([key, value]) => ({ id: key, name: value })) : []
  //       // );
  //     } else {
  //       // If response status is not 200, display the message from the API
  //       Swal.fire({
  //         title: response.data.message || 'Unexpected error occurred.',
  //         icon: 'error',
  //         confirmButtonText: 'Close'
  //       })
  //     }
  //   } catch (error) {
  //     // Handle errors by logging them and showing a user-friendly message
  //     console.error('Error occurred during fetch:', error)

  //     Swal.fire({
  //       title:
  //         'Error fetching data. Please check your network connection or try again later.',
  //       icon: 'error',
  //       confirmButtonText: 'Close'
  //     })
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  const fetchData = async () => {
    console.log('Requesting data...')
    setLoading(true)

    try {
      const response = await axios.post(
        BaseApi + '/candidates/editProfile',
        null,
        {
          headers: {
            'Content-Type': 'application/json',
            key: ApiKey,
            token: tokenKey
          }
        }
      )

      // Ensure the response status and data are valid
      if (response.status === 200 && response.data.status === 200) {
        const userData = response.data.response // This contains the user data

        // Update the userData state with the fetched data
        setUserData({
          profile_image: userData.profile_image || '',
          about_me: userData.about_me || '',
          first_name: userData.first_name || '',
          last_name: userData.last_name || '',
          email_address: userData.email_address || '',
          address: userData.address || '',
          postcode: userData.postcode || '',
          mobile: userData.mobile || '',
          phone: userData.phone || '',
          placeOfBirth: userData.placeOfBirth || '',
          placeOfResidence: userData.placeOfResidence || '',
          gender: userData.gender || '',
          maritalStatus: userData.maritalStatus || '',
          drivingLicense: userData.drivingLicense || '',
          dateOfBirth: userData.dateOfBirth || '',
          nationality: userData.nationality || '',
          socialLinks: userData.socialLinks || [],
          skillList: userData.skillList || [],
          experienceArray: userData.experienceArray || [],
          locationlList: userData.locationlList || [],
          others: userData.others || '',
          website: userData.website || ''
        })
        setImagePreview(response.data.response.profile_image)
        console.log(response.data.response.profile_image)

        console.log('Fetched user data:', userData)
      } else {
        // If response status is not 200, display the message from the API
        Swal.fire({
          title: response.data.message || 'Unexpected error occurred.',
          icon: 'error',
          confirmButtonText: 'Close'
        })
      }
    } catch (error) {
      // Handle errors by logging them and showing a user-friendly message
      console.error('Error occurred during fetch:', error)

      Swal.fire({
        title:
          error.response?.data?.message ||
          'Error fetching data. Please check your network connection or try again later.',
        icon: 'error',
        confirmButtonText: 'Close'
      })
    } finally {
      setLoading(false)
    }
  }

  const getData = async () => {
    console.log('Requesting data...')
    setLoading(true)

    try {
      const response = await axios.post(BaseApi + '/users/contactinfo', null, {
        headers: {
          'Content-Type': 'application/json',
          key: ApiKey,
          token: tokenKey
        }
      })

      // Ensure the response status and data are valid
      if (response.status === 200 && response.data.status === 200) {
        const {
          // first_name, // Extract first_name
          country,
          gender,
          maritalStatus,
          socialNetwork,
          drivingLicense
        } = response.data.response.data // Access nested data correctly

        // Convert object data to arrays for mapping
        setCountries(
          Object.entries(country).map(([key, value]) => ({
            id: key,
            name: value
          }))
        )
        setGenders(
          Object.entries(gender).map(([key, value]) => ({
            id: key,
            name: value
          }))
        )
        setMaritalStatuses(
          Object.entries(maritalStatus).map(([key, value]) => ({
            id: key,
            name: value
          }))
        )
        setSocialNetworks(
          Object.entries(socialNetwork).map(([key, value]) => ({
            id: key,
            name: value
          }))
        )
        setDrivingLicenses(
          Object.entries(drivingLicense).map(([key, value]) => ({
            id: key,
            name: value
          }))
        )
      } else {
        // If response status is not 200, display the message from the API
        Swal.fire({
          title: response.data.message || 'Unexpected error occurred.',
          icon: 'error',
          confirmButtonText: 'Close'
        })
      }
    } catch (error) {
      // Handle errors by logging them and showing a user-friendly message
      console.error('Error occurred during fetch:', error)

      Swal.fire({
        title:
          'Error fetching data. Please check your network connection or try again later.',
        icon: 'error',
        confirmButtonText: 'Close'
      })
    } finally {
      setLoading(false)
    }
  }

  // Use the `first_name` state variable where needed
  useEffect(() => {
    fetchData()
    getData()
  }, [])

  //  useEffect(() => {
  //   fetchData()
  //   }, [])

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate('/user/jobseekerlogin')
    } else {
      // TokenKey is present, fetch data or perform other actions
      // getData()

      window.scrollTo(0, 0)
    }
  }, [tokenKey, navigate])

  // const handleClick = async () => {
  //   try {
  //     const newErrors = {}
  //     console.log(newErrors)
  //     if (userData.about_me === '' || userData.about_me === null) {
  //       newErrors.about_me = 'About Me Required'
  //       window.scrollTo(0, 0)
  //     }

  //     if (userData.first_name === '' || userData.first_name === null) {
  //       newErrors.first_name = t('jobseekerEditProfile.firstNameRequired')
  //       window.scrollTo(0, 0)
  //     }

  //     if (userData.last_name === '' || userData.last_name === null) {
  //       newErrors.last_name = t('jobseekerEditProfile.lastNameRequired')
  //       window.scrollTo(0, 0)
  //     }
  //     if (userData.email_address === '' || userData.email_address === null) {
  //       newErrors.email_address = 'Email Address Required'
  //       window.scrollTo(0, 0)
  //     }
  //     if (userData.address === '' || userData.address === null) {
  //       newErrors.address = 'Address Required'
  //       window.scrollTo(0, 0)
  //     }
  //     if (userData.postcode === '' || userData.postcode === null) {
  //       newErrors.postcode = 'Postcode/Zip Required'
  //       window.scrollTo(0, 0)
  //     }
  //     if (userData.gender === '' || userData.gender === null) {
  //       newErrors.gender = 'Gender Required'
  //       window.scrollTo(0, 0)
  //     }
  //     if (userData.phone === '' || userData.phone === null) {
  //       newErrors.phone = 'Phone Number Required'
  //       window.scrollTo(0, 0)
  //     }
  //     if (userData.mobile === '' || userData.mobile === null) {
  //       newErrors.mobile = 'Mobile Number Required'
  //       window.scrollTo(0, 0)
  //     }
  //     if (userData.dateOfBirth === '' || userData.dateOfBirth === null) {
  //       newErrors.dateOfBirth = 'Date of Birth Required'
  //       window.scrollTo(0, 0)
  //     }
  //     if (userData.placeOfBirth === '' || userData.placeOfBirth === null) {
  //       newErrors.placeOfBirth = 'Place Of Birth Required'
  //       window.scrollTo(0, 0)
  //     }
  //     if (userData.maritalStatus === '' || userData.maritalStatus === null) {
  //       newErrors.maritalStatus = 'Marital Status Required'
  //       window.scrollTo(0, 0)
  //     }
  //     if (userData.drivingLicense === '' || userData.drivingLicense === null) {
  //       newErrors.drivingLicense = 'Driving License Required'
  //       window.scrollTo(0, 0)
  //     }
  //     if (userData.socialLinks === '' || userData.socialLinks === null) {
  //       newErrors.socialLinks = 'Social Links Required'
  //       window.scrollTo(0, 0)
  //     }
  //     if (userData.nationality === '' || userData.nationality === null) {
  //       newErrors.nationality = 'Nationality Required'
  //       window.scrollTo(0, 0)
  //     }

  //     setErrors(newErrors)

  //     if (Object.keys(newErrors).length === 0) {
  //       const confirmationResult = await Swal.fire({
  //         title: t('jobseekerEditProfile.editConfirmTitle'),
  //         text: t('jobseekerEditProfile.editConfirmTxt'),
  //         icon: 'question',
  //         showCancelButton: true,
  //         confirmButtonText: t('jobseekerEditProfile.yes'),
  //         cancelButtonText: t('jobseekerEditProfile.no')
  //       })
  //       navigate('/candidates/professions/experience')
  //       if (confirmationResult.isConfirmed) {

  //         const response = await axios.post(
  //           BaseApi + '/candidates/editProfile',
  //           userData,
  //           {
  //             headers: {
  //               'Content-Type': 'multipart/form-data',
  //               key: ApiKey,
  //               token: tokenKey
  //             }
  //           }
  //         )
  //         setLoading(false)
  //         if (response.data.status === 200) {
  //           Swal.fire({
  //             title: t('jobseekerEditProfile.editSuccessTitle'),
  //             icon: 'success',
  //             confirmButtonText: t('jobseekerEditProfile.close')
  //           })
  //           navigate('/candidates/professions/experience')
  //         } else if (response.data.status === 400) {
  //           Cookies.remove('tokenClient')
  //           Cookies.remove('user_type')
  //           Cookies.remove('fname')
  //           navigate('/')
  //           Swal.fire({
  //             title: response.data.message,
  //             icon: 'warning',
  //             confirmButtonText: t('searchJobPage.close')
  //           })
  //         } else {
  //           Swal.fire({
  //             title: response.data.message,
  //             icon: 'error',
  //             confirmButtonText: t('jobseekerEditProfile.close')
  //           })
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     setLoading(false)
  //     if (error.message === 'Network Error') {
  //       Cookies.remove('tokenClient')
  //       Cookies.remove('user_type')
  //       Cookies.remove('fname')
  //       navigate('/')
  //       Swal.fire({
  //         title: t('tokenExpired.tokenExpired'),
  //         icon: 'warning',
  //         confirmButtonText: t('jobDescription.close')
  //       })
  //       setTimeout(function () {
  //         window.location.reload()
  //       }, 3000)
  //     }
  //     Swal.fire({
  //       title: t('jobseekerEditProfile.editFailedTitle'),
  //       icon: 'error',
  //       confirmButtonText: t('jobseekerEditProfile.close')
  //     })
  //     console.log('Could not submit edit data!')
  //   }
  // }

  const handleClick = async () => {
    try {
      const newErrors = {}

      // Validate About Me
      // if (userData.about_me === '' || userData.about_me === null) {
      //   newErrors.about_me = 'About Me Required'
      //   window.scrollTo(0, 0)
      // }

      // Validate First Name
      if (userData.first_name === '' || userData.first_name === null) {
        newErrors.first_name = t('jobseekerEditProfile.firstNameRequired')
        window.scrollTo(0, 0)
      }

      // Validate Last Name
      if (userData.last_name === '' || userData.last_name === null) {
        newErrors.last_name = t('jobseekerEditProfile.lastNameRequired')
        window.scrollTo(0, 0)
      }

      // Validate Email Address
      if (userData.email_address === '' || userData.email_address === null) {
        newErrors.email_address = 'Email Address is required'
        window.scrollTo(0, 0)
      }

      // Validate Address
      if (userData.address === '' || userData.address === null) {
        newErrors.address = 'Address is required'
        window.scrollTo(0, 0)
      }

      // Validate Postcode
      if (userData.postcode === '' || userData.postcode === null) {
        newErrors.postcode = 'Postcode/Zip is required'
        window.scrollTo(0, 0)
      }

      // Validate Gender
      if (userData.gender === '' || userData.gender === null) {
        newErrors.gender = 'Gender is required'
        window.scrollTo(0, 0)
      }
      if (userData.placeOfResidence === '' || userData.placeOfResidence === null) {
        newErrors.placeOfResidence = 'Place of Residence is required'
        window.scrollTo(0, 0)
      }

      // Validate Phone Number
      if (userData.phone === '' || userData.phone === null) {
        newErrors.phone = 'Phone Number is required'
        window.scrollTo(0, 0)
      }

      // Validate Mobile Number
      if (userData.mobile === '' || userData.mobile === null) {
        newErrors.mobile = 'Mobile Number is required'
        window.scrollTo(0, 0)
      }

      // Validate Date of Birth
      if (userData.dateOfBirth === '' || userData.dateOfBirth === null) {
        newErrors.dateOfBirth = 'Date of Birth is required'
        window.scrollTo(0, 0)
      }

      // Validate Place of Birth
      if (userData.placeOfBirth === '' || userData.placeOfBirth === null) {
        newErrors.placeOfBirth = 'Place Of Birth is required'
        window.scrollTo(0, 0)
      }

      // Validate Marital Status
      if (userData.maritalStatus === '' || userData.maritalStatus === null) {
        newErrors.maritalStatus = 'Marital Status is required'
        window.scrollTo(0, 0)
      }

      // Validate Driving License
      if (userData.drivingLicense === '' || userData.drivingLicense === null) {
        newErrors.drivingLicense = 'Driving License is required'
        window.scrollTo(0, 0)
      }

      // Validate Social Links
      if (userData.socialLinks === '' || userData.socialLinks === null) {
        newErrors.socialLinks = 'Social Links is required'
        window.scrollTo(0, 0)
      }

      // Validate Nationality
      if (userData.nationality === '' || userData.nationality === null) {
        newErrors.nationality = 'Nationality is required'
        window.scrollTo(0, 0)
      }
      // if (userData.website === '' || userData.website === null) {
      //   newErrors.website = 'website is Required'
      //   window.scrollTo(0, 0)
      // }
      // if (userData.others === '' || userData.others === null) {
      //   newErrors.others = 'others is Required'
      //   window.scrollTo(0, 0)
      // }

      // Validate Profile Image
      // if (!userData.profile_image) {
      //   newErrors.profile_image = 'Profile image is required'
      //   window.scrollTo(0, 0)
      // }

      setErrors(newErrors)

      console.log(newErrors)

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          title: t('jobseekerEditProfile.editConfirmTitle'),
          text: t('jobseekerEditProfile.editConfirmTxt'),
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: t('jobseekerEditProfile.yes'),
          cancelButtonText: t('jobseekerEditProfile.no')
        })

        if (confirmationResult.isConfirmed) {
          const formData = new FormData()

          // Append all form data to FormData
          Object.keys(userData).forEach(key => {
            if (key === 'profile_image' && userData[key]) {
              formData.append(key, userData[key]) // Append profile image
            } else {
              formData.append(key, userData[key]) // Append other fields
            }
          })

          const response = await axios.post(
            BaseApi + '/candidates/editProfile',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                key: ApiKey,
                token: tokenKey
              }
            }
          )

          setLoading(false)

          if (response.data.status === 200) {
            Swal.fire({
              title: t('jobseekerEditProfile.editSuccessTitle'),
              icon: 'success',
              confirmButtonText: t('jobseekerEditProfile.close')
            })
            navigate('/candidates/professions/experience')
          } else if (response.data.status === 400) {
            Cookies.remove('tokenClient')
            Cookies.remove('user_type')
            Cookies.remove('fname')
            navigate('/')
            Swal.fire({
              title: response.data.message,
              icon: 'warning',
              confirmButtonText: t('searchJobPage.close')
            })
          } else {
            Swal.fire({
              title: response.data.message,
              icon: 'error',
              confirmButtonText: t('jobseekerEditProfile.close')
            })
          }
        }
      }
    } catch (error) {
      setLoading(false)
      if (error.message === 'Network Error') {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/')
        Swal.fire({
          title: t('tokenExpired.tokenExpired'),
          icon: 'warning',
          confirmButtonText: t('jobDescription.close')
        })
        setTimeout(function () {
          window.location.reload()
        }, 3000)
      }
      Swal.fire({
        title: t('jobseekerEditProfile.editFailedTitle'),
        icon: 'error',
        confirmButtonText: t('jobseekerEditProfile.close')
      })
      console.log('Could not submit edit data!')
    }
  }

  return (
    <>
      <NavBar />
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <>
          <div className='container editProfile'>
            <div className='row'>
              <div className='col-lg-3'>
                <JSSidebar />
              </div>

              <div
                className='col-lg-9 mb-5'
                style={{
                  borderLeft: '2px solid #e6e8e7',
                  borderRight: '2px solid #e6e8e7'
                }}
              >
                <div className='mx-4 d-flex PageHeader'>
                  {/* <img src="/Images/employerSide/icon8color.png" alt="" /> */}
                  <h3 className=''>Contact Information</h3>
                </div>
                {/* <div
                  className='mx-3'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '20px'
                  }}
                >
                  <h3 style={{ marginRight: '10px', fontSize: '24px' }}>
                    Contact Information
                  </h3>
                  <div
                    style={{
                      position: 'relative',
                      display: 'inline-block',
                      cursor: 'pointer'
                    }}
                    onMouseEnter={() => setTooltipVisible(true)}
                    onMouseLeave={() => setTooltipVisible(false)}
                  >
                    <span
                      style={{
                        fontSize: '20px',
                        width: '24px',
                        height: '24px',
                        border: '2px solid #000',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#000',
                        fontWeight: 'bold'
                      }}
                    >
                      i
                    </span>
                    {isTooltipVisible && (
                      <div
                        style={{
                          visibility: 'visible',
                          width: '400px',
                          backgroundColor: '#555',
                          color: '#fff',
                          textAlign: 'justify',
                          borderRadius: '6px',
                          padding: '8px',
                          position: 'absolute',
                          zIndex: 20,
                          // bottom: "125%",
                          left: '50%',
                          transform: 'translateX(-50%)',
                          opacity: 1,
                          transition: 'opacity 0.3s'
                        }}
                      >
                       
                        <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                          Some employers won’t consider resumes with photos.
                          Check the job application requirements before you add
                          a photo.
                        </p>
                        <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                          How to add your photo:
                        </p>
                        <ul style={{ margin: 0, paddingLeft: '20px' }}>
                          <li style={{ listStyleType: 'disc', marginBottom: '5px' }}>
                            Use a recent color photo in JPEG, PNG, or GIF format
                            and smaller than 10 MB.
                          </li>
                          <li  style={{ listStyleType: 'disc', marginBottom: '5px' }}>
                            Crop your photo so it only shows your head and
                            shoulders.
                          </li>
                          <li  style={{ listStyleType: 'disc', marginBottom: '5px' }}>
                            If you already uploaded a photo, uploading another
                            will replace it.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div> */}

                <p className='mx-4'>
                  Enter your personal information in the corresponding fields.{' '}
                  <br />
                  You can change or update your information at any time.
                </p>

                <form>
                  {/* <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '20px 0'
                    }}
                  >
                    <div style={{ textAlign: 'center', marginRight: '20px' }}>
                      <div
                        style={{
                          width: '50px',
                          height: '50px',
                          backgroundColor: '#f1f1f1',
                          borderRadius: '5px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '5px',
                          marginLeft: '20px',
                          position: 'relative',
                          cursor: 'pointer'
                        }}
                      >
                        {imagePreview ? (
                          <img
                            src={imagePreview}
                            alt='Preview'
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              borderRadius: '5px'
                            }}
                          />
                        ) : (
                          <i
                            className='fa fa-user'
                            style={{ fontSize: '24px' }}
                          ></i>
                        )}
                        <input
                          type='file'
                          accept='image/*'
                          style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            opacity: 0,
                            cursor: 'pointer'
                          }}
                          name='profile_image'
                          // value={userData.profile_image}
                          onChange={handleChange}
                          // onChange={(e) => handleUpload(e)}
                        />
                      </div>
                      <span
                        style={{ fontSize: '12px', color: '#555' }}
                        onChange={e => handleUpload(e)}
                      >
                        Upload your photo
                      </span>
                      {error && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {error}
                        </div>
                      )}
                    </div>

                    <div style={{ textAlign: 'center' }}>
                      <div
                        style={{
                          width: '50px',
                          height: '50px',
                          backgroundColor: '#f1f1f1',
                          borderRadius: '5px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '5px'
                        }}
                      >
                        <i
                          className='fa fa-camera'
                          style={{ fontSize: '24px' }}
                        ></i>
                      </div>
                      <span style={{ fontSize: '12px', color: '#555' }}>
                        Snapshot
                      </span>
                    </div>
                  </div>

                 
                  <h4
                    style={{
                      color: '#333',
                      fontSize: '18px',
                      marginBottom: '10px',
                      marginLeft: '20px'
                    }}
                  >
                    Short profile
                  </h4>
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#555',
                      marginLeft: '20px'
                    }}
                  >
                    Summarize your resume in 3 to 5 sentences and pique the
                    interest of potential employers. Describe your professional
                    journey, goals, and ambitions. (This text serves as an
                    introduction in your resume)
                  </p> */}
                  <div className='mb-5 mt-4 mx-4'>
                    {/* <div className='form-outline mb-5 DashBoardInputBx'>
                      <label className='form-label' htmlFor='form3Example1'>
                     
                        About me
                        <span className='RedStar'>*</span>
                      </label>

                      <input
                        type='text'
                        id='form3Example1'
                        className={`form-control ${
                          errors.about_me ? 'input-error' : ''
                        }`}
                        placeholder='About us'
                        name='about_me'
                        value={userData.about_me} 
                       
                        onChange={handleChange}
                      />

                      {errors.about_me && (
                        <div className='text-danger'>{errors.about_me}</div>
                      )}
                    </div> */}
                    <div className='form-outline mb-5 DashBoardInputBx'>
                      <label className='form-label' htmlFor='form3Example1'>
                        {t('jobseekerEditProfile.firstName')}
                        <span className='RedStar'>*</span>
                      </label>

                      <input
                        type='text'
                        id='form3Example1'
                        className={`form-control ${
                          errors.first_name ? 'input-error' : ''
                        }`}
                        placeholder={t('jobseekerEditProfile.firstName')}
                        name='first_name'
                        value={userData.first_name} // Bind state to the input value
                        // onChange={e => setFirstName(e.target.value)} // Update state on input change
                        onChange={handleChange}
                      />

                      {errors.first_name && (
                        <div className='text-danger'>{errors.first_name}</div>
                      )}
                    </div>
                    <div className='form-outline mb-5 DashBoardInputBx'>
                      <label className='form-label' htmlFor='form3Example3'>
                        {t('jobseekerEditProfile.lastName')}
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='text'
                        id='form3Example3'
                        className={`form-control ${
                          errors.last_name && 'input-error'
                        }`}
                        placeholder={t('jobseekerEditProfile.lastName')}
                        name='last_name'
                        value={userData.last_name}
                        onChange={handleChange}
                      />
                      {errors.last_name && (
                        <div className='text-danger'>{errors.last_name}</div>
                      )}
                    </div>

                    <div className='form-outline mb-5 DashBoardInputBx'>
                      <label className='form-label' htmlFor='form3Example3'>
                        {/* {t("jobseekerEditProfile.lastName")} */}
                        Email Address
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='text'
                        id='form3Example3'
                        className={`form-control ${
                          errors.email_address && 'input-error'
                        }`}
                        placeholder='Email Address'
                        name='email_address'
                        value={userData.email_address}
                        onChange={handleChange}
                        readOnly
                      />
                      {errors.email_address && (
                        <div className='text-danger'>
                          {errors.email_address}
                        </div>
                      )}
                    </div>

                    <div className='form-outline mb-5 DashBoardInputBx'>
                      <label className='form-label' htmlFor='form3Example3'>
                        Address
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='text'
                        id='form3Example3'
                        className={`form-control ${
                          errors.address && 'input-error'
                        }`}
                        placeholder='Address'
                        name='address'
                        value={userData.address}
                        onChange={handleChange}
                      />

                      {errors.address && (
                        <div className='text-danger'>{errors.address}</div>
                      )}
                    </div>

                    {/* <div className='form-outline mb-5 DashBoardInputBx'>
                      <div className='d-flex justify-content-between gap-3'>
                        <div className='w-50 w-md-100'>
                          <label className='form-label' htmlFor='residence'>
                            Place of residence
                            <span className='RedStar'>*</span>
                          </label>
                          <input
                            type='text'
                            className={`form-control ${
                              errors.placeOfResidence && 'input-error'
                            }`}
                            id='residence'
                            value={userData.placeOfResidence}
                            name='placeOfResidence' // Corrected name
                            placeholder='Place of residence'
                            onChange={handleChange}
                          />
                          {errors.placeOfResidence && (
                            <div className='text-danger'>
                              {errors.placeOfResidence}
                            </div>
                          )}
                        </div>

                        <div className='w-50'>
                          <label className='form-html' htmlFor='postcode'>
                            Postcode / Zip
                            <span className='RedStar'>*</span>
                          </label>
                          <input
                            type='text'
                            className={`form-control ${
                              errors.postcode && 'input-error'
                            }`}
                            id='postcode' // Added id for accessibility
                            name='postcode'
                            value={userData.postcode}
                            placeholder='Postcode / Zip'
                            onChange={handleChange}
                          />
                          {errors.postcode && (
                            <div className='text-danger'>{errors.postcode}</div>
                          )}
                        </div>
                      </div>
                    </div> */}
                    <div className='form-outline mb-5 DashBoardInputBx'>
                      <div className='row'>
                        {/* Place of Residence */}
                        <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
                          <label className='form-html' htmlFor='residence'>
                            Place of residence
                            <span className='RedStar'>*</span>
                          </label>
                          <input
                            type='text'
                            className={`form-control ${
                              errors.placeOfResidence && 'input-error'
                            }`}
                            id='residence'
                            value={userData.placeOfResidence}
                            name='placeOfResidence'
                            placeholder='Place of residence'
                            onChange={handleChange}
                          />
                          {errors.placeOfResidence && (
                            <div className='text-danger'>
                              {errors.placeOfResidence}
                            </div>
                          )}
                        </div>

                        {/* Postcode / Zip */}
                        <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
                          <label
                            className='form-html'
                            htmlFor='postcode'
                          >
                            Postcode / Zip
                            <span className='RedStar'>*</span>
                          </label>
                          <input
                            type='text'
                            className={`form-control ${
                              errors.postcode && 'input-error'
                            }`}
                            id='postcode'
                            name='postcode'
                            value={userData.postcode}
                            placeholder='Postcode / Zip'
                            onChange={handleChange}
                          />
                          {errors.postcode && (
                            <div className='text-danger'>{errors.postcode}</div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className=' d-flex PageHeader'>
                      <h3 className=''>Additional Points</h3>
                    </div>
                    <p className=''>
                      Add here any points you would like to include in your
                      contact information
                    </p>
                    <div className='mb-5 mt-4 '>
                      <div className='form-outline mb-5 DashBoardInputBx '>
                        <div className='row'>
                          <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
                            <label
                              className='form-html'
                              htmlFor='form3Example3'
                            >
                              Phone
                              <span class='RedStar'>*</span>
                            </label>
                            <input
                              type='tel'
                              // className='form-control'
                              className={`form-control ${
                                errors.phone && 'input-error'
                              }`}
                              id='residence'
                              value={userData.phone}
                              name='phone'
                              placeholder='Contact Number'
                              onChange={handleChange}
                              maxLength={15}
                            />

                            {errors.phone && (
                              <div className='text-danger'>{errors.phone}</div>
                            )}
                          </div>
                          <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
                            <label
                              className='form-html'
                              htmlFor='form3Example3'
                            >
                              Mobile / Cell phone
                              <span class='RedStar'>*</span>
                            </label>
                            <input
                              type='tel'
                              className={`form-control ${
                                errors.mobile && 'input-error'
                              }`}
                              id='postcode'
                              name='mobile'
                              value={userData.mobile}
                              placeholder='Contact Number'
                              onChange={handleChange}
                              maxLength={15}
                            />
                            {errors.mobile && (
                              <div className='text-danger'>{errors.mobile}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mb-5 mt-4 '>
                      <div className='form-outline mb-5 DashBoardInputBx '>
                        <div className='row'>
                          <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
                            <label
                              className='form-html'
                              htmlFor='form3Example3'
                            >
                              Date of birth
                              <span class='RedStar'>*</span>
                            </label>
                            <input
                              type='date'
                              className={`form-control ${
                                errors.dateOfBirth && 'input-error'
                              }`}
                              // id='dateOfBirth'
                              // name='dateOfBirth'
                              // value={dateOfBirth}
                              // placeholder='Contact Number'
                              id='dateOfBirth'
                              name='dateOfBirth'
                              value={userData.dateOfBirth}
                              onChange={handleChange}
                              max={new Date().toISOString().split('T')[0]} // Set the max date to today
                            />
                            <div>
                              {errors.dateOfBirth && (
                                <div className='text-danger'>
                                  {errors.dateOfBirth}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
                            <label
                              className='form-html'
                              htmlFor='form3Example3'
                            >
                              Place of birth
                              <span class='RedStar'>*</span>
                            </label>

                            <select
                              name='placeOfBirth'
                              id='placeOfBirth'
                              value={userData.placeOfBirth}
                              className={`form-control form-select ${
                                errors.placeOfBirth && 'input-error'
                              }`}
                              onChange={handleChange}
                            >
                              <option value=''>Select place of birth</option>

                              {countries.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {errors.placeOfBirth && (
                              <div className='text-danger flex-column'>
                                {errors.placeOfBirth}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mb-5 mt-4 '>
                      <div className='form-outline mb-5 DashBoardInputBx '>
                        <div className='row'>
                          <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
                            <label className='form-html' htmlFor='nationality'>
                              Nationality
                              <span class='RedStar'>*</span>
                            </label>
                            <select
                              name='nationality'
                              id='nationality'
                              // className='form-control form-select'
                              className={`form-control form-select ${
                                errors.nationality && 'input-error'
                              }`}
                              value={userData.nationality}
                              onChange={handleChange}
                            >
                              <option value=''>Select Nationality</option>

                              {countries.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {errors.nationality && (
                              <div className='text-danger'>
                                {errors.nationality}
                              </div>
                            )}
                          </div>
                          <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
                            <label
                              className='form-html'
                              htmlFor='residence'
                            >
                              Place of Residence
                              <span class='RedStar'>*</span>
                            </label>
                            <select
                              name='placeOfResidence'
                              id='placeOfResidence'
                              // className='form-control form-select'
                              className={`form-control form-select ${
                                errors.placeOfResidence && 'input-error'
                              }`}
                              value={userData.placeOfResidence}
                              onChange={handleChange}
                            >
                              <option value=''>
                                Select Place of Residence
                              </option>

                              {countries.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {errors.placeOfResidence && (
                              <div className='text-danger'>
                                {errors.placeOfResidence}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mb-5 mt-4 '>
                      <div className='form-outline mb-5 DashBoardInputBx '>
                        <div className='row'>
                          <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
                            <label className='form-html' htmlFor='gender'>
                              Gender
                              <span class='RedStar'>*</span>
                            </label>
                            <select
                              name='gender'
                              id='gender'
                              value={userData.gender}
                              className={`form-control form-select ${
                                errors.gender && 'input-error'
                              }`}
                              onChange={handleChange}
                            >
                              <option value=''>Select Gender</option>

                              {genders.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {errors.gender && (
                              <div className='text-danger'>{errors.gender}</div>
                            )}
                          </div>
                          <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0 '>
                            <label
                              className='form-html'
                              htmlFor='maritalStatus'
                            >
                              Marital Status
                              <span class='RedStar'>*</span>
                            </label>
                            <select
                              name='maritalStatus'
                              id='maritalStatus'
                              // className='form-control form-select'
                              className={`form-control form-select ${
                                errors.maritalStatus && 'input-error'
                              }`}
                              value={userData.maritalStatus}
                              onChange={handleChange}
                            >
                              <option value=''>Select Marital Status</option>

                              {maritalStatuses.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {errors.maritalStatus && (
                              <div className='text-danger'>
                                {errors.maritalStatus}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mb-5 mt-4 '>
                      <div className='form-outline mb-5 DashBoardInputBx '>
                        <div className='row'>
                          <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
                            <label
                              className='form-html'
                              htmlFor='drivingLicense'
                            >
                              Driving License
                              <span class='RedStar'>*</span>
                            </label>
                            <select
                              name='drivingLicense'
                              id='drivingLicense'
                              className={`form-control form-select ${
                                errors.drivingLicense && 'input-error'
                              }`}
                              value={userData.drivingLicense}
                              onChange={handleChange}
                            >
                              <option value=''>Select Driving License</option>

                              {drivingLicenses.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {errors.drivingLicense && (
                              <div className='text-danger'>
                                {errors.drivingLicense}
                              </div>
                            )}
                          </div>
                          <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
                            <label className='form-html' htmlFor='socialLinks'>
                              Social Network
                              <span class='RedStar'>*</span>
                            </label>
                            <select
                              name='socialLinks'
                              id='socialLinks'
                              className={`form-control form-select ${
                                errors.socialLinks && 'input-error'
                              }`}
                              value={userData.socialLinks}
                              onChange={handleChange}
                            >
                              <option value=''>Select Social Network</option>

                              {socialNetworks.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {errors.socialLinks && (
                              <div className='text-danger'>
                                {errors.socialLinks}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mb-5 mt-4 '>
                      <div className='form-outline mb-5 DashBoardInputBx '>
                        <div className='row'>
                          <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
                            <label className='form-html' htmlFor='website'>
                              Website
                              {/* <span class='RedStar'>*</span> */}
                            </label>
                            <input
                              name='website'
                              id='website'
                               placeholder='https://'
                              // className={`form-control  ${
                              //   errors.website && 'input-error'
                              // }`}
                              className='form-control'
                              value={userData.website}
                              onChange={handleChange}
                            />

                            {/* {errors.website && (
                              <div className='text-danger'>
                                {errors.website}
                              </div>
                            )} */}
                          </div>
                          <div className='form-group col-lg-6 col-md-12 mb-5 mb-lg-0'>
                            <label
                              className='form-html'
                              htmlFor='socialLinks'
                            >
                              Others
                              {/* <span class='RedStar'>*</span> */}
                            </label>
                            <input
                              name='others'
                              id='others'
                               placeholder='Your Text'
                              // className={`form-control ${
                              //   errors.others && 'input-error'
                              // }`}
                              className='form-control'
                              value={userData.others}
                              onChange={handleChange}
                            />

                            {/* {errors.others && (
                              <div className='text-danger'>{errors.others}</div>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='JSEPFinalButton d-flex justify-content-between'>
                      {/* <button
                        type='button'
                        className='btn btn-primary button1'
                        onClick={handleClick}
                        style={{
                          backgroundColor: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor,
                          border: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor
                        }}
                        onMouseEnter={handleFirstButtonMouseEnter}
                        onMouseLeave={handleFirstButtonMouseLeave}
                      >
                        {t("jobseekerEditProfile.updateButton")}
                        Next
                      </button> */}
                      <Link
                        to={`/candidates/addresume`}
                        type='button'
                        // onClick={handleClick}
                        className='btn btn-primary button1'
                        style={{
                          backgroundColor: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor,
                          border: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor
                        }}
                        onMouseEnter={handleFirstButtonMouseEnter}
                        onMouseLeave={handleFirstButtonMouseLeave}
                      >
                        Back
                      </Link>
                      <button
                        // to={`/candidates/professions/experience`}
                        type='button'
                        onClick={handleClick}
                        className='btn btn-primary button1'
                        style={{
                          backgroundColor: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor,
                          border: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor
                        }}
                        onMouseEnter={handleFirstButtonMouseEnter}
                        onMouseLeave={handleFirstButtonMouseLeave}
                      >
                        Next
                      </button>
                      {/* <div className='JSEPFinalButton'>
                        <button
                          type='button'
                          className='btn btn-primary button1'
                          onClick={handleClick}
                          style={{
                            backgroundColor: hoverFirstButtonColor
                              ? secondaryColor
                              : primaryColor,
                            border: hoverFirstButtonColor
                              ? secondaryColor
                              : primaryColor
                          }}
                          onMouseEnter={handleFirstButtonMouseEnter}
                          onMouseLeave={handleFirstButtonMouseLeave}
                        >
                          {t('jobseekerEditProfile.updateButton')}
                        </button>
                        <button
                          type='button'
                          className='btn btn-primary button2'
                          style={{
                            color: hoverThirdButtonColor
                              ? primaryColor
                              : secondaryColor,
                            backgroundColor: 'white',
                            border: hoverThirdButtonColor
                              ? `2px solid ${primaryColor}`
                              : `2px solid ${secondaryColor}`
                          }}
                          onMouseEnter={handleThirdButtonMouseEnter}
                          onMouseLeave={handleThirdButtonMouseLeave}
                        >
                          {t('jobseekerEditProfile.cancelButton')}
                        </button>
                      </div> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  )
}

export default NewContactInformation