import React, { useEffect, useState } from 'react'
import JSSidebar from './JSSidebar'
import NavBar from '../element/NavBar'
import Footer from '../element/Footer'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import ApiKey from '../api/ApiKey'
import BaseApi from '../api/BaseApi'
// import Multiselect from "multiselect-react-dropdown";
import JoditEditor from 'jodit-react'
import Select from 'react-select'
import { useRef } from 'react'
import Swal from 'sweetalert2'

const Skills = () => {
  const primaryColor = Cookies.get('primaryColor')
  const secondaryColor = Cookies.get('secondaryColor')
  const [skills, setSkills] = useState([{ special_skills: '', level: '' }])

  const [validationErrors, setValidationErrors] = useState([])
  const [t, i18n] = useTranslation('global')
  const [loading, setLoading] = useState(false)
  const [interest, setInterest] = useState([
    {
      other_interest: ''
    }
  ])

  const navigate = useNavigate()
  const tokenKey = Cookies.get('tokenClient') // Assuming you're using cookies for the token

  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false)

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true)
  }

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false)
  }

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false)

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true)
  }

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false)
  }

  const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false)

  const handleThirdButtonMouseEnter = () => {
    setHoverThirdButtonColor(true)
  }

  const handleThirdButtonMouseLeave = () => {
    setHoverThirdButtonColor(false)
  }

  const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false)

  const handleFourthButtonMouseEnter = () => {
    setHoverFourthButtonColor(true)
  }

  const handleFourthButtonMouseLeave = () => {
    setHoverFourthButtonColor(false)
  }

  const handleChange = (e, index) => {
    const { name, value } = e.target
    setSkills(prevSkills => {
      const updatedSkills = [...prevSkills]
      updatedSkills[index] = {
        ...updatedSkills[index],
        [name]: value
      }
      return updatedSkills
    })
   
  }


  const handleIntrestChange = (e, index) => {
    const { name, value } = e.target
 
    setInterest(prevInterest => {
      const updatedInterest = [...prevInterest]
      updatedInterest[index] = {
        ...updatedInterest[index],
        [name]: value
      }
      return updatedInterest
    })
  }

  const handleClick = async () => {
    if (!validateFields()) {
      window.scrollTo(0, 0) // Scroll to the top to see validation errors
      return
    }

    try {
      const confirmationResult = await Swal.fire({
        title: 'Confirm Skills/Interests Details',
        text: 'Are you sure you want to save these details?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (confirmationResult.isConfirmed) {
        setLoading(true)
        console.log()

        const response = await axios.post(
          BaseApi + '/candidates/addspecialskills',
          { Skills: skills,
            Interests: interest}, // Send education details as payload
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey,
              token: tokenKey
            }
          }
        )

        setLoading(false)

        if (response.data.status === 200) {
          getData()
          Swal.fire({
            title: 'Success!',
            text: 'Skills details updated successfully.',
            icon: 'success',
            confirmButtonText: 'Close'
          })
          setSkills([]) // Reset education details
          // Optional: Reload the page or call a function to update UI
          navigate('/candidates/languages')
        } else if (response.data.status === 400) {
          Cookies.remove('tokenClient')
          Cookies.remove('user_type')
          Cookies.remove('fname')
          navigate('/candidates/parttime') // Redirect if token expired or other issue
          Swal.fire({
            title: response.data.message,
            icon: 'warning',
            confirmButtonText: 'Close'
          })
        } else {
          Swal.fire({
            title: response.data.message,
            icon: 'error',
            confirmButtonText: 'Close'
          })
        }
      }
    } catch (error) {
      setLoading(false)
      // if (error.message === "Network Error") {
      //   Cookies.remove("tokenClient");
      //   Cookies.remove("user_type");
      //   Cookies.remove("fname");
      //   navigate("/candidates/internships");
      //   Swal.fire({
      //     title: "Token Expired",
      //     icon: "warning",
      //     confirmButtonText: "Close",
      //   });
      //   setTimeout(function () {
      //     window.location.reload(); // Reload page if the token is expired
      //   }, 3000);
      // }

      Swal.fire({
        title: 'Failed to update Skills',
        text: 'There was an error updating your skills details. Please try again.',
        icon: 'error',
        confirmButtonText: 'Close'
      })
    }
  }

  const validateFields = () => {
    const errors = skills.map(skill => ({
      special_skills:
        skill.special_skills.trim() === '' ? 'Skill name is required' : '',
      level: skill.level.trim() === '' ? 'Proficiency level is required' : ''
    }))

    setValidationErrors(errors)

    return errors.every(error => !error.special_skills && !error.level)
  }

  // const validateFields = () => {
  //   // Validate skills
  //   const skillErrors = skills.map((skill) => ({
  //     special_skills:
  //       skill.special_skills.trim() === '' ? 'Skill name is required' : '',
  //     level: skill.level.trim() === '' ? 'Proficiency level is required' : '',
  //   }));

  //   // Validate interests
  //   const interestErrors = intrest.map((interest) => ({
  //     interest_name:
  //       interest.interest_name === '' ? 'Interest name is required' : '',

  //   }));

  //   // Set errors for both sections
  //   setValidationErrors({
  //     skills: skillErrors,
  //     interests: interestErrors,
  //   });

  //   // Return overall validation status
  //   const areSkillsValid = skillErrors.every(
  //     (error) => !error.special_skills && !error.level
  //   );
  //   const areInterestsValid = interestErrors.every(
  //     (error) => !error.interest_name && !error.description
  //   );

  //   return areSkillsValid && areInterestsValid;
  // };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate('/user/jobseekerlogin')
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData()

      window.scrollTo(0, 0)
      // if(educationDetails.basic_course_id) {
      // getSpecializationList(educationDetails.basic_course_id);
      // }
    }
  }, [tokenKey, navigate])

  // const getData = async () => {
  //   try {
  //     setLoading(true)
  //     const response = await axios.post(
  //       BaseApi + '/candidates/addspecialskills',
  //       null, // Pass null as the request body if not required
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           key: ApiKey,
  //           token: tokenKey
  //         }
  //       }
  //     )
  //     if (response.data.status === 200) {
  //       // if (Array.isArray(response.data)) {
  //       //   setInternships(response.data);
  //       // } else {
  //       //   setInternships([]); // Fallback if the data is not an array
  //       // }

  //       setSkills(response.data.response)
  //       console.log(response.data)

  //       // console.log("object");

  //       // code to handel pre selected interest category

  //       var categoryList = response.data.response.categoryList
  //       var interestCategory = response.data.response.interest_categories
  //       var selectedCat = []

  //       categoryList.forEach(element => {
  //         for (let i = 0; i < interestCategory.length; i++) {
  //           if (parseInt(interestCategory[i]) === element.id) {
  //             let obj = {
  //               value: element.id,
  //               label: element.name
  //             }
  //             selectedCat.push(obj)
  //           }
  //         }
  //       })

  //       setLoading(false)
  //       setSkills(response.data.response.userDetailsArray)
  //       console.log(response.data.response.userDetailsArray)

  //       console.log('check')
  //       // setDesignationList(response.data.response.designationlList);
  //       // console.log(skillList);
  //     } else if (response.data.status === 400) {
  //       Cookies.remove('tokenClient')
  //       Cookies.remove('user_type')
  //       Cookies.remove('fname')
  //       navigate('/')
  //       Swal.fire({
  //         title: response.data.message,
  //         icon: 'warning',
  //         confirmButtonText: t('searchJobPage.close')
  //       })
  //     } else {
  //       Swal.fire({
  //         title: response.data.message,
  //         icon: 'error',
  //         confirmButtonText: t('searchJobPage.close')
  //       })
  //     }
  //   } catch (error) {
  //     setLoading(false)
  //     if (error.message === 'Network Error') {
  //       Cookies.remove('tokenClient')
  //       Cookies.remove('user_type')
  //       Cookies.remove('fname')
  //       navigate('/')
  //       Swal.fire({
  //         title: t('tokenExpired.tokenExpired'),
  //         icon: 'warning',
  //         confirmButtonText: t('jobDescription.close')
  //       })
  //       setTimeout(function () {
  //         window.location.reload()
  //       }, 3000)
  //     }
  //     console.log('Cannot get data of edit profile page')
  //   }
  // }

  const getData = async () => {
    try {
      setLoading(true)
      const response = await axios.post(
        BaseApi + '/candidates/addspecialskills',
        null, // Pass null as the request body if not required
        {
          headers: {
            'Content-Type': 'application/json',
            key: ApiKey,
            token: tokenKey
          }
        }
      )

      if (response.data.status === 200) {
        const userDetailsArray = response.data.response?.userDetailsArray || [] // Safely access the data
        const otherInterestDetailsArray =
          response.data.response?.otherInterestDetailsArray || []
        // setSkills(userDetailsArray) // Update the skills state with the array
        // setInterest(userDetailsArray)
        if (response.data.response.userDetailsArray.length === 0) {
          setSkills([{ special_skills: '', level: '' }]) // Set default empty fields
          setInterest([{ other_interest: '' }])
        } else {
          setSkills(response.data.response.userDetailsArray)
          setInterest(response.data.response.otherInterestDetailsArray)
        }

        console.log('User Details:', userDetailsArray)
        console.log('Intrest Details', otherInterestDetailsArray)

        // Additional processing if needed
        const categoryList = response.data.response?.categoryList || []
        const interestCategory =
          response.data.response?.interest_categories || []
        const selectedCat = []

        categoryList.forEach(element => {
          for (let i = 0; i < interestCategory.length; i++) {
            if (parseInt(interestCategory[i]) === element.id) {
              selectedCat.push({
                value: element.id,
                label: element.name
              })
            }
          }
        })

        setLoading(false)
      } else if (response.data.status === 400) {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/')
        Swal.fire({
          title: response.data.message,
          icon: 'warning',
          confirmButtonText: 'Close'
        })
      } else {
        Swal.fire({
          title: response.data.message,
          icon: 'error',
          confirmButtonText: 'Close'
        })
      }
    } catch (error) {
      setLoading(false)
      if (error.message === 'Network Error') {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/')
        Swal.fire({
          title: 'Token Expired',
          icon: 'warning',
          confirmButtonText: 'Close'
        })
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      }
      console.log('Cannot get data of edit profile page')
    }
  }

  const handleAdd = () => {
    setSkills([...skills, { special_skills: '', level: '' }])
  }

  const handleAddInterest = () => {
    console.log('heelo' )
    setInterest([...interest, { interest: '' }])
  }

  // const handleRemove = index => {
  //   setSkills(prevSkills => prevSkills.filter((_, i) => i !== index))
  // }

  const handleRemove = async id => {
    try {
      const confirmationResult = await Swal.fire({
        title: t('jobseekerEducation.removeConfirmTitle'),
        text: t('jobseekerEducation.removeConfirmTxt'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('jobseekerEducation.yes'),
        cancelButtonText: t('jobseekerEducation.no')
      })
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteeducation/${id}`,
          null,
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey,
              token: tokenKey
            }
          }
        )
        if (response.data.status === 200) {
          getData()
          Swal.fire({
            title: t('jobseekerEducation.removeSuccessTitle'),
            icon: 'success',
            confirmButtonText: t('jobseekerEducation.close')
          })
        } else if (response.data.status === 400) {
          Cookies.remove('tokenClient')
          Cookies.remove('user_type')
          Cookies.remove('fname')
          navigate('/')
          Swal.fire({
            title: response.data.message,
            icon: 'warning',
            confirmButtonText: t('jobseekerEducation.close')
          })
        } else {
          Swal.fire({
            title: response.data.message,
            icon: 'error',
            confirmButtonText: t('jobseekerEducation.close')
          })
        }
      }
    } catch (error) {
      setLoading(false)
      if (error.message === 'Network Error') {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/')
        Swal.fire({
          title: t('tokenExpired.tokenExpired'),
          icon: 'warning',
          confirmButtonText: t('jobDescription.close')
        })
        setTimeout(function () {
          window.location.reload()
        }, 3000)
      }
      Swal.fire({
        title: t('jobseekerEducation.removeFailedTitle'),
        text: t('jobseekerEducation.removeFailedTxt'),
        icon: 'error',
        confirmButtonText: t('jobseekerEducation.close')
      })
    }
  }
  const handleRemoveIntrest = async id => {
    try {
      const confirmationResult = await Swal.fire({
        title: t('jobseekerEducation.removeConfirmTitle'),
        text: t('jobseekerEducation.removeConfirmTxt'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('jobseekerEducation.yes'),
        cancelButtonText: t('jobseekerEducation.no')
      })
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteeducation/${id}`,
          null,
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey,
              token: tokenKey
            }
          }
        )
        if (response.data.status === 200) {
          getData()
          Swal.fire({
            title: t('jobseekerEducation.removeSuccessTitle'),
            icon: 'success',
            confirmButtonText: t('jobseekerEducation.close')
          })
        } else if (response.data.status === 400) {
          Cookies.remove('tokenClient')
          Cookies.remove('user_type')
          Cookies.remove('fname')
          navigate('/')
          Swal.fire({
            title: response.data.message,
            icon: 'warning',
            confirmButtonText: t('jobseekerEducation.close')
          })
        } else {
          Swal.fire({
            title: response.data.message,
            icon: 'error',
            confirmButtonText: t('jobseekerEducation.close')
          })
        }
      }
    } catch (error) {
      setLoading(false)
      if (error.message === 'Network Error') {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/')
        Swal.fire({
          title: t('tokenExpired.tokenExpired'),
          icon: 'warning',
          confirmButtonText: t('jobDescription.close')
        })
        setTimeout(function () {
          window.location.reload()
        }, 3000)
      }
      Swal.fire({
        title: t('jobseekerEducation.removeFailedTitle'),
        text: t('jobseekerEducation.removeFailedTxt'),
        icon: 'error',
        confirmButtonText: t('jobseekerEducation.close')
      })
    }
  }

  // const handleRemoveWithoutId = (indexToRemove) => {
  //   setEducationDetails((prevEducationDetails) =>
  //     prevEducationDetails.filter((_, index) => index !== indexToRemove)
  //   );
  // };
  // const handleRemoveWithoutId = indexToRemove => {
  //   // Remove internship entry without ID (local state update)
  //   setSkills(prevInternshipDetails =>
  //     prevInternshipDetails.filter((_, index) => index !== indexToRemove)
  //   )
  // }

   const handleRemoveWithoutId = async (indexToRemove) => {
      const confirmationResult = await Swal.fire({
        title: 'Remove Details?',
        text: 'Do you want to remove this skill?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
    
      if (confirmationResult.isConfirmed) {
        setSkills(prevSkills =>
          prevSkills.filter((_, index) => index !== indexToRemove)
        )
    
        Swal.fire({
          title: 'Removed!',
          text: 'The skill has been removed.',
          icon: 'success',
          confirmButtonText: 'OK'
        })
      }
    }
  // const handleRemoveIntrestWithoutId = indexToRemove => {
  //   // Remove internship entry without ID (local state update)
  //   setInterest(prevInternshipDetails =>
  //     prevInternshipDetails.filter((_, index) => index !== indexToRemove)
  //   )
  // }

    const handleRemoveIntrestWithoutId = async (indexToRemove) => {
      const confirmationResult = await Swal.fire({
        title: "Remove Details?",
        text: 'Do you want to remove this interest?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
    
      if (confirmationResult.isConfirmed) {
        setInterest(prevInterest =>
          prevInterest.filter((_, index) => index !== indexToRemove)
        )
    
        Swal.fire({
          title: 'Removed!',
          text: 'The interest has been removed.',
          icon: 'success',
          confirmButtonText: 'OK'
        })
      }
    }
  const handleSubmit = e => {
    e.preventDefault()
    if (validateFields()) {
      // Submit logic here
      console.log('Skills:', skills)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <NavBar />
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <>
      <div className='container editProfile'>
        <div className='row'>
          <div className='col-lg-3'>
            <JSSidebar />
          </div>
          <div
            className='col-lg-9 mb-5'
            style={{
              borderLeft: '2px solid #e6e8e7',
              borderRight: '2px solid #e6e8e7'
            }}
          >
            <div className='mx-3 d-flex PageHeader'>
              <h3>Special skills / expertise</h3>
            </div>
            <p className='mx-3'>
              Do you have special talents or skills that you would like to
              mention?
            </p>
            <form onSubmit={handleSubmit}>
              {skills.map((j, index) => (
                <div key={index} className='mb-3 mt-3 mx-3'>
                  <h4 className='mt-5 mb-5'>Skill {index + 1}:</h4>

                  {/* <div className='d-flex gap-4 form-outline mb-5 DashBoardInputBx'>
                    <div className='form-group w-50'>
                      <label className='form-label'>
                        Special skills / expertise
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='text'
                        className={`form-control ${
                          validationErrors[index]?.special_skills &&
                          'input-error'
                        }`}
                        placeholder='Special skill'
                        name='special_skills'
                        value={j.special_skills}
                        onChange={e => handleChange(e, index)}
                      />
                      {validationErrors[index]?.special_skills && (
                        <div className='text-danger small'>
                          {validationErrors[index].special_skills}
                        </div>
                      )}
                    </div>

                    <div className='form-group w-50'>
                      <label className='form-html'>
                        Level
                        <span className='RedStar'>*</span>
                      </label>
                      <select
                        className={`form-control form-select ${
                          validationErrors[index]?.level && 'input-error'
                        }`}
                        name='level'
                        value={j.level}
                        onChange={e => handleChange(e, index)}
                      >
                        <option value=''>Select proficiency level</option>
                        <option value='Beginner'>Beginner</option>
                        <option value='Intermediate'>Intermediate</option>
                        <option value='Advanced'>Advanced</option>
                        <option value='Expert'>Expert</option>
                      </select>
                      {validationErrors[index]?.level && (
                        <div className='text-danger small'>
                          {validationErrors[index].level}
                        </div>
                      )}
                    </div>
                  </div> */}

<div className='row g-3 form-outline mb-5 DashBoardInputBx'>
  {/* Special Skills */}
  <div className='col-12 col-md-6 mb-md-0 mb-3'>
    <div className='form-group'>
      <label className='form-label'>
        Special skills / expertise
        <span className='RedStar'>*</span>
      </label>
      <input
        type='text'
        className={`form-control ${
          validationErrors[index]?.special_skills && 'input-error'
        }`}
        placeholder='Special skill'
        name='special_skills'
        value={j.special_skills}
        onChange={e => handleChange(e, index)}
      />
      {validationErrors[index]?.special_skills && (
        <div className='text-danger small'>
          {validationErrors[index].special_skills}
        </div>
      )}
    </div>
  </div>

  {/* Level */}
  <div className='col-12 col-md-6'>
    <div className='form-group'>
      <label className='form-label'>
        Level
        <span className='RedStar'>*</span>
      </label>
      <select
        className={`form-control form-select ${
          validationErrors[index]?.level && 'input-error'
        }`}
        name='level'
        value={j.level}
        onChange={e => handleChange(e, index)}
      >
        <option value=''>Select proficiency level</option>
        <option value='Beginner'>Beginner</option>
        <option value='Intermediate'>Intermediate</option>
        <option value='Advanced'>Advanced</option>
        <option value='Expert'>Expert</option>
      </select>
      {validationErrors[index]?.level && (
        <div className='text-danger small'>
          {validationErrors[index].level}
        </div>
      )}
    </div>
  </div>
</div>

                  <div className='removeButtonJobseeker mt-4'>
                    {/* {j.id ? (
                      <>
                        <button
                          type='button'
                          className='btn btn-primary button2'
                          onClick={() => handleRemove(j.id)}
                          style={{
                            color: hoverFourthButtonColor
                              ? primaryColor
                              : secondaryColor,
                            backgroundColor: 'white',
                            border: hoverFourthButtonColor
                              ? `2px solid ${primaryColor}`
                              : `2px solid ${secondaryColor}`
                          }}
                          // onMouseEnter={handleFourthButtonMouseEnter}
                          // onMouseLeave={handleFourthButtonMouseLeave}
                        >
                          {t('jobseekerEducation.removeButton')}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type='button'
                          className='btn btn-primary button2'
                          onClick={() => handleRemoveWithoutId(index)}
                          style={{
                            color: hoverFourthButtonColor
                              ? primaryColor
                              : secondaryColor,
                            backgroundColor: 'white',
                            border: hoverFourthButtonColor
                              ? `2px solid ${primaryColor}`
                              : `2px solid ${secondaryColor}`
                          }}
                          // onMouseEnter={handleFourthButtonMouseEnter}
                          // onMouseLeave={handleFourthButtonMouseLeave}
                        >
                          {t('jobseekerEducation.removeButton')}
                        </button>
                      </>
                    )} */}
                                        {index !== 0 && (
  <>
    {j.id ? (
      <button
        type='button'
        className='btn btn-primary button2'
        onClick={() => handleRemoveWithoutId(index)}
        style={{
          color: hoverFourthButtonColor ? primaryColor : secondaryColor,
          backgroundColor: 'white',
          border: hoverFourthButtonColor
            ? `2px solid ${primaryColor}`
            : `2px solid ${secondaryColor}`
        }}
      >
        {t('jobseekerEducation.removeButton')}
      </button>
    ) : (
      <button
        type='button'
        className='btn btn-primary button2'
        onClick={() => handleRemoveWithoutId(index)}
        style={{
          color: hoverFourthButtonColor ? primaryColor : secondaryColor,
          backgroundColor: 'white',
          border: hoverFourthButtonColor
            ? `2px solid ${primaryColor}`
            : `2px solid ${secondaryColor}`
        }}
      >
        {t('jobseekerEducation.removeButton')}
      </button>
    )}
  </>
)}
                  </div>
                </div>
              ))}
              {skills.length > 0 && (
                <>
                  <div className='EduAddMore mb-4 mx-3'>
                    <button
                      type='button'
                      className='btn btn-primary button1'
                      onClick={handleAdd}
                      style={{
                        backgroundColor: primaryColor,
                        color: 'white',
                        border: primaryColor
                      }}
                    >
                      {t('jobseekerEducation.addMoreButton')}
                    </button>
                  </div>
                </>
              )}
              {/* {skills.length <= 0 && (
                <>
                  <div className='EduAddMore mb-4'>
                    <button
                      type='button'
                      className='btn btn-primary button1'
                      onClick={handleAdd}
                      style={{
                        backgroundColor: primaryColor,
                        color: 'white',
                        border: primaryColor
                      }}
                    >
                      {t('jobseekerEducation.addDetails')}
                    </button>
                  </div>
                </>
              )} */}
            </form>

            <div className='mx-3 d-flex PageHeader'>
              <h3>Other interests </h3>
            </div>
            <p className='mx-3'>
              Do you have an exciting hobby or interest that you would like to
              mention?
            </p>
            <form onSubmit={handleSubmit}>
              {interest.map((j, index) => (
                <div key={index} className='mb-3 mt-3 mx-3'>
                  <h4 className='mt-3 mb-5'>Interest {index + 1}:</h4>

                  <div className=' form-outline mb-3 DashBoardInputBx'>
                    <div className='form-outline '>
                      <label className='form-label'>
                        Other Interests
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='text'
                        className={`form-control ${
                          validationErrors[index]?.other_interest &&
                          'input-error'
                        }`}
                        placeholder='Other interest'
                        name='other_interest'
                        value={j.other_interest}
                        onChange={e => handleIntrestChange(e, index)}
                      />
                      {validationErrors[index]?.other_interest && (
                        <div className='text-danger small'>
                          {validationErrors[index].other_interest}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => handleRemoveSkill(index)}
                    disabled={skills.length === 1}
                  >
                    Remove Skill
                  </button> */}
                  <div className='removeButtonJobseeker mt-4'>
                    {/* {j.id ? (
                      <>
                        <button
                          type='button'
                          className='btn btn-primary button2'
                          onClick={() => handleRemoveIntrest(j.id)}
                          style={{
                            color: hoverFourthButtonColor
                              ? primaryColor
                              : secondaryColor,
                            backgroundColor: 'white',
                            border: hoverFourthButtonColor
                              ? `2px solid ${primaryColor}`
                              : `2px solid ${secondaryColor}`
                          }}
                          // onMouseEnter={handleFourthButtonMouseEnter}
                          // onMouseLeave={handleFourthButtonMouseLeave}
                        >
                          {t('jobseekerEducation.removeButton')}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type='button'
                          className='btn btn-primary button2'
                          onClick={() => handleRemoveIntrestWithoutId(index)}
                          style={{
                            color: hoverFourthButtonColor
                              ? primaryColor
                              : secondaryColor,
                            backgroundColor: 'white',
                            border: hoverFourthButtonColor
                              ? `2px solid ${primaryColor}`
                              : `2px solid ${secondaryColor}`
                          }}
                          // onMouseEnter={handleFourthButtonMouseEnter}
                          // onMouseLeave={handleFourthButtonMouseLeave}
                        >
                          {t('jobseekerEducation.removeButton')}
                        </button>
                      </>
                    )} */}

{index !== 0 && (
  <>
    {j.id ? (
      <button
        type='button'
        className='btn btn-primary button2'
        onClick={() => handleRemoveIntrestWithoutId(index)}
        style={{
          color: hoverFourthButtonColor ? primaryColor : secondaryColor,
          backgroundColor: 'white',
          border: hoverFourthButtonColor
            ? `2px solid ${primaryColor}`
            : `2px solid ${secondaryColor}`
        }}
      >
        {t('jobseekerEducation.removeButton')}
      </button>
    ) : (
      <button
        type='button'
        className='btn btn-primary button2'
        onClick={() => handleRemoveIntrestWithoutId(index)}
        style={{
          color: hoverFourthButtonColor ? primaryColor : secondaryColor,
          backgroundColor: 'white',
          border: hoverFourthButtonColor
            ? `2px solid ${primaryColor}`
            : `2px solid ${secondaryColor}`
        }}
      >
        {t('jobseekerEducation.removeButton')}
      </button>
    )}
  </>
)}
                  </div>
                </div>
              ))}
              {interest.length > 0 && (
                <>
                  <div className='EduAddMore mb-4 mx-3'>
                    <button
                      type='button'
                      className='btn btn-primary button1'
                      onClick={handleAddInterest}
                      style={{
                        backgroundColor: primaryColor,
                        color: 'white',
                        border: primaryColor
                      }}
                    >
                      {t('jobseekerEducation.addMoreButton')}
                    </button>
                  </div>
                </>
              )}
              {/* {interest.length <= 0 && (
                <>
                  <div className='EduAddMore mb-4'>
                    <button
                      type='button'
                      className='btn btn-primary button1'
                      onClick={handleAddInterest}
                      style={{
                        backgroundColor: primaryColor,
                        color: 'white',
                        border: primaryColor
                      }}
                    >
                      {t('jobseekerEducation.addDetails')}
                    </button>
                  </div>
                </>
              )} */}
            </form>
            {/* {skills.length > 0 && (
              <>
                <div className='bottomButtonsEducation'>
                  <button
                    type='button'
                    className='btn btn-primary button1'
                    onClick={handleClick}
                    style={{
                      backgroundColor: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor
                    }}
                    onMouseEnter={handleFirstButtonMouseEnter}
                    onMouseLeave={handleFirstButtonMouseLeave}
                  >
                    {t('jobseekerEducation.updateButton')}
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary button2'
                    style={{
                      color: hoverThirdButtonColor
                        ? primaryColor
                        : secondaryColor,
                      backgroundColor: 'white',
                      border: hoverThirdButtonColor
                        ? `2px solid ${primaryColor}`
                        : `2px solid ${secondaryColor}`
                    }}
                    onMouseEnter={handleThirdButtonMouseEnter}
                    onMouseLeave={handleThirdButtonMouseLeave}
                    onClick={() => navigate('/candidates/myaccount')}
                  >
                    {t('jobseekerEducation.cancelButton')}
                  </button>
                </div>
              </>
            )} */}

            <div className='JSEPFinalButton d-flex justify-content-between my-5'>
              <Link
                to={`/candidates/educations`}
                type='button'
                // onClick={handleClick}
                className='btn btn-primary button1'
                style={{
                  backgroundColor: hoverFirstButtonColor
                    ? secondaryColor
                    : primaryColor,
                  border: hoverFirstButtonColor ? secondaryColor : primaryColor
                }}
                onMouseEnter={handleFirstButtonMouseEnter}
                onMouseLeave={handleFirstButtonMouseLeave}
              >
                Back
              </Link>
              <button
                // to={`/candidates/professions/experience`}
                type='button'
                onClick={handleClick}
                className='btn btn-primary button1'
                style={{
                  backgroundColor: hoverFirstButtonColor
                    ? secondaryColor
                    : primaryColor,
                  border: hoverFirstButtonColor ? secondaryColor : primaryColor
                }}
                onMouseEnter={handleFirstButtonMouseEnter}
                onMouseLeave={handleFirstButtonMouseLeave}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      </>
      )
      }
    </>
  )
}

export default Skills
