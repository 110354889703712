import React, { useEffect, useState } from 'react'
import JSSidebar from './JSSidebar'
import NavBar from '../element/NavBar'
import Footer from '../element/Footer'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import ApiKey from '../api/ApiKey'
import BaseApi from '../api/BaseApi'
// import Multiselect from "multiselect-react-dropdown";
import JoditEditor from 'jodit-react'
import Select from 'react-select'
import { useRef } from 'react'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // import styles

const EducationDetails = ({ education_id, index }) => {
  const [loading, setLoading] = useState(false)
  let primaryColor = Cookies.get('primaryColor')
  let secondaryColor = Cookies.get('secondaryColor')
  const [errors, setErrors] = useState({})

  const [validationErrors, setValidationErrors] = useState([])
  const [suggestionTakenNative, setSuggestionTakenNative] = useState(false)
  const [suggestionsNative, setSuggestionsNative] = useState([])
  const [yearsList, setYearsList] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])

  const mapKey = Cookies.get('mapKey')
  const [t, i18n] = useTranslation('global')
  // const [educations, setEducationDetails] = useState([
  //   {
  //     qualifications: '',

  //     institutions: '',
  //     location: '',
  //     start_month: '',
  //     start_year: '',
  //     end_month: '',
  //     end_year: '',
  //     // ongoing: false,
  //     subject_area: '',
  //     education_certificate: ''
  //   }
  // ])
  const [educations, setEducationDetails] = useState([
    {
      qualifications: '',
      institutions: '',
      location: '',
      start_month: '',
      start_year: '',
      end_month: '',
      end_year: '',
      subject_area: '',
      ongoing: 0, // add ongoing flag
      education_certificate: [] // Array to hold multiple files for each education entry
    }
  ])

  // const getFileIcon = file => {
  //   // Check if the input is a File object and extract the name
  //   const fileName = typeof file === 'object' && file.name ? file.name : file

  //   // Ensure fileName is a valid string
  //   if (typeof fileName !== 'string') {
  //     console.error('Invalid file input:', file)
  //     return 'fa-file' // Default icon for invalid input
  //   }

  //   const ext = fileName.split('.').pop().toLowerCase()
  //   const icons = {
  //     pdf: 'fa-file-pdf',
  //     doc: 'fa-file-word',
  //     docx: 'fa-file-word',
  //     txt: 'fa-file-alt',
  //     xls: 'fa-file-excel',
  //     xlsx: 'fa-file-excel',
  //     csv: 'fa-file-csv',
  //     jpg: 'fa-file-image',
  //     jpeg: 'fa-file-image'
  //   }

  //   return icons[ext] || 'fa-file' // Default to a generic file icon
  // }

  const getFileIcon = file => {
    // If file is an array, process each file and return an array of icons
    if (Array.isArray(file)) {
      return file.map(f => getFileIcon(f)) // Recursively call getFileIcon for each file in the array
    }

    // Check if the input is a File object and extract the name
    const fileName = typeof file === 'object' && file.name ? file.name : file

    // Ensure fileName is a valid string
    if (typeof fileName !== 'string') {
      console.error('Invalid file input:', file)
      return 'fa-file' // Default icon for invalid input
    }

    // Extract file extension
    const ext = fileName.split('.').pop().toLowerCase()

    // Define icon mapping for file extensions
    const icons = {
      pdf: 'fa-file-pdf',
      doc: 'fa-file-word',
      docx: 'fa-file-word',
      txt: 'fa-file-alt',
      xls: 'fa-file-excel',
      xlsx: 'fa-file-excel',
      csv: 'fa-file-csv',
      jpg: 'fa-file-image',
      jpeg: 'fa-file-image'
    }

    return icons[ext] || 'fa-file' // Default to a generic file icon
  }

  // const [educations, setEducations] = useState([]);

  // const [educations, setEducationDetails] = useState([]);

  const [autocompleteService, setAutocompleteService] = useState(null)

  const navigate = useNavigate()
  const tokenKey = Cookies.get('tokenClient') // Assuming you're using cookies for the token

  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false)

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true)
  }

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false)
  }
  const [ongoing, setOngoing] = useState(false)
  const currentMonth = new Date().getMonth() + 1 // JavaScript months are 0-indexed
  const currentYear = new Date().getFullYear()

  // const handleFileChange = (event, index) => {
  //   const files = Array.from(event.target.files) // Convert FileList to an array

  //   console.log(selectedFiles, 'Current selected files')

  //   // Check for file limit
  //   if (selectedFiles.length + files.length > 5) {
  //     Swal.fire({
  //       title: 'File Limit Exceeded',
  //       text: 'You can upload a maximum of 5 files.',
  //       icon: 'error'
  //     })
  //     return
  //   }

  //   // Check for duplicates
  //   const isDuplicate = files.some(
  //     newFile =>
  //       selectedFiles.some(existingFile => existingFile.name === newFile.name) // Check if the new file name already exists in selectedFiles
  //   )

  //   console.log(isDuplicate, 'isDuplicate')

  //   if (isDuplicate) {
  //     Swal.fire({
  //       title: 'Duplicate Files',
  //       text: 'Some files are duplicates and were not added.',
  //       icon: 'error'
  //     })
  //     return
  //   }

  //   // Update the selectedFiles state with the new files
  //   setSelectedFiles(prevFiles => [
  //     ...prevFiles,
  //     ...files // Add the new file objects to the selectedFiles array
  //   ])

  //   // Update the education details with the new files (without calling API)
  //   setEducationDetails(prevInternships => {
  //     const updatedInternships = [...prevInternships]
  //     updatedInternships[index] = {
  //       ...updatedInternships[index],
  //       education_certificate: [
  //         ...(updatedInternships[index].education_certificate || []), // Ensure we start with existing certificates
  //         ...files // Add the new file objects
  //       ]
  //     }
  //     return updatedInternships
  //   })
  // }

  // const handleFileChange = (event, index) => {
  //   const files = Array.from(event.target.files) // Convert FileList to an array

  //   console.log('Selected Files:', files)

  //   // Check for file limit
  //   if (selectedFiles.length + files.length > 5) {
  //     Swal.fire({
  //       title: 'File Limit Exceeded',
  //       text: 'You can upload a maximum of 5 files.',
  //       icon: 'error'
  //     })
  //     return
  //   }

  //   // Check for duplicates
  //   const isDuplicate = files.some(
  //     newFile =>
  //       selectedFiles.some(existingFile => existingFile.name === newFile.name) // Check if the new file name already exists in selectedFiles
  //   )

  //   console.log(isDuplicate, 'isDuplicate')

  //   if (isDuplicate) {
  //     Swal.fire({
  //       title: 'Duplicate Files',
  //       text: 'Some files are duplicates and were not added.',
  //       icon: 'error'
  //     })
  //     return
  //   }

  //   // Add files to selectedFiles (as file objects)
  //   setSelectedFiles(prevFiles => [
  //     ...prevFiles,
  //     ...files // Add the new file objects to the selectedFiles array
  //   ])

  //   // Update education certificates in the relevant education object
  //   setEducationDetails(prevInternships => {
  //     const updatedInternships = [...prevInternships]
  //     updatedInternships[index] = {
  //       ...updatedInternships[index],
  //       education_certificate: [
  //         ...(updatedInternships[index].education_certificate || []), // Ensure we start with existing certificates
  //         ...files.map(file => file.name) // Add the file names to education_certificate
  //       ]
  //     }
  //     return updatedInternships
  //   })
  // }

  // const handleFileChange = (event, index) => {
  //   const files = Array.from(event.target.files); // Convert FileList to an array

  //   console.log('Selected Files:', files);

  //   if (selectedFiles.length + files.length > 5) {
  //     Swal.fire({
  //       title: 'File Limit Exceeded',
  //       text: 'You can upload a maximum of 5 files.',
  //       icon: 'error'
  //     });
  //     return;
  //   }

  //   const isDuplicate = files.some(newFile =>
  //     selectedFiles.some(existingFile => existingFile.name === newFile.name)
  //   );

  //   console.log(isDuplicate, 'isDuplicate');

  //   if (isDuplicate) {
  //     Swal.fire({
  //       title: 'Duplicate Files',
  //       text: 'Some files are duplicates and were not added.',
  //       icon: 'error'
  //     });
  //     return;
  //   }

  //   setSelectedFiles(prevFiles => [...prevFiles, ...files]);

  //   // Update education certificates in the relevant education object
  //   setEducationDetails(prevDetails => {
  //     const updatedDetails = [...prevDetails];
  //     updatedDetails[index] = {
  //       ...updatedDetails[index],
  //       education_certificate: [
  //         ...(updatedDetails[index].education_certificate || []),
  //         ...files // Store File objects instead of just names
  //       ]
  //     };
  //     return updatedDetails;
  //   });
  // };

  // const handleFileChange = (event, index) => {
  //   const files = Array.from(event.target.files); // Convert FileList to an array

  //   console.log('Selected Files:', files);

  //   if (selectedFiles.length + files.length > 5) {
  //     Swal.fire({
  //       title: 'File Limit Exceeded',
  //       text: 'You can upload a maximum of 5 files.',
  //       icon: 'error'
  //     });
  //     return;
  //   }

  //   const isDuplicate = files.some(newFile =>
  //     selectedFiles.some(existingFile => existingFile.name === newFile.name)
  //   );

  //   console.log(isDuplicate, 'isDuplicate');

  //   if (isDuplicate) {
  //     Swal.fire({
  //       title: 'Duplicate Files',
  //       text: 'Some files are duplicates and were not added.',
  //       icon: 'error'
  //     });
  //     return;
  //   }

  //   setSelectedFiles(prevFiles => [...prevFiles, ...files]);

  //   // Update the state with the file objects (not just the names)
  //   setEducationDetails(prevDetails => {
  //     const updatedDetails = [...prevDetails];
  //     updatedDetails[index] = {
  //       ...updatedDetails[index],
  //       education_certificate: [
  //         ...(updatedDetails[index].education_certificate || []),
  //         ...files // Store the actual file objects
  //       ]
  //     };
  //     return updatedDetails;
  //   });
  // };

  // const handleFileChange = (event, index) => {
  //   const files = Array.from(event.target.files) // Convert FileList to an array

  //   console.log('Selected Files:', files)

  //   if (selectedFiles.length + files.length > 5) {
  //     Swal.fire({
  //       title: 'File Limit Exceeded',
  //       text: 'You can upload a maximum of 5 files.',
  //       icon: 'error'
  //     })
  //     return
  //   }

  //   const isDuplicate = files.some(newFile =>
  //     selectedFiles.some(existingFile => existingFile.name === newFile.name)
  //   )

  //   console.log(isDuplicate, 'isDuplicate')

  //   if (isDuplicate) {
  //     Swal.fire({
  //       title: 'Duplicate Files',
  //       text: 'Some files are duplicates and were not added.',
  //       icon: 'error'
  //     })
  //     return
  //   }

  //   setSelectedFiles(prevFiles => [...prevFiles, ...files])

  //   // Update the state with the file objects (not just the names)
  //   setEducationDetails(prevDetails => {
  //     const updatedDetails = [...prevDetails]
  //     updatedDetails[index] = {
  //       ...updatedDetails[index],
  //       education_certificate: [
  //         ...(updatedDetails[index].education_certificate || []),
  //         ...files // Store the actual file objects
  //       ]
  //     }
  //     return updatedDetails
  //   })
  // }



    const handleFileChange = (event, index) => {
      const files = Array.from(event.target.files); // Convert FileList to an array
      console.log("Selected Files:", files);
    
      setEducationDetails((prevDetails) => {
        const updatedDetails = [...prevDetails];
        const existingFiles = updatedDetails[index]?.education_certificate || [];
    
        // Check if the total number of files exceeds the limit (5)
        if (existingFiles.length + files.length > 5) {
          Swal.fire({
            title: "File Limit Exceeded",
            text: "You can upload a maximum of 5 files per form.",
            icon: "error",
          });
          return prevDetails; // Return without updating state
        }
    
        // Check for duplicate files based on file name
        const newFiles = files.filter(
          (newFile) => !existingFiles.some((existingFile) => existingFile.name === newFile.name)
        );
    
        if (newFiles.length < files.length) {
          Swal.fire({
            title: "Duplicate Files",
            text: "Some files are duplicates and were not added.",
            icon: "error",
          });
        }
    
        // Update only if there are new files
        updatedDetails[index] = {
          ...updatedDetails[index],
          education_certificate: [...existingFiles, ...newFiles], // Append new files
        };
    
        return updatedDetails;
      });
    };

  // const handleRemoveFileWithName = async (file) => {
  //   try {
  //     console.log(file, 'File')
  //   } catch (error) {
  //     console.log(error.message)
  //   }
  // }

  // const handleRemoveFileWithName = async (file, education_id, index) => {
  //   try {
  //     // Log to check what education_id is
  //     console.log('Education ID before parsing:', education_id);

  //     // Check if education_id is an array or object and extract the ID correctly
  //     const educationId = Array.isArray(education_id) ? education_id[0]?.id : parseInt(education_id, 10);

  //     console.log('Education ID after parsing:', educationId);

  //     // Check if educationId is valid
  //     if (isNaN(educationId) || !Number.isInteger(educationId)) {
  //       console.error('Invalid education ID:', educationId);
  //       return;
  //     }

  //     // API call to delete the file
  //     const response = await axios.post(BaseApi + '/candidates/deleteEducationCertificate', {
  //       education_id: educationId,  // Send as a single integer
  //       file_name: file,        // Send the file name to be deleted
  //     }, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         key: ApiKey, // Replace with your actual API key
  //         token: tokenKey, // Replace with the user's token
  //       },
  //     });

  //     if (response.data.status === 200) {
  //       // Successfully deleted file, update state or remove file from selectedFiles
  //       setSelectedFiles(prevFiles => {
  //         const newFiles = [...prevFiles];
  //         newFiles.splice(index, 1); // Remove the file from the list
  //         return newFiles;
  //       });
  //       console.log('File deleted successfully');
  //     } else {
  //       console.error('Failed to delete file');
  //     }
  //   } catch (error) {
  //     console.error('Error deleting file:', error);
  //   }
  // };

  // const handleRemoveFileWithName = async (file, education_id, index) => {
  //   try {
  //     // Log to check what education_id is
  //     console.log('Education ID before parsing:', education_id)

  //     // Check if education_id is an array or object and extract the ID correctly
  //     const educationId = Array.isArray(education_id)
  //       ? education_id[0]?.id
  //       : parseInt(education_id, 10)

  //     console.log('Education ID after parsing:', educationId)

  //     // Check if educationId is valid
  //     if (isNaN(educationId) || !Number.isInteger(educationId)) {
  //       console.error('Invalid education ID:', educationId)
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Invalid Education ID',
  //         text: 'The Education ID is invalid. Please try again.'
  //       })
  //       return
  //     }

  //     // SweetAlert confirmation dialog
  //     const result = await Swal.fire({
  //       title: 'Are you sure?',
  //       text: `Are you sure you want to delete the file "${file}"?`,
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonText: 'Yes, delete it!',
  //       cancelButtonText: 'No, keep it'
  //     })

  //     if (result.isConfirmed) {
  //       // Proceed with the API call to delete the file
  //       const response = await axios.post(
  //         BaseApi + '/candidates/deleteEducationCertificate',
  //         {
  //           education_id: educationId, // Send as a single integer
  //           file_name: file // Send the file name to be deleted
  //         },
  //         {
  //           headers: {
  //             'Content-Type': 'application/json',
  //             key: ApiKey, // Replace with your actual API key
  //             token: tokenKey // Replace with the user's token
  //           }
  //         }
  //       )

  //       // Check the response from the API
  //       if (response.data.status === 200) {
  //         // Successfully deleted file, update state or remove file from selectedFiles
  //         setSelectedFiles(prevFiles => {
  //           const newFiles = [...prevFiles]
  //           newFiles.splice(index, 1) // Remove the file from the list
  //           return newFiles
  //         })

  //         // Show success message with SweetAlert
  //         Swal.fire({
  //           icon: 'success',
  //           title: 'File Deleted Successfully',
  //           text: `The file "${file}" has been deleted.`
  //         })
  //         console.log('File deleted successfully')
  //       } else {
  //         console.error('Failed to delete file')
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Error',
  //           text: 'Failed to delete the file. Please try again.'
  //         })
  //       }
  //     } else {
  //       // User canceled the deletion
  //       console.log('File deletion canceled')
  //     }
  //   } catch (error) {
  //     console.error('Error deleting file:', error)
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Error',
  //       text: 'An error occurred while deleting the file. Please try again.'
  //     })
  //   }
  // }

  const handleRemoveFileWithName = async (education_id, file_name, index) => {
    try {
      // Log to check what education_id is
      console.log('Education ID before parsing:', education_id)

      // Check if education_id is an array or object and extract the ID correctly
      const educationId = Array.isArray(education_id)
        ? education_id[0]?.id
        : parseInt(education_id, 10)

      console.log('Education ID after parsing:', educationId)

      // Check if educationId is valid
      if (isNaN(educationId) || !Number.isInteger(educationId)) {
        console.error('Invalid education ID:', educationId)
        Swal.fire({
          icon: 'error',
          title: 'Invalid Education ID',
          text: 'The Education ID is invalid. Please try again.'
        })
        return
      }

      // SweetAlert confirmation dialog
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `Are you sure you want to delete the certificate "${file_name}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      })

      if (result.isConfirmed) {
        // Proceed with the API call to delete the certificate
        const response = await axios.post(
          BaseApi + '/candidates/deleteEducationCertificate',
          {
            education_id: educationId, // Send the education ID
            file_name: file_name // Send the file name to be deleted
          },
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey, // Replace with your actual API key
              token: tokenKey // Replace with the user's token
            }
          }
        )

        // Check the response from the API
        if (response.data.status === 200) {
          // Successfully deleted certificate, update state or remove certificate from educations
          setEducationDetails(prevEducations => {
            const updatedEducations = [...prevEducations]
            const educationToUpdate = updatedEducations.find(
              education => education.id === educationId
            )

            if (educationToUpdate) {
              // Remove the certificate from the education's education_certificate array
              educationToUpdate.education_certificate =
                educationToUpdate.education_certificate.filter(
                  certificate => certificate !== file_name
                )
            }

            return updatedEducations
          })

          // Show success message with SweetAlert
          Swal.fire({
            icon: 'success',
            title: 'Certificate Deleted Successfully',
            text: `The certificate "${file_name}" has been deleted.`
          })
          console.log('Certificate deleted successfully')
        } else {
          console.error('Failed to delete certificate')
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete the certificate. Please try again.'
          })
        }
      } else {
        // User canceled the deletion
        console.log('Certificate deletion canceled')
      }
    } catch (error) {
      console.error('Error deleting certificate:', error)
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while deleting the certificate. Please try again.'
      })
    }
  }

  // const handleRemoveFile = (index, fileIndex) => {
  //   // Update the selected files state
  //   setSelectedFiles(prevFiles => {
  //     // Create a new array by filtering out the file at fileIndex
  //     const updatedFiles = prevFiles.filter((_, idx) => idx !== fileIndex)
  //     return updatedFiles // Return the updated files array
  //   })

  //   // Update the education details state
  //   setEducationDetails(prevEducation => {
  //     // Create a shallow copy of the education details array
  //     const updatedEducation = [...prevEducation]

  //     // Check if the index and property exist before updating
  //     if (
  //       updatedEducation[index] &&
  //       Array.isArray(updatedEducation[index].education_certificate)
  //     ) {
  //       // Create a new array by filtering out the file at fileIndex
  //       updatedEducation[index] = {
  //         ...updatedEducation[index], // Shallow copy of the specific object
  //         education_certificate: updatedEducation[
  //           index
  //         ].education_certificate.filter(
  //           (_, idx) => idx !== fileIndex // Remove the file at the given index
  //         )
  //       }
  //     } else {
  //       console.warn(
  //         `No education certificate found at index ${index} or invalid structure`
  //       )
  //     }

  //     return updatedEducation // Return the updated education array
  //   })
  // }

  const handleRemoveFile = (index, fileIndex) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to remove this file?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
      cancelButtonText: 'Cancel'
    }).then(result => {
      if (result.isConfirmed) {
        console.log(index, fileIndex)

        // Update the selected files state
        setSelectedFiles(prevFiles => {
          const updatedFiles = prevFiles.filter((_, idx) => idx !== fileIndex)
          return updatedFiles
        })

        // Update the education details state
        setEducationDetails(prevEducation => {
          const updatedEducation = [...prevEducation]

          if (
            updatedEducation[index] &&
            Array.isArray(updatedEducation[index].education_certificate)
          ) {
            updatedEducation[index] = {
              ...updatedEducation[index],
              education_certificate: updatedEducation[
                index
              ].education_certificate.filter((_, idx) => idx !== fileIndex)
            }
          } else {
            console.warn(
              `No education certificate found at index ${index} or invalid structure`
            )
          }

          return updatedEducation
        })

        // Show success message
        Swal.fire({
          title: 'Deleted!',
          text: 'The file has been removed.',
          icon: 'success',
          timer: 1500
        })
      }
    })
  }

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false)

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true)
  }

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false)
  }

  const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false)

  const handleThirdButtonMouseEnter = () => {
    setHoverThirdButtonColor(true)
  }

  const handleThirdButtonMouseLeave = () => {
    setHoverThirdButtonColor(false)
  }

  const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false)

  const handleFourthButtonMouseEnter = () => {
    setHoverFourthButtonColor(true)
  }
  const handleNativeSuggestionClick = suggestion => {
    // Update the input value with the clicked suggestion
    handleNativeLocationChange({
      target: { name: 'location', value: suggestion }
    })

    setSuggestionTakenNative(true)
    // Clear the suggestions
    setSuggestionsNative([])
    // console.log(filterItem);
  }

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate('/user/jobseekerlogin')
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData()
      window.scrollTo(0, 0)
      // if(educationDetails.basic_course_id) {
      // getSpecializationList(educationDetails.basic_course_id);
      // }
    }
  }, [tokenKey, navigate])
  const getData = async () => {
    try {
      setLoading(true)
      const response = await axios.post(
        BaseApi + '/candidates/editEducation',
        null, // Pass null as the request body if not required
        {
          headers: {
            'Content-Type': 'application/json',
            key: ApiKey,
            token: tokenKey
          }
        }
      )
      if (response.data.status === 200) {
        if (response.data.response.eduDetails.length === 0) {
          setEducationDetails([
            {
              qualifications: '',

              institutions: '',
              location: '',
              start_month: '',
              start_year: '',
              end_month: '',
              end_year: '',
              ongoing: 0,
              subject_area: '',
              education_certificate: ''
            }
          ]) // Set default empty fields
        } else {
          setEducationDetails(response.data.response.eduDetails)
          console.log(response.data.response.eduDetails)
        }
        setYearsList(response.data.response.yearList)
        // console.log(response.data.response)
        // const certificates = response.data.response.eduDetails
        //   .map(eduDetails =>
        //     eduDetails.education_certificate
        //       ? eduDetails.education_certificate
        //       : []
        //   )
        //   .flat()
        // setSelectedFiles(certificates)
        // console.log('Selected Files:', certificates)

        const certificates = response.data.response.eduDetails.map(
          eduDetails => {
            // Ensure education_certificate is an array even if it's null
            return eduDetails.education_certificate
              ? eduDetails.education_certificate
              : []
          }
        )

        // Flatten the array of arrays to get a single list of certificates
        const flattenedCertificates = [].concat(...certificates)

        // Set the selectedFiles state with the certificates
        setSelectedFiles(flattenedCertificates)

        console.log('Selected Files:', flattenedCertificates)

        setLoading(false)
      } else if (response.data.status === 400) {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/')
        Swal.fire({
          title: response.data.message,
          icon: 'warning',
          confirmButtonText: t('searchJobPage.close')
        })
      } else {
        Swal.fire({
          title: response.data.message,
          icon: 'error',
          confirmButtonText: t('searchJobPage.close')
        })
      }
    } catch (error) {
      setLoading(false)
      if (error.message === 'Network Error') {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/')
        Swal.fire({
          title: t('tokenExpired.tokenExpired'),
          icon: 'warning',
          confirmButtonText: t('jobDescription.close')
        })
        setTimeout(function () {
          window.location.reload()
        }, 3000)
      }
      console.log('Cannot get data of edit profile page')
    }
  }

  const handleFourthButtonMouseLeave = () => {
    setHoverFourthButtonColor(false)
  }

  // const handleChange = (e, index) => {
  //   const { name, value, type, checked } = e.target
  //   if (educations.ongoing) {
  //     setOngoing(true)
  //     // setFormData({
  //     //   ...formData,
  //     //  to_month: currentMonth,
  //     //  to_year: currentYear
  //     // })
  //   }

  //   // Update the educations array
  //   // const updatedEducations = [...educations]
  //   // updatedEducations[index][name] =
  //   //   type === 'checkbox' ? (checked ? '1' : '0') : value

  //   // Update the educations array
  //   const updatedEducations = [...educations]
  //   // updatedEducations[index][name] = checked;
  //   updatedEducations[index][name] = type === 'checkbox' ? checked : value

  //   // Clear validation error for the specific field
  //   const updatedValidationErrors = [...validationErrors]
  //   if (updatedValidationErrors[index]) {
  //     delete updatedValidationErrors[index][name] // Remove the error for this field
  //   }

  //   setEducationDetails(updatedEducations)
  //   setValidationErrors(updatedValidationErrors) // Update validation errors
  // }



  const handleChange = (e, index) => {
    const { name, value, type, checked } = e.target;
  
    const updatedEducations = [...educations];
  
    if (type === "checkbox") {
      updatedEducations[index][name] = checked;
      if (name === "ongoing" && checked) {
        setOngoing(true);
        updatedEducations[index].end_month = currentMonth;
        updatedEducations[index].end_year = currentYear;
      } else if (name === "ongoing" && !checked) {
        setOngoing(false);
        updatedEducations[index].end_month = "";
        updatedEducations[index].end_year = "";
      }
    } else {
      updatedEducations[index][name] = value;
    }
  
    // Clear validation errors for the specific field
    const updatedValidationErrors = [...validationErrors];
    if (updatedValidationErrors[index]) {
      delete updatedValidationErrors[index][name];
    }
  
    setEducationDetails(updatedEducations);
    setValidationErrors(updatedValidationErrors);
  };
  

  // const handleChange = (e, index) => {
  //   const { name, value, type, checked } = e.target

  //   // Update the educations array
  //   const updatedEducations = [...educations]
  //   updatedEducations[index][name] =
  //     type === 'checkbox' ? (checked ? '1' : '0') : value

  //   // Clear validation error for the specific field
  //   const updatedValidationErrors = [...validationErrors]
  //   if (updatedValidationErrors[index]) {
  //     delete updatedValidationErrors[index][name] // Remove the error for this field
  //   }

  //   // Update state with the new values
  //   setEducationDetails(updatedEducations)
  //   setValidationErrors(updatedValidationErrors) // Update validation errors
  // }

  // const handleChange = (e, index, id) => {
  //   const { name, checked,value, type } = e.target;

  //   // Update the educations array
  //   const updatedEducations = [...educations];
  //   updatedEducations[index][name] = checked; // Directly store the boolean value for checkboxes

  //   // Clear validation error for the specific field
  //   const updatedValidationErrors = [...validationErrors];
  //   if (updatedValidationErrors[index]) {
  //     delete updatedValidationErrors[index][name]; // Remove the error for this field
  //   }

  //   // Update state with the new values
  //   setEducationDetails(updatedEducations);
  //   setValidationErrors(updatedValidationErrors); // Update validation errors
  // };

  const handleRemove = async id => {
    try {
      const confirmationResult = await Swal.fire({
        title: t('jobseekerEducation.removeConfirmTitle'),
        text: t('jobseekerEducation.removeConfirmTxt'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('jobseekerEducation.yes'),
        cancelButtonText: t('jobseekerEducation.no')
      })
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteeducation/${id}`,
          null,
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey,
              token: tokenKey
            }
          }
        )
        if (response.data.status === 200) {
          getData()
          Swal.fire({
            title: t('jobseekerEducation.removeSuccessTitle'),
            icon: 'success',
            confirmButtonText: t('jobseekerEducation.close')
          })
        } else if (response.data.status === 400) {
          Cookies.remove('tokenClient')
          Cookies.remove('user_type')
          Cookies.remove('fname')
          navigate('/')
          Swal.fire({
            title: response.data.message,
            icon: 'warning',
            confirmButtonText: t('jobseekerEducation.close')
          })
        } else {
          Swal.fire({
            title: response.data.message,
            icon: 'error',
            confirmButtonText: t('jobseekerEducation.close')
          })
        }
      }
    } catch (error) {
      setLoading(false)
      if (error.message === 'Network Error') {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/')
        Swal.fire({
          title: t('tokenExpired.tokenExpired'),
          icon: 'warning',
          confirmButtonText: t('jobDescription.close')
        })
        setTimeout(function () {
          window.location.reload()
        }, 3000)
      }
      Swal.fire({
        title: t('jobseekerEducation.removeFailedTitle'),
        text: t('jobseekerEducation.removeFailedTxt'),
        icon: 'error',
        confirmButtonText: t('jobseekerEducation.close')
      })
    }
  }

  const handleRemoveWithoutId = indexToRemove => {
    // Show SweetAlert confirmation dialog
    Swal.fire({
      title: "Remove Details?",
      text: "Do you want to remove this Details?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        // Remove item from the state after confirmation
        setEducationDetails(prevInternshipDetails =>
          prevInternshipDetails.filter((_, index) => index !== indexToRemove)
        )
        
        // Show success alert after removal
        Swal.fire(
          'Removed!',
          'Your item has been removed.',
          'success'
        )
      }
    })
  }

  const handleNativeLocationChange = e => {
    const { value } = e.target
    setSuggestionTakenNative(false)
    if (value == '') {
      setSuggestionTakenNative(true)
    }
    if (value != '') {
      setErrors({
        ...errors,
        location: ''
      })
    }

    if (autocompleteService) {
      // Call Google Maps Autocomplete API
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ['(cities)'] // Restrict to cities if needed
        },
        (predictions, status) => {
          if (status === 'OK' && predictions) {
            setSuggestionsNative(
              predictions.map(prediction => prediction.description)
            )
          } else {
            setSuggestionsNative([])
          }
        }
      )
    }
    // if (editProfile.location === '') {
    //   setSuggestionsNative([])
    // }
  }

  // const handleAdd = () => {
  //   const newQualification = {
  //     // basic_course_id: '', // Set default values for the new block
  //     // basic_university: '',
  //     // basic_year: '',
  //     // // graduation_title: "",
  //     // specialization_id: '' // Include specialization_id in the new education detail
  //     qualifications: '',

  //     institutions: '',
  //     location: '',
  //     start_month: '',
  //     start_year: '',
  //     end_month: '',
  //     end_year: '',
  //     ongoing: false,
  //     subject_area: '',
  //     education_certificate:''

  //   }

  //   setEducationDetails(prevEducationDetails => [
  //     ...prevEducationDetails,
  //     newQualification
  //   ])

  // console.log(selectedFiles)
  // }

  // const handleAdd = () => {
  //   const newQualification = {
  //     qualifications: '',
  //     institutions: '',
  //     location: '',
  //     start_month: '',
  //     start_year: '',
  //     end_month: '',
  //     end_year: '',
  //     ongoing: false,
  //     subject_area: '',
  //     education_certificate: '', // Initialize as an empty array for the new qualification

  //   }

  //   // Update education details
  //   setEducationDetails(prevEducationDetails => [
  //     ...prevEducationDetails,
  //     newQualification
  //   ])
  //   console.log(newQualification)

  //   // Reset selected files for the new qualification
  //   setSelectedFiles(prevSelectedFiles => [
  //     ...prevSelectedFiles,
  //     []
  //   ]);

  //   console.log('New qualification added:', newQualification)
  // }

  // const handleAdd = (index) => {
  //   // Get the current index based on the length of the educationDetails array
  //   const currentIndex = educations.length;
  //   console.log(currentIndex)

  //   const newQualification = {
  //     qualifications: '',
  //     institutions: '',
  //     location: '',
  //     start_month: '',
  //     start_year: '',
  //     end_month: '',
  //     end_year: '',
  //     ongoing: false,
  //     subject_area: '',

  //   };

  //   // Update education details
  //   setEducationDetails(prevEducationDetails => [
  //     ...prevEducationDetails,
  //     newQualification
  //   ]);

  //   // Reset selected files for the new qualification
  //   setSelectedFiles(prevSelectedFiles => [
  //     ...prevSelectedFiles,
  //     [] // Initialize an empty array for the new qualification's selected files
  //   ]);

  //   console.log('New qualification added at index:', currentIndex);
  // }

  // const handleAdd = (index) => {
  //   // Get the current index based on the length of the educationDetails array
  //   const currentIndex = educations.length;
  //   console.log('Adding new qualification at index:', currentIndex);

  //   const newQualification = {
  //     qualifications: '',
  //     institutions: '',
  //     location: '',
  //     start_month: '',
  //     start_year: '',
  //     end_month: '',
  //     end_year: '',
  //     ongoing: false,
  //     subject_area: '',
  //   };

  //   // Update education details with the new qualification (empty fields)
  //   setEducationDetails(prevEducationDetails => [
  //     ...prevEducationDetails,
  //     newQualification
  //   ]);

  //   // Reset selected files for the new qualification
  //   setSelectedFiles(prevSelectedFiles => [
  //     ...prevSelectedFiles,
  //     [] // Initialize an empty array for the new qualification's selected files
  //   ]);

  //   console.log('New qualification added with empty fields:', newQualification);
  // };

  // const handleAdd = () => {
  //   setEducationDetails(prevEducationDetails => [
  //     ...prevEducationDetails,
  //     {
  //       qualifications: '',
  //       institutions: '',
  //       location: '',
  //       start_month: '',
  //       start_year: '',
  //       end_month: '',
  //       end_year: '',
  //       ongoing: false,
  //       subject_area: ''
  //     }
  //   ])
  //   console.log(selectedFiles)
  // }

  const handleAdd = () => {
    setEducationDetails(prevEducationDetails => [
      ...prevEducationDetails,
      {
        qualifications: '',
        institutions: '',
        location: '',
        start_month: '',
        start_year: '',
        end_month: '',
        end_year: '',
        ongoing: 0,
        subject_area: '',
        education_certificate: [] // Empty file array for new entry
      }
    ])

    console.log('New education added with empty certificate array')
  }

  // const validateFields = () => {
  //   const errors = educations.map((education, index) => ({
  //     qualifications:
  //       education.qualifications?.trim() === ''
  //         ? 'Qualifications is required'
  //         : '',
  //     institutions:
  //       education.institutions?.trim() === '' ? 'Institution is required' : '',
  //     start_year:
  //       education.start_year?.trim() === '' ? 'Start year is required' : '',
  //     location: education.location?.trim() === '' ? 'Location is required' : '',
  //     subject_area:
  //       education.subject_area?.trim() === '' ? 'Subject is required' : '',
  //     start_month:
  //       education.start_month?.trim() === ''
  //         ? 'Start month is required'
  //         : parseInt(education.start_month) > currentMonth &&
  //           parseInt(education.start_year) === currentYear
  //         ? 'Start month cannot be ahead of the current month'
  //         : '',
  //     // end_month:
  //     //   education.end_month?.trim() === '' ? 'End month is required' : '',
  //     // end_year: education.end_year?.trim() === '' ? 'End year is required' : ''
  //     end_month:
  //       education.end_month?.trim() === ''
  //         ? 'End month is required'
  //         : parseInt(education.end_month) > currentMonth &&
  //           parseInt(education.end_year) === currentYear
  //         ? 'End month cannot be ahead of the current month'
  //         : '',
  //     end_year: education.end_year?.trim() === '' ? 'End year is required' : ''
  //     // ongoing: education.ongoing === false ? false : true // Check if the job is ongoing
  //   }))

  //   // const errors = educations.map((education, index) => ({
  //   //   qualifications:
  //   //     typeof education.qualifications === 'string' && education.qualifications.trim() === ''
  //   //       ? 'Qualifications is required'
  //   //       : '',
  //   //   institutions:
  //   //     typeof education.institutions === 'string' && education.institutions.trim() === ''
  //   //       ? 'Institution is required'
  //   //       : '',
  //   //   start_year:
  //   //     typeof education.start_year === 'string' && education.start_year.trim() === ''
  //   //       ? 'Start year is required'
  //   //       : '',
  //   //   location:
  //   //     typeof education.location === 'string' && education.location.trim() === ''
  //   //       ? 'Location is required'
  //   //       : '',
  //   //   subject_area:
  //   //     typeof education.subject_area === 'string' && education.subject_area.trim() === ''
  //   //       ? 'Subject is required'
  //   //       : '',
  //   //   start_month:
  //   //     typeof education.start_month === 'string' && education.start_month.trim() === ''
  //   //       ? 'Start month is required'
  //   //       : '',
  //   //   end_month:
  //   //     typeof education.end_month === 'string' && education.end_month.trim() === ''
  //   //       ? 'End month is required'
  //   //       : '',
  //   //   end_year:
  //   //     typeof education.end_year === 'string' && education.end_year.trim() === ''
  //   //       ? 'End year is required'
  //   //       : ''
  //   //   // ongoing: education.ongoing === false ? false : true // Check if the job is ongoing
  //   // }))

  //   setValidationErrors(errors) // Set the validation errors in the state
  //   console.log(errors)

  //   // Check if all fields are valid (no error messages)
  //   return errors.every(
  //     error =>
  //       !error.qualifications &&
  //       !error.institutions &&
  //       !error.start_year &&
  //       !error.location &&
  //       !error.subject_area &&
  //       !error.start_month &&
  //       !error.end_month &&
  //       !error.end_year
  //   )
  // }



  const validateFields = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Months are 0-indexed in JS
    
    const errors = educations.map((education) => {
      const startYear = Number(education.start_year);
      const startMonth = Number(education.start_month);
      const endYear = Number(education.end_year);
      const endMonth = Number(education.end_month);
      const isOngoing = education.ongoing; // If "Ongoing" is a field
  
      return {
        qualifications:
          education.qualifications?.trim() === '' ? 'Qualifications is required' : '',
  
        institutions:
          education.institutions?.trim() === '' ? 'Institution is required' : '',
  
        start_year:
          education.start_year?.trim() === '' ? 'Start year is required' : '',
  
        start_month:
          education.start_month?.trim() === ''
            ? 'Start month is required'
            : startYear === currentYear && startMonth > currentMonth
            ? 'Start month cannot be in the future'
            : '',
  
        location: education.location?.trim() === '' ? 'Location is required' : '',
  
        subject_area:
          education.subject_area?.trim() === '' ? 'Subject is required' : '',
  
        end_year:
          !isOngoing && education.end_year?.trim() === ''
            ? 'End year is required'
            : '',
  
        end_month:
          !isOngoing && education.end_month?.trim() === ''
            ? 'End month is required'
            : endYear === currentYear && endMonth > currentMonth
            ? 'End month cannot be in the future'
            : '',
  
        // Ensure end date is not before start date
        date_order:
          !isOngoing &&
          startYear &&
          endYear &&
          (endYear < startYear || (endYear === startYear && endMonth < startMonth))
            ? 'End date cannot be before start date'
            : '',
      };
    });
  
    setValidationErrors(errors); // Set errors in state
    console.log(errors);
  
    // Check if all fields are valid
    return errors.every(
      (error) =>
        !error.qualifications &&
        !error.institutions &&
        !error.start_year &&
        !error.start_month &&
        !error.location &&
        !error.subject_area &&
        !error.end_month &&
        !error.end_year &&
        !error.date_order
    );
  };
  
  // Function to handle form submission
  const handleClick = async () => {
    if (!validateFields()) {
      window.scrollTo(0, 0) // Scroll to the top to see validation errors
      return
    }

    try {
      const confirmationResult = await Swal.fire({
        title: 'Confirm Education Details',
        text: 'Are you sure you want to save these details?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (confirmationResult.isConfirmed) {
        setLoading(true)
        // console.log()
        // const formData = new FormData()

        // educations.forEach((edu, index) => {
        //   edu.education_certificate.forEach(file => {
        //     formData.append(`education_certificate_${index}[]`, file) // Append file object
        //   })
        // })

        const response = await axios.post(
          BaseApi + '/candidates/editEducation',
          // null,
          { Education: educations }, // Send education details as payload
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              key: ApiKey, // Replace with your actual API key
              token: tokenKey
            }
          }
        )

        setLoading(false)

        if (response.data.status === 200) {
          Swal.fire({
            title: 'Success!',
            text: 'Educations details updated successfully.',
            icon: 'success',
            confirmButtonText: 'Close'
          })
          // setEducationDetails([]) // Reset education details
          // Optional: Reload the page or call a function to update UI
          navigate('/candidates/skills')
        } else if (response.data.status === 400) {
          Cookies.remove('tokenClient')
          Cookies.remove('user_type')
          Cookies.remove('fname')
          navigate('/candidates/skills') // Redirect if token expired or other issue
          Swal.fire({
            title: response.data.message,
            icon: 'warning',
            confirmButtonText: 'Close'
          })
        } else {
          Swal.fire({
            title: response.data.message,
            icon: 'error',
            confirmButtonText: 'Close'
          })
        }
      }
    } catch (error) {
      setLoading(false)
      if (error.message === 'Network Error') {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/candidates/skills')
        Swal.fire({
          title: 'Token Expired',
          icon: 'warning',
          confirmButtonText: 'Close'
        })
        setTimeout(function () {
          window.location.reload() // Reload page if the token is expired
        }, 3000)
      }

      Swal.fire({
        title: 'Failed to update Educations details',
        text: 'There was an error updating your Educations details details. Please try again.',
        icon: 'error',
        confirmButtonText: 'Close'
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <NavBar />
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <>
          <div className='container editProfile'>
            <div className='row'>
              <div className='col-lg-3'>
                <JSSidebar />
              </div>

              <div
                className='col-lg-9 mb-5'
                style={{
                  borderLeft: '2px solid #e6e8e7',
                  borderRight: '2px solid #e6e8e7'
                }}
              >
                <div className='mx-3 d-flex PageHeader'>
                  <h3 className=''>Education</h3>
                </div>
                <p className='mx-3'>
                  If your desired details are not available for selection here,
                  then select the qualification that comes closest to yours.
                </p>

                <form>
                  <div className='mb-3 mt-3 mx-3'>
                    {Array.isArray(educations) && educations.length > 0 ? (
                      educations.map((j, index) => {
                        return (
                          <div key={index}>
                            <>
                              <h4 className='mt-5 mb-5'>
                                Education Details {index + 1}:
                              </h4>

                              <div className='form-outline mb-5 DashBoardInputBx'>
                                <label
                                  className='form-label'
                                  htmlFor='form3Example1'
                                >
                                  Qualification
                                  <span className='RedStar'>*</span>
                                </label>

                                <select
                                  name='qualifications'
                                  id='qualifications'
                                  className={`form-control form-select ${
                                    validationErrors[index]?.qualifications &&
                                    'input-error'
                                  }`}
                                  value={j.qualifications || ''}
                                  onChange={e => handleChange(e, index, j.id)}
                                >
                                  <option value=''>Select Qualification</option>
                                  <option value='Year 9-11'>
                                    Completed Year 9-11
                                  </option>
                                  <option value='High School'>
                                    Completed High School (Year 12)
                                  </option>
                                  <option value='Diploma'>Diploma</option>
                                  <option value='TAFE/Trade Certificate'>
                                    TAFE/Trade Certificate
                                  </option>
                                  <option value='Undergraduate'>
                                    Undergraduate
                                  </option>
                                  <option value='Postgraduate'>
                                    Postgraduate Degree
                                  </option>
                                  <option value="Master's Degree">
                                    Master's Degree
                                  </option>
                                  <option value='Doctorate'>PhD</option>
                                  <option value='Not Specified'>
                                    Not Specified
                                  </option>
                                </select>

                                {validationErrors[index]?.qualifications && (
                                  <div className='text-danger'>
                                    {validationErrors[index].qualifications}
                                  </div>
                                )}
                              </div>

                              <div className='form-outline mb-5 DashBoardInputBx'>
                                <label
                                  className='form-label'
                                  htmlFor='form3Example3'
                                >
                                  Institute / School
                                  <span className='RedStar'>*</span>
                                </label>
                                <input
                                  type='text'
                                  id='form3Example3'
                                  className={`form-control  ${
                                    validationErrors[index]?.institutions &&
                                    'input-error'
                                  }`}
                                  placeholder=' Institute / School'
                                  name='institutions'
                                  value={j.institutions || ''}
                                  onChange={e => handleChange(e, index, j.id)}
                                />

                                {validationErrors[index]?.institutions && (
                                  <div className='text-danger'>
                                    {validationErrors[index].institutions}
                                  </div>
                                )}
                              </div>

                              <div className='form-outline mb-5 DashBoardInputBx'>
                                <label
                                  className='form-label'
                                  htmlFor='form3Example3'
                                >
                                  Location
                                  <span className='RedStar'>*</span>
                                </label>
                                <input
                                  type='text'
                                  id='form3Example3'
                                  className={`form-control  ${
                                    validationErrors[index]?.location &&
                                    'input-error'
                                  }`}
                                  placeholder='Location'
                                  name='location'
                                  value={j.location || ''}
                                  onChange={e => handleChange(e, index, j.id)}
                                />
                                {suggestionsNative.length > 0 && (
                                  <div
                                    className='suggestions'
                                    style={{
                                      display: suggestionTakenNative
                                        ? 'none'
                                        : ''
                                    }}
                                  >
                                    <ul className='locationDropdown'>
                                      {suggestionsNative.map(
                                        (suggestion, index) => (
                                          <div
                                            key={index}
                                            className='suggestion-item'
                                          >
                                            <li
                                              onClick={() =>
                                                handleNativeSuggestionClick(
                                                  suggestion
                                                )
                                              }
                                            >
                                              <div className='eachLocation'>
                                                <div className='locationIcon'>
                                                  <LocationOnIcon fontSize='small' />
                                                </div>{' '}
                                                <div className='locationSuggestion'>
                                                  {suggestion}
                                                </div>
                                              </div>{' '}
                                            </li>
                                          </div>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                )}

                                {validationErrors[index]?.location && (
                                  <div className='text-danger'>
                                    {validationErrors[index].location}
                                  </div>
                                )}
                              </div>
                              <div className='form-outline mb-5 DashBoardInputBx'>
                                <h5>Duration</h5>
                              </div>

                              {/* <div className='form-outline mb-5 DashBoardInputBx d-flex gap-4'>
                                <label
                                  className='form-label'
                                  htmlFor='startEmployment'
                                >
                                  School Entry
                                  <span className='RedStar'>*</span>
                                </label>

                                <div className='d-flex gap-3 form-control'>
                                  <div className='w-50'>
                                    <select
                                      name='start_month'
                                      id='startMonth'
                                      className={`form-control form-select ${
                                        validationErrors[index]?.start_month &&
                                        'input-error'
                                      }`}
                                      value={j.start_month || ''}
                                      onChange={e =>
                                        handleChange(e, index, j.id)
                                      }
                                    >
                                      <option value=''>Select Month</option>
                                      <option value='01'>January</option>
                                      <option value='02'>February</option>
                                      <option value='03'>March</option>
                                      <option value='04'>April</option>
                                      <option value='05'>May</option>
                                      <option value='06'>June</option>
                                      <option value='07'>July</option>
                                      <option value='08'>August</option>
                                      <option value='09'>September</option>
                                      <option value='10'>October</option>
                                      <option value='11'>November</option>
                                      <option value='12'>December</option>
                                    </select>
                                    {validationErrors[index]?.start_month && (
                                      <div className='text-danger small'>
                                        {validationErrors[index].start_month}
                                      </div>
                                    )}
                                  </div>

                                  <div className='w-50'>
                                    <select
                                      name='start_year'
                                      id='startYear'
                                      className={`form-control form-select ${
                                        validationErrors[index]?.start_year &&
                                        'input-error'
                                      }`}
                                      value={j.start_year || ''}
                                      onChange={e =>
                                        handleChange(e, index, j.id)
                                      }
                                    >
                                      <option value=''>Select Year</option>
                                      {Object.entries(yearsList).map(
                                        ([index, value]) => {
                                          return (
                                            <option value={value}>
                                              {value}
                                            </option>
                                          )
                                        }
                                      )}
                                    </select>
                                    {validationErrors[index]?.start_year && (
                                      <div className='text-danger small'>
                                        {validationErrors[index].start_year}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className='d-flex gap-3  form-control'>
                                  {j.ongoing ? (
                                    <>
                                      <div className='w-50'>
                                        <label className='form-cellphone'>
                                          Year of Graduation
                                          <span className='RedStar'>*</span>
                                        </label>
                                        <select
                                          name='to_month'
                                          className={`form-control form-select ${
                                            j.end_month && 'input-error'
                                          }`}
                                          placeholder='Month'
                                          value={currentMonth}
                                          onChange={handleChange}
                                          disabled={!!j.ongoing} // Disable if ongoing is checked
                                        >
                                          <option value=''>Select Month</option>
                                          {[...Array(12)].map((_, idx) => (
                                            <option key={idx} value={idx + 1}>
                                              {new Date(0, idx).toLocaleString(
                                                'en-US',
                                                { month: 'long' }
                                              )}
                                            </option>
                                          ))}
                                        </select>
                                        {validationErrors[index]?.end_month && (
                                          <div className='text-danger small'>
                                            {validationErrors[index].end_month}
                                          </div>
                                        )}
                                      </div>

                                      <div className='w-50'>
                                        <select
                                          name='to_year'
                                          className={`form-control form-select ${
                                            j.end_year && 'input-error'
                                          }`}
                                          placeholder='Year name'
                                          value={currentYear}
                                          onChange={handleChange}
                                          disabled={!!j.ongoing} // Disable if ongoing is checked
                                        >
                                          <option value=''>Select Year</option>
                                          {yearsList.map((year, idx) => (
                                            <option key={idx} value={year}>
                                              {year}
                                            </option>
                                          ))}
                                        </select>
                                        {validationErrors[index]?.to_year && (
                                          <div className='text-danger small'>
                                            {validationErrors[index].to_year}
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className='w-50'>
                                        <label className='form-cellphone w-25'>
                                          Year of Graduation
                                          <span className='RedStar'>*</span>
                                        </label>
                                        <select
                                          name='end_month'
                                          className={`form-control form-select ${
                                            validationErrors[index]
                                              ?.end_month && 'input-error'
                                          }`}
                                          value={j.end_month || ''}
                                          onChange={e =>
                                            handleChange(e, index, j.id)
                                          }
                                        >
                                          <option value=''>Select Month</option>
                                          <option value='01'>January</option>
                                          <option value='02'>February</option>
                                          <option value='03'>March</option>
                                          <option value='04'>April</option>
                                          <option value='05'>May</option>
                                          <option value='06'>June</option>
                                          <option value='07'>July</option>
                                          <option value='08'>August</option>
                                          <option value='09'>September</option>
                                          <option value='10'>October</option>
                                          <option value='11'>November</option>
                                          <option value='12'>December</option>
                                        </select>
                                        {validationErrors[index]?.end_month && (
                                          <div className='text-danger small'>
                                            {validationErrors[index].end_month}
                                          </div>
                                        )}
                                      </div>

                                      <div className='w-50'>
                                        <select
                                          name='end_year'
                                          className={`form-control form-select ${
                                            validationErrors[index]?.end_year &&
                                            'input-error'
                                          }`}
                                          value={j.end_year || ''}
                                          onChange={e =>
                                            handleChange(e, index, j.id)
                                          }
                                        >
                                          <option value=''>Select Year</option>
                                          {Object.entries(yearsList).map(
                                            ([index, value]) => {
                                              return (
                                                <option value={value}>
                                                  {value}
                                                </option>
                                              )
                                            }
                                          )}
                                        </select>
                                        {validationErrors[index]?.end_year && (
                                          <div className='text-danger small'>
                                            {validationErrors[index].end_year}
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div> */}

<div className='form-outline mb-5 DashBoardInputBx d-flex flex-column flex-md-row gap-4'>
  <label className='form-label' htmlFor='startEmployment'>
    School Entry
    <span className='RedStar'>*</span>
  </label>

  <div className='d-flex flex-column flex-md-row gap-3 w-100'>
    <div className='w-100 w-md-50'>
      <select
        name='start_month'
        id='startMonth'
        className={`form-control form-select ${
          validationErrors[index]?.start_month && 'input-error'
        }`}
        value={j.start_month || ''}
        onChange={e => handleChange(e, index, j.id)}
      >
        <option value=''>Select Month</option>
        {Array.from({ length: 12 }, (_, idx) => (
          <option key={idx + 1} value={String(idx + 1).padStart(2, '0')}>
            {new Date(0, idx).toLocaleString('en-US', { month: 'long' })}
          </option>
        ))}
      </select>
      {validationErrors[index]?.start_month && (
        <div className='text-danger small'>
          {validationErrors[index].start_month}
        </div>
      )}
    </div>

    <div className='w-100 w-md-50'>
      <select
        name='start_year'
        id='startYear'
        className={`form-control form-select ${
          validationErrors[index]?.start_year && 'input-error'
        }`}
        value={j.start_year || ''}
        onChange={e => handleChange(e, index, j.id)}
      >
        <option value=''>Select Year</option>
        {yearsList.map((year, idx) => (
          <option key={idx} value={year}>
            {year}
          </option>
        ))}
      </select>
      {validationErrors[index]?.start_year && (
        <div className='text-danger small'>
          {validationErrors[index].start_year}
        </div>
      )}
    </div>
  </div>

  <div className='d-flex flex-column flex-md-row gap-3 w-100'>
    {j.ongoing ? (
      <>
        <div className='w-100 w-md-50'>
          <label className='form-label'>
            Year of Graduation
            <span className='RedStar'>*</span>
          </label>
          <select
            name='to_month'
            className={`form-control form-select ${
              j.end_month && 'input-error'
            }`}
            value={currentMonth}
            onChange={handleChange}
            disabled={!!j.ongoing} // Disable if ongoing is checked
          >
            <option value=''>Select Month</option>
            {[...Array(12)].map((_, idx) => (
              <option key={idx} value={idx + 1}>
                {new Date(0, idx).toLocaleString('en-US', { month: 'long' })}
              </option>
            ))}
          </select>
          {validationErrors[index]?.end_month && (
            <div className='text-danger small'>
              {validationErrors[index].end_month}
            </div>
          )}
        </div>

        <div className='w-100 w-md-50'>
          <select
            name='to_year'
            className={`form-control form-select ${
              j.end_year && 'input-error'
            }`}
            value={currentYear}
            onChange={handleChange}
            disabled={!!j.ongoing} // Disable if ongoing is checked
          >
            <option value=''>Select Year</option>
            {yearsList.map((year, idx) => (
              <option key={idx} value={year}>
                {year}
              </option>
            ))}
          </select>
          {validationErrors[index]?.to_year && (
            <div className='text-danger small'>
              {validationErrors[index].to_year}
            </div>
          )}
        </div>
      </>
    ) : (
      <>
        < div className='w-100 w-md-50'>
          <label className='form-label'>
            Year of Graduation
            <span className='RedStar'>*</span>
          </label>
          <select
            name='end_month'
            className={`form-control form-select ${
              validationErrors[index]?.end_month && 'input-error'
            }`}
            value={j.end_month || ''}
            onChange={e => handleChange(e, index, j.id)}
          >
            <option value=''>Select Month</option>
            {Array.from({ length: 12 }, (_, idx) => (
              <option key={idx + 1} value={String(idx + 1).padStart(2, '0')}>
                {new Date(0, idx).toLocaleString('en-US', { month: 'long' })}
              </option>
            ))}
          </select>
          {validationErrors[index]?.end_month && (
            <div className='text-danger small'>
              {validationErrors[index].end_month}
            </div>
          )}
        </div>

        <div className='w-100 w-md-50'>
          <select
            name='end_year'
            className={`form-control form-select ${
              validationErrors[index]?.end_year && 'input-error'
            }`}
            value={j.end_year || ''}
            onChange={e => handleChange(e, index, j.id)}
          >
            <option value=''>Select Year</option>
            {yearsList.map((year, idx) => (
              <option key={idx} value={year}>
                {year}
              </option>
            ))}
          </select>
          {validationErrors[index]?.end_year && (
            <div className='text-danger small'>
              {validationErrors[index].end_year}
            </div>

          )}
          {validationErrors[index]?.date_order && (
    <div className="text-danger small">{validationErrors[index].date_order}</div>
  )}
        </div>
      </>
    )}
  </div>
</div>

                              <div className='form-outline mb-3 d-flex gap-3 ongoing-container mb-5'>
                                <input
                                  type='checkbox'
                                  // id={`ongoing-${j.id}`}
                                  name='ongoing'
                                  checked={j.ongoing == 1 ? true : false}
                                  onChange={e => handleChange(e, index, j.id)}
                                  className='form-check-input'
                                />

                                <label
                                  htmlFor={`ongoing-${j.id}`}
                                  className='form-check-label'
                                >
                                  Ongoing
                                </label>
                              </div>

                              <div className='form-outline mb-5 DashBoardInputBx SubjectAreaBx'>
                                <label
                                  className='form-label'
                                  htmlFor='form3Example3'
                                >
                                  Subject area
                                  <span className='RedStar'>*</span>
                                </label>
                                <textarea
                                  type='text'
                                  id='form3Example3'
                                  className={`form-control  ${
                                    validationErrors[index]?.subject_area &&
                                    'input-error'
                                  }`}
                                  placeholder='What was the focus of your training? What specialist knowledge and qualifications are important for the new position? Please summarize these briefly and concisely in keywords.'
                                  name='subject_area'
                                  value={j.subject_area || ''}
                                  onChange={e => handleChange(e, index, j.id)}
                                />

                                {validationErrors[index]?.subject_area && (
                                  <div className='text-danger'>
                                    {validationErrors[index].subject_area}
                                  </div>
                                )}
                              </div>

                              <div className='file-upload-container'>
                                <div className='upload-box'>
                                  <input
                                    type='file'
                                    name='education_certificate'
                                    id={`file-input-${index}`}
                                    accept='.pdf, .doc, .docx, .txt, .xls, .csv, .jpg, .jpeg'
                                    multiple
                                    onChange={e => handleFileChange(e, index)}
                                    style={{ display: 'none' }}
                                  />
                                  <div
                                    className='upload-method'
                                    onClick={() =>
                                      document
                                        .getElementById(`file-input-${index}`)
                                        .click()
                                    }
                                  >
                                    <p>
                                      <span role='img' aria-label='attachment'>
                                        📎
                                      </span>{' '}
                                      <span className='upload-text'>
                                        Add files
                                      </span>{' '}
                                      or drag and drop files here
                                    </p>
                                    <p className='upload-info'>
                                      Supported formats: .pdf, .doc, .docx,
                                      .txt, .xls, .csv, .jpg, .jpeg
                                    </p>
                                  </div>

                                  {/* {selectedFiles && selectedFiles.length > 0 ? (
                                  <div className='file-preview'>
                                    <strong>Selected Files:</strong>
                                    <ul>
                                      {selectedFiles?.map((file, fileIndex) => (
                                        <li
                                          key={fileIndex}
                                          className='file-item'
                                        >
                                          <i
                                            className={`fas ${getFileIcon(
                                              file
                                            )} file-icon`}
                                          ></i>
                                          <span className='file-name'>
                                            {typeof file === 'object' &&
                                            file.name
                                              ? file.name.length > 30
                                                ? `${file.name.slice(0, 30)}...`
                                                : file.name
                                              : file}
                                          </span>
                                          {!file? (
                                            <button
                                              type='button'
                                              onClick={() =>
                                                handleRemoveFileWithName(
                                                  file,
                                                  education_id,
                                                  fileIndex
                                                )
                                              }
                                              className='remove-btn'
                                            >
                                              &times;
                                            </button>
                                          ) : (
                                            <button
                                              type='button'
                                              onClick={() =>
                                                handleRemoveFile(
                                                  index,
                                                  fileIndex
                                                )
                                              } // Remove file by index
                                              className='remove-btn'
                                            >
                                              &times;
                                            </button>
                                          )}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ) : (
                                  <>
                                    <p>No files selected</p>
                                  </>
                                )} */}

                                  {/* {Array.isArray(j.education_certificate) && j.education_certificate.length > 0 && (
          <ul>
            {j.education_certificate.map((certificate, certIndex) => (
              <li key={certIndex}>{certificate}</li>
            ))}
          </ul>
        )} */}

                                  {/* {Array.isArray(j.education_certificate) &&
                                  j.education_certificate.length > 0 ? (
                                    <div className='file-preview'>
                                      <strong>Selected Certificates:</strong>
                                      <ul>
                                        {j.education_certificate ? (
                                          j.education_certificate.map(
                                            (certificate, certIndex) => (
                                              <li
                                                key={certIndex}
                                                className='file-item'
                                              >
                                                <i
                                                  className={`fas fa-file file-icon`}
                                                ></i>
                                                <span className='file-name'>
                                                  {certificate?.length > 30
                                                    ? `${certificate?.slice(
                                                        0,
                                                        30
                                                      )}...`
                                                    : certificate}
                                                </span>
                                                 <span className='file-name'>
            {certificate?.length > 30
              ? `${certificate.slice(0, 30)}...`
              : certificate }
          </span>
                                                {!certificate.name ? (
                                                  <button
                                                    type='button'
                                                    onClick={() =>
                                                      handleRemoveFileWithName(
                                                        j.id,
                                                        certificate
                                                      )
                                                    } // Replace with your remove function
                                                    className='remove-btn'
                                                  >
                                                    &times;
                                                  </button>
                                                ) : (
                                                  <button
                                                    type='button'
                                                    onClick={() =>
                                                      handleRemoveFile(
                                                        index,
                                                        certIndex
                                                      )
                                                    } // Replace with your remove function
                                                    className='remove-btn'
                                                  >
                                                    &times;
                                                  </button>
                                                )}
                                              </li>
                                            )
                                          )
                                        ) : (
                                          <p>No certificates selected</p>
                                        )}
                                        
                                      </ul>
                                    </div>
                                  ) : (
                                    <p>No certificates selected</p>
                                  )} */}
                                  {Array.isArray(j.education_certificate) &&
                                  j.education_certificate.length > 0 ? (
                                    <div className='file-preview'>
                                      <strong>Selected Certificates:</strong>
                                      <ul>
                                        {j.education_certificate.map(
                                          (certificate, certIndex) => (
                                            <li
                                              key={certIndex}
                                              className='file-item'
                                            >
                                              <i
                                                className={`fas fa-file file-icon`}
                                              ></i>
                                              {/* <span className='file-name'>
          
            {certificate?.name?.length > 30
              ? `${certificate?.name.slice(0, 30)}...`
              : certificate?.name}
          </span> */}
                                              <span className='file-name'>
                                                {/* Check if the certificate is a File object */}
                                                {certificate instanceof File
                                                  ? certificate.name.length > 30
                                                    ? `${certificate.name.slice(
                                                        0,
                                                        30
                                                      )}...`
                                                    : certificate.name
                                                  : certificate.length > 30
                                                  ? `${certificate.slice(
                                                      0,
                                                      30
                                                    )}...`
                                                  : certificate}
                                              </span>

                                              {/* Remove button for file object */}
                                              <button
                                                type='button'
                                                onClick={
                                                  () =>
                                                    certificate?.name
                                                      ? handleRemoveFile(
                                                          index,
                                                          certIndex
                                                        ) // Handle file removal
                                                      : handleRemoveFileWithName(
                                                          j.id,
                                                          certificate
                                                        ) // Handle name-based removal
                                                }
                                                className='remove-btn'
                                              >
                                                &times;
                                              </button>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  ) : (
                                    <p>No certificates selected</p>
                                  )}
                                </div>
                              </div>

                              <div className='removeButtonJobseeker mt-4'>
                                {/* {j.id ? (
                                  <>
                                    <button
                                      type='button'
                                      className='btn btn-primary button2'
                                      onClick={() => handleRemove(j.id)}
                                      style={{
                                        color: hoverFourthButtonColor
                                          ? primaryColor
                                          : secondaryColor,
                                        backgroundColor: 'white',
                                        border: hoverFourthButtonColor
                                          ? `2px solid ${primaryColor}`
                                          : `2px solid ${secondaryColor}`
                                      }}
                                      // onMouseEnter={handleFourthButtonMouseEnter}
                                      // onMouseLeave={handleFourthButtonMouseLeave}
                                    >
                                      {t('jobseekerEducation.removeButton')}
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      type='button'
                                      className='btn btn-primary button2'
                                      onClick={() =>
                                        handleRemoveWithoutId(index)
                                      }
                                      style={{
                                        color: hoverFourthButtonColor
                                          ? primaryColor
                                          : secondaryColor,
                                        backgroundColor: 'white',
                                        border: hoverFourthButtonColor
                                          ? `2px solid ${primaryColor}`
                                          : `2px solid ${secondaryColor}`
                                      }}
                                      // onMouseEnter={handleFourthButtonMouseEnter}
                                      // onMouseLeave={handleFourthButtonMouseLeave}
                                    >
                                      {t('jobseekerEducation.removeButton')}
                                    </button>
                                  </>
                                )} */}
                                {index !== 0 && (
                                  <>
                                    {j.id ? (
                                      <button
                                        type='button'
                                        className='btn btn-primary button2'
                                        onClick={() => handleRemove(j.id)}
                                        style={{
                                          color: hoverFourthButtonColor
                                            ? primaryColor
                                            : secondaryColor,
                                          backgroundColor: 'white',
                                          border: hoverFourthButtonColor
                                            ? `2px solid ${primaryColor}`
                                            : `2px solid ${secondaryColor}`
                                        }}
                                      >
                                        {t('jobseekerEducation.removeButton')}
                                      </button>
                                    ) : (
                                      <button
                                        type='button'
                                        className='btn btn-primary button2'
                                        onClick={() =>
                                          handleRemoveWithoutId(index)
                                        }
                                        style={{
                                          color: hoverFourthButtonColor
                                            ? primaryColor
                                            : secondaryColor,
                                          backgroundColor: 'white',
                                          border: hoverFourthButtonColor
                                            ? `2px solid ${primaryColor}`
                                            : `2px solid ${secondaryColor}`
                                        }}
                                      >
                                        {t('jobseekerEducation.removeButton')}
                                      </button>
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          </div>
                        )
                      })
                    ) : (
                      <></>
                    )}
                    {/* {educations && educations.length > 0 ? (
                educations.map((educations,j, index) => (
                    <div key={index} className="education-item">
                        <h2>{education.qualifications}</h2>
                        <p>Institution: {education.institutions}</p>
                        <p>Location: {education.location}</p>
                        <p>
                            Duration: {education.start_month}/{education.start_year} - {education.end_month}/{education.end_year}
                        </p>
                        <p>Ongoing: {education.ongoing === "true" ? "Yes" : "No"}</p>
                        <p>Subject Area: {education.subject_area}</p>
                        <h3>Certificates:</h3>
                        <ul>
                            {education.education_certificate.map((certificate, certIndex) => (
                                <li key={certIndex}>{certificate}</li>
                            ))}
                        </ul>
                    

                    </div>
                ))
            ) : (
                <p>No educations found</p>
            )} */}
                  </div>
                </form>
                {educations.length > 0 && (
                  <>
                    <div className='EduAddMore mb-4'>
                      <button
                        type='button'
                        className='btn btn-primary button1'
                        onClick={handleAdd}
                        style={{
                          backgroundColor: primaryColor,
                          color: 'white',
                          border: primaryColor
                        }}
                      >
                        {t('jobseekerEducation.addMoreButton')}
                      </button>
                    </div>
                  </>
                )}
                {/* {educations.length > 0 && (
                  <>
                    <div className='bottomButtonsEducation'>
                      <button
                        type='button'
                        className='btn btn-primary button1'
                        onClick={handleClick}
                        style={{
                          backgroundColor: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor,
                          border: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor
                        }}
                        onMouseEnter={handleFirstButtonMouseEnter}
                        onMouseLeave={handleFirstButtonMouseLeave}
                      >
                        {t('jobseekerEducation.updateButton')}
                      </button>
                      <button
                        type='button'
                        className='btn btn-primary button2'
                        style={{
                          color: hoverThirdButtonColor
                            ? primaryColor
                            : secondaryColor,
                          backgroundColor: 'white',
                          border: hoverThirdButtonColor
                            ? `2px solid ${primaryColor}`
                            : `2px solid ${secondaryColor}`
                        }}
                        onMouseEnter={handleThirdButtonMouseEnter}
                        onMouseLeave={handleThirdButtonMouseLeave}
                        onClick={() => navigate('/candidates/myaccount')}
                      >
                        {t('jobseekerEducation.cancelButton')}
                      </button>
                    </div>
                  </>
                )} */}

                {/* <div className='JSEPFinalButton d-flex justify-content-between'>
                  <Link
                    to={`/candidates/parttime`}
                    type='button'
                    // onClick={handleClick}
                    className='btn btn-primary button1'
                    style={{
                      backgroundColor: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor
                    }}
                    onMouseEnter={handleFirstButtonMouseEnter}
                    onMouseLeave={handleFirstButtonMouseLeave}
                  >
                    Back
                  </Link>
                  <button
                    // to={`/candidates/professions/experience`}
                    type='button'
                    onClick={handleClick}
                    className='btn btn-primary button1'
                    style={{
                      backgroundColor: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor
                    }}
                    onMouseEnter={handleFirstButtonMouseEnter}
                    onMouseLeave={handleFirstButtonMouseLeave}
                  >
                    Next
                  </button>
                </div> */}

                {/* <div className='JSEPFinalButton d-flex justify-content-end my-3'>
                 
                </div>
                <div className='JSEPFinalButton d-flex justify-content-end my-5'>
                  <button
                    onClick={handleClick}
                    className='btn btn-primary button1'
                  >
                    Save
                  </button>
                </div> */}
                <div className='JSEPFinalButton d-flex justify-content-between my-5'>
                  <Link
                    to={`/candidates/professions/experience`}
                    type='button'
                    // onClick={handleClick}
                    className='btn btn-primary button1'
                    style={{
                      backgroundColor: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor
                    }}
                    onMouseEnter={handleFirstButtonMouseEnter}
                    onMouseLeave={handleFirstButtonMouseLeave}
                  >
                    Back
                  </Link>
                  <button
                    // to={`/candidates/professions/experience`}
                    type='button'
                    onClick={handleClick}
                    className='btn btn-primary button1'
                    style={{
                      backgroundColor: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor
                    }}
                    onMouseEnter={handleFirstButtonMouseEnter}
                    onMouseLeave={handleFirstButtonMouseLeave}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  )
}

export default EducationDetails
