import React, { useEffect, useState } from 'react'
import JSSidebar from './JSSidebar'
import NavBar from '../element/NavBar'
import Footer from '../element/Footer'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import ApiKey from '../api/ApiKey'
import BaseApi from '../api/BaseApi'
// import Multiselect from "multiselect-react-dropdown";
import JoditEditor from 'jodit-react'
import Select from 'react-select'
import { useRef } from 'react'
import Swal from 'sweetalert2'

const JsLanguages = () => {
  const primaryColor = Cookies.get('primaryColor')
  const secondaryColor = Cookies.get('secondaryColor')
  const [skills, setSkills] = useState([{ special_skills: '', level: '' }])

  const [candidateLanguage, setCandidateLanguage] = useState([
    {
      languages: '',
      written: '',
      spoken: ''
    }
  ])

  const [validationErrors, setValidationErrors] = useState([])
  const [t, i18n] = useTranslation('global')
  const [loading, setLoading] = useState(false)
  const [intrest, setIntrest] = useState([])
  const [languages, setLanguages] = useState([])

  const navigate = useNavigate()
  const tokenKey = Cookies.get('tokenClient') // Assuming you're using cookies for the token

  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false)

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true)
  }

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false)
  }

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false)

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true)
  }

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false)
  }

  const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false)

  const handleThirdButtonMouseEnter = () => {
    setHoverThirdButtonColor(true)
  }

  const handleThirdButtonMouseLeave = () => {
    setHoverThirdButtonColor(false)
  }

  const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false)

  const handleFourthButtonMouseEnter = () => {
    setHoverFourthButtonColor(true)
  }

  const handleFourthButtonMouseLeave = () => {
    setHoverFourthButtonColor(false)
  }

  // const handleLanguageChange = (e, index, type) => {
  //   const { name, value } = e.target

  //   // Update the languages based on the 'type' (spoken or written)
  //   const updatedLanguages = [...languages] // assuming languages is an array of objects
  //   updatedLanguages[index] = {
  //     ...updatedLanguages[index],
  //     [name]: value
  //   }

  //   setLanguages(updatedLanguages)
  // }

  const handleLanguageChange = (e, index) => {
    const { name, value } = e.target

    // Update the specific field in the candidateLanguage array
    const updatedLanguages = candidateLanguage.map((language, i) =>
      i === index ? { ...language, [name]: value } : language
    )

    setCandidateLanguage(updatedLanguages) // Update the state
  }

  // const handleClick = async () => {
  //   if (!validateFields()) {
  //     window.scrollTo(0, 0) // Scroll to the top to see validation errors
  //     return
  //   }

  //   try {
  //     const confirmationResult = await Swal.fire({
  //       title: 'Confirm Languages Details',
  //       text: 'Are you sure you want to save these details?',
  //       icon: 'question',
  //       showCancelButton: true,
  //       confirmButtonText: 'Yes',
  //       cancelButtonText: 'No'
  //     })

  //     if (confirmationResult.isConfirmed) {
  //       setLoading(true)
  //       console.log()

  //       const response = await axios.post(
  //         BaseApi + '/candidates/addcandidatelanguage',
  //         { CandidateLanguage: candidateLanguage }, // Send education details as payload
  //         {
  //           headers: {
  //             'Content-Type': 'application/json',
  //             key: ApiKey,
  //             token: tokenKey
  //           }
  //         }
  //       )

  //       setLoading(false)

  //       if (response.data.status === 200) {
  //         Swal.fire({
  //           title: 'Success!',
  //           text: 'Internships details updated successfully.',
  //           icon: 'success',
  //           confirmButtonText: 'Close'
  //         })
  //         setLanguages([]) // Reset education details
  //         // Optional: Reload the page or call a function to update UI
  //         navigate('/candidates/parttime')
  //       } else if (response.data.status === 400) {
  //         Cookies.remove('tokenClient')
  //         Cookies.remove('user_type')
  //         Cookies.remove('fname')
  //         navigate('/candidates/parttime') // Redirect if token expired or other issue
  //         Swal.fire({
  //           title: response.data.message,
  //           icon: 'warning',
  //           confirmButtonText: 'Close'
  //         })
  //       } else {
  //         Swal.fire({
  //           title: response.data.message,
  //           icon: 'error',
  //           confirmButtonText: 'Close'
  //         })
  //       }
  //     }
  //   } catch (error) {
  //     setLoading(false)
  //     // if (error.message === "Network Error") {
  //     //   Cookies.remove("tokenClient");
  //     //   Cookies.remove("user_type");
  //     //   Cookies.remove("fname");
  //     //   navigate("/candidates/internships");
  //     //   Swal.fire({
  //     //     title: "Token Expired",
  //     //     icon: "warning",
  //     //     confirmButtonText: "Close",
  //     //   });
  //     //   setTimeout(function () {
  //     //     window.location.reload(); // Reload page if the token is expired
  //     //   }, 3000);
  //     // }

  //     Swal.fire({
  //       title: 'Failed to update Internships',
  //       text: 'There was an error updating your internships details. Please try again.',
  //       icon: 'error',
  //       confirmButtonText: 'Close'
  //     })
  //   }
  // }

  const handleClick = async () => {
    if (!validateFields()) {
      window.scrollTo(0, 0) // Scroll to the top to see validation errors
      return
    }

    try {
      const confirmationResult = await Swal.fire({
        title: 'Confirm Languages Details',
        text: 'Are you sure you want to save these details?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (confirmationResult.isConfirmed) {
        setLoading(true)
        console.log('Payload before submission:', candidateLanguage) // Debugging

        const response = await axios.post(
          `${BaseApi}/candidates/addcandidatelanguage`,
          { CandidateLanguage: candidateLanguage }, // Send language details as payload
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey,
              token: tokenKey
            }
          }
        )

        setLoading(false)

        if (response.data.status === 200) {
          getData()
          Swal.fire({
            title: 'Success!',
            text: 'Language details updated successfully.',
            icon: 'success',
            confirmButtonText: 'Close'
          })
          setCandidateLanguage([{ languages: '', written: '', spoken: '' }]) // Reset state
          navigate('/candidates/editlanguages')
        } else {
          Swal.fire({
            title: response.data.message,
            icon: response.data.status === 400 ? 'warning' : 'error',
            confirmButtonText: 'Close'
          })
          if (response.data.status === 400) {
            Cookies.remove('tokenClient')
            Cookies.remove('user_type')
            Cookies.remove('fname')
            navigate('/candidates/cvpreview')
          }
        }
      }
    } catch (error) {
      setLoading(false)
      Swal.fire({
        title: 'Failed to update Language Details',
        text: 'There was an error updating your details. Please try again.',
        icon: 'error',
        confirmButtonText: 'Close'
      })
    }
  }

  // const validateFields = () => {
  //   const errors = skills.map(skill => ({
  //     candidateLanguage:
  //       typeof skill.languages === 'string' && skill.languages.trim() === ''
  //         ? 'Language is required'
  //         : skill.languages
  //         ? ''
  //         : 'Language is required',
  //     written:
  //       typeof skill.written === 'string' && skill.written.trim() === ''
  //         ? 'Written is required'
  //         : skill.written
  //         ? ''
  //         : 'Written is required'
  //   }))

  //   setValidationErrors(errors)

  //   return errors.every(error => !error.special_skills && !error.level)
  // }

  const validateFields = () => {
    const errors = candidateLanguage.map(skill => ({
      candidateLanguage:
        typeof skill.languages === 'string' && skill.languages.trim() === ''
          ? 'Language is required'
          : skill.languages
          ? ''
          : 'Language is required',
      written:
        typeof skill.written === 'string' && skill.written.trim() === ''
          ? 'Written is required'
          : skill.written
          ? ''
          : 'Written is required',
      spoken:
        typeof skill.spoken === 'string' && skill.spoken.trim() === ''
          ? 'Spoken is required'
          : skill.spoken
          ? ''
          : 'Spoken is required'
    }))

    setValidationErrors(errors)
    console.log(errors)

    return errors.every(
      error => !error.special_skills && !error.level && !error.spoken
    )
  }

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate('/user/jobseekerlogin')
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData()

      window.scrollTo(0, 0)
      // if(educationDetails.basic_course_id) {
      // getSpecializationList(educationDetails.basic_course_id);
      // }
    }
  }, [tokenKey, navigate])

  // const getData = async () => {
  //   try {
  //     setLoading(true)
  //     const response = await axios.post(
  //       BaseApi + '/candidates/addcandidatelanguage',
  //       null, // Pass null as the request body if not required
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           key: ApiKey,
  //           token: tokenKey
  //         }
  //       }
  //     )
  //     if (response.data.status === 200) {
  //       setLanguages(response.data.response.languageData)
  //       console.log(response.data)

  //       // console.log("object");

  //       // code to handel pre selected interest category

  //       setLoading(false)
  //       // setSkills(response.data.response)

  //       const languageData = response.data.response.languageData
  //       setLanguages(languageData || [])
  //       console.log(response.data.response.languageData)

  //       setCandidateLanguage(response.data.response.userDetailsArray || [])

  //       console.log('check')
  //       // setDesignationList(response.data.response.designationlList);
  //       // console.log(skillList);
  //     } else if (response.data.status === 400) {
  //       Cookies.remove('tokenClient')
  //       Cookies.remove('user_type')
  //       Cookies.remove('fname')
  //       navigate('/')
  //       Swal.fire({
  //         title: response.data.message,
  //         icon: 'warning',
  //         confirmButtonText: t('searchJobPage.close')
  //       })
  //     } else {
  //       Swal.fire({
  //         title: response.data.message,
  //         icon: 'error',
  //         confirmButtonText: t('searchJobPage.close')
  //       })
  //     }
  //   } catch (error) {
  //     setLoading(false)
  //     // if (error.message === 'Network Error') {
  //     //   Cookies.remove('tokenClient')
  //     //   Cookies.remove('user_type')
  //     //   Cookies.remove('fname')
  //     //   navigate('/')
  //     //   Swal.fire({
  //     //     title: t('tokenExpired.tokenExpired'),
  //     //     icon: 'warning',
  //     //     confirmButtonText: t('jobDescription.close')
  //     //   })
  //     //   setTimeout(function () {
  //     //     window.location.reload()
  //     //   }, 3000)
  //     // }
  //     console.log('Cannot get data of edit profile page')
  //   }
  // }

  const getData = async () => {
    try {
      setLoading(true)
      const response = await axios.post(
        BaseApi + '/candidates/addcandidatelanguage',
        null, // Pass null if no request body is required
        {
          headers: {
            'Content-Type': 'application/json',
            key: ApiKey,
            token: tokenKey
          }
        }
      )
      if (response.data.status === 200) {
        // Set language data
        setLanguages(response.data.response.languageData)

        // If no user details array is returned, set default values
        if (response.data.response.userDetailsArray.length === 0) {
          setCandidateLanguage([{ languages: '', written: '', spoken: '' }]) // Set default empty fields
        } else {
          setCandidateLanguage(response.data.response.userDetailsArray)
        }
      } else {
        // Handle error or redirect if needed
        console.error('Error fetching data')
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleAdd = () => {
    console.log(languages)
    setCandidateLanguage([
      ...candidateLanguage,
      { languages: '', written: '', spoken: '' }
    ])
  }

  const handleAddIntrest = () => {
    setIntrest([...intrest, { intrest: '' }])
  }

  const handleRemove = async id => {
    try {
      const confirmationResult = await Swal.fire({
        title: t('jobseekerEducation.removeConfirmTitle'),
        text: t('jobseekerEducation.removeConfirmTxt'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('jobseekerEducation.yes'),
        cancelButtonText: t('jobseekerEducation.no')
      })
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteeducation/${id}`,
          null,
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey,
              token: tokenKey
            }
          }
        )
        if (response.data.status === 200) {
          getData()
          Swal.fire({
            title: t('jobseekerEducation.removeSuccessTitle'),
            icon: 'success',
            confirmButtonText: t('jobseekerEducation.close')
          })
        } else if (response.data.status === 400) {
          Cookies.remove('tokenClient')
          Cookies.remove('user_type')
          Cookies.remove('fname')
          navigate('/')
          Swal.fire({
            title: response.data.message,
            icon: 'warning',
            confirmButtonText: t('jobseekerEducation.close')
          })
        } else {
          Swal.fire({
            title: response.data.message,
            icon: 'error',
            confirmButtonText: t('jobseekerEducation.close')
          })
        }
      }
    } catch (error) {
      setLoading(false)
      if (error.message === 'Network Error') {
        Cookies.remove('tokenClient')
        Cookies.remove('user_type')
        Cookies.remove('fname')
        navigate('/')
        Swal.fire({
          title: t('tokenExpired.tokenExpired'),
          icon: 'warning',
          confirmButtonText: t('jobDescription.close')
        })
        setTimeout(function () {
          window.location.reload()
        }, 3000)
      }
      Swal.fire({
        title: t('jobseekerEducation.removeFailedTitle'),
        text: t('jobseekerEducation.removeFailedTxt'),
        icon: 'error',
        confirmButtonText: t('jobseekerEducation.close')
      })
    }
  }

 const handleRemoveWithoutId = async (indexToRemove) => {
    console.log("Removing index:", indexToRemove);
  
    const confirmationResult = await Swal.fire({
      title: "Remove Details?",
        text: "Do you want to remove this Details?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    });
  
    if (confirmationResult.isConfirmed) {
      setCandidateLanguage(prevLanguages =>
        prevLanguages.filter((_, index) => index !== indexToRemove)
      );
  
      Swal.fire({
        title: 'Removed!',
        text: 'The language has been removed.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (validateFields()) {
  //     // Submit logic here
  //     console.log("Skills:", skills);
  //   }
  // };

  // const handleSubmit = async e => {
  //   e.preventDefault()
  //   if (validateFields()) {
  //     try {
  //       setLoading(true)
  //       const response = await axios.post(
  //         `${BaseApi}/candidates/addcandidatelanguage`,
  //         { candidateLanguage },
  //         {
  //           headers: {
  //             'Content-Type': 'application/json',
  //             key: ApiKey,
  //             token: tokenKey
  //           }
  //         }
  //       )
  //       setLoading(false)

  //       if (response.data.status === 200) {
  //         Swal.fire('Success', 'Languages updated successfully!', 'success')
  //         navigate('/candidates/parttime')
  //       } else {
  //         Swal.fire(
  //           'Error',
  //           response.data.message || 'Something went wrong!',
  //           'error'
  //         )
  //       }
  //     } catch (error) {
  //       setLoading(false)
  //       Swal.fire(
  //         'Error',
  //         'Failed to update languages. Please try again.',
  //         'error'
  //       )
  //     }
  //   }
  // }

  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <NavBar />

      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <>
          <div className='container editProfile'>
            <div className='row'>
              <div className='col-lg-3'>
                <JSSidebar />
              </div>
              <div
                className='col-lg-9 mb-5'
                style={{
                  borderLeft: '2px solid #e6e8e7',
                  borderRight: '2px solid #e6e8e7'
                }}
              >
                <div className='mx-3 d-flex PageHeader'>
                  <h3>Language skills</h3>
                </div>
                <p className='mx-3'>
                  Open up new opportunities with multilingualism and find your
                  dream job in top international companies!
                </p>
                <form>
                  {candidateLanguage.map((j, index) => (
                    <div key={index} className='mb-5 mt-4 mx-4'>
                      <h4 className='mt-5 mb-5'>Languages {index + 1}:</h4>

                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-outline mb-5 DashBoardInputBx'>
                            <label className='form-label'>
                              Languages
                              <span className='RedStar'>*</span>
                            </label>
                            <select
                              className={`form-control form-select ${
                                validationErrors[index]?.candidateLanguage &&
                                'input-error'
                              }`}
                              name='languages'
                              value={j.languages}
                              onChange={e => handleLanguageChange(e, index)}
                            >
                              <option value=''>Select language spoken</option>
                              {languages.map(language => (
                                <option key={language.id} value={language.name}>
                                  {language.name}
                                </option>
                              ))}
                            </select>
                            {validationErrors[index]?.candidateLanguage && (
                              <div className='text-danger small'>
                                {validationErrors[index].candidateLanguage}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-outline mb-5 DashBoardInputBx'>
                            <label className='form-label'>
                              Written
                              <span className='RedStar'>*</span>
                            </label>
                            <select
                              className={`form-control form-select ${
                                validationErrors[index]?.written &&
                                'input-error'
                              }`}
                              name='written'
                              value={j.written}
                              onChange={e => handleLanguageChange(e, index)}
                            >
                              <option value=''>Select language written</option>
                              <option value='Mother Tongue'>
                                Mother Tongue
                              </option>
                              <option value='Fluent'>Fluent</option>
                              <option value='Good Knowledge'>
                                Good Knowledge
                              </option>
                              <option value='Basic Knowledge'>
                                Basic Knowledge
                              </option>
                            </select>
                            {validationErrors[index]?.written && (
                              <div className='text-danger small'>
                                {validationErrors[index].written}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='col-lg-4'>
                          <div className='form-outline mb-5 DashBoardInputBx'>
                            <label className='form-label'>
                              Spoken
                              <span className='RedStar'>*</span>
                            </label>
                            <select
                              className={`form-control form-select ${
                                validationErrors[index]?.spoken && 'input-error'
                              }`}
                              name='spoken'
                              value={j.spoken}
                              onChange={e => handleLanguageChange(e, index)}
                            >
                              <option value=''>Select language Spoken</option>
                              <option value='Mother Tongue'>
                                Mother Tongue
                              </option>
                              <option value='Fluent'>Fluent</option>
                              <option value='Good Knowledge'>
                                Good Knowledge
                              </option>
                              <option value='Basic Knowledge'>
                                Basic Knowledge
                              </option>
                            </select>
                            {validationErrors[index]?.spoken && (
                              <div className='text-danger small'>
                                {validationErrors[index].spoken}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className='removeButtonJobseeker mt-4'>
                        {/* {index !== 0 && j.id ? (
                      <>
                        <button
                          type='button'
                          className='btn btn-primary button2'
                          onClick={() => handleRemove(j.id)}
                          style={{
                            color: hoverFourthButtonColor
                              ? primaryColor
                              : secondaryColor,
                            backgroundColor: 'white',
                            border: hoverFourthButtonColor
                              ? `2px solid ${primaryColor}`
                              : `2px solid ${secondaryColor}`
                          }}
                        >
                          {t('jobseekerEducation.removeButton')}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type='button'
                          className='btn btn-primary button2'
                          onClick={() => handleRemoveWithoutId(index)}
                          style={{
                            color: hoverFourthButtonColor
                              ? primaryColor
                              : secondaryColor,
                            backgroundColor: 'white',
                            border: hoverFourthButtonColor
                              ? `2px solid ${primaryColor}`
                              : `2px solid ${secondaryColor}`
                          }}
                        >
                          {t('jobseekerEducation.removeButton')}
                        </button>
                      </>
                    )} */}
                        {index !== 0 && (
                          <>
                            {j.id ? (
                              <button
                                type='button'
                                className='btn btn-primary button2'
                                onClick={() => handleRemoveWithoutId(index)}
                                style={{
                                  color: hoverFourthButtonColor
                                    ? primaryColor
                                    : secondaryColor,
                                  backgroundColor: 'white',
                                  border: hoverFourthButtonColor
                                    ? `2px solid ${primaryColor}`
                                    : `2px solid ${secondaryColor}`
                                }}
                              >
                                {t('jobseekerEducation.removeButton')}
                              </button>
                            ) : (
                              <button
                                type='button'
                                className='btn btn-primary button2'
                                onClick={() => handleRemoveWithoutId(index)}
                                style={{
                                  color: hoverFourthButtonColor
                                    ? primaryColor
                                    : secondaryColor,
                                  backgroundColor: 'white',
                                  border: hoverFourthButtonColor
                                    ? `2px solid ${primaryColor}`
                                    : `2px solid ${secondaryColor}`
                                }}
                              >
                                {t('jobseekerEducation.removeButton')}
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ))}

                  {candidateLanguage.length > 0 && (
                    <>
                      <div className='EduAddMore mb-4'>
                        <button
                          type='button'
                          className='btn btn-primary button1'
                          onClick={handleAdd}
                          style={{
                            backgroundColor: primaryColor,
                            color: 'white',
                            border: primaryColor
                          }}
                        >
                          {t('jobseekerEducation.addMoreButton')}
                        </button>
                      </div>
                    </>
                  )}
                  {/* {candidateLanguage.length <= 0 && (
                <>
                  <div className='EduAddMore mb-4'>
                    <button
                      type='button'
                      className='btn btn-primary button1'
                      onClick={handleAdd}
                      style={{
                        backgroundColor: primaryColor,
                        color: 'white',
                        border: primaryColor
                      }}
                    >
                      {t('jobseekerEducation.addDetails')}
                    </button>
                  </div>
                </>
              )} */}
                </form>

                {/* {skills.length > 0 && (
              <>
                <div className="bottomButtonsEducation">
                  <button
                    type="button"
                    className="btn btn-primary button1"
                    onClick={handleClick}
                    style={{
                      backgroundColor: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor,
                    }}
                    onMouseEnter={handleFirstButtonMouseEnter}
                    onMouseLeave={handleFirstButtonMouseLeave}
                  >
                    {t("jobseekerEducation.updateButton")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary button2"
                    style={{
                      color: hoverThirdButtonColor
                        ? primaryColor
                        : secondaryColor,
                      backgroundColor: "white",
                      border: hoverThirdButtonColor
                        ? `2px solid ${primaryColor}`
                        : `2px solid ${secondaryColor}`,
                    }}
                    onMouseEnter={handleThirdButtonMouseEnter}
                    onMouseLeave={handleThirdButtonMouseLeave}
                    onClick={() => navigate("/candidates/myaccount")}
                  >
                    {t("jobseekerEducation.cancelButton")}
                  </button>
                </div>
              </>
            )} */}

                <div className='JSEPFinalButton d-flex justify-content-end my-5'>
                  {/* <Link
                to={`/candidates/skills`}
                type='button'
                // onClick={handleClick}
                className='btn btn-primary button1'
                style={{
                  backgroundColor: hoverFirstButtonColor
                    ? secondaryColor
                    : primaryColor,
                  border: hoverFirstButtonColor ? secondaryColor : primaryColor
                }}
                onMouseEnter={handleFirstButtonMouseEnter}
                onMouseLeave={handleFirstButtonMouseLeave}
              >
                Back
              </Link> */}
                  <button
                    // to={`/candidates/professions/experience`}
                    type='button'
                    onClick={handleClick}
                    className='btn btn-primary button1'
                    style={{
                      backgroundColor: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverFirstButtonColor
                        ? secondaryColor
                        : primaryColor
                    }}
                    onMouseEnter={handleFirstButtonMouseEnter}
                    onMouseLeave={handleFirstButtonMouseLeave}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  )
}

export default JsLanguages
